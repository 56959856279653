import React, { useState, useEffect } from "react";
import cn from "classnames";

import { replaceSpaceToUnderscore } from "../../../../../../utils";
import styles from "./VehicleImage.module.scss";
import DefaultCarAvatar from "../../../../../../assets/icons/default-car.png";
import EngineIcon from "../../../../../../components/engine-icon/EngineIcon";
import EngineRedIcon from "../../../../../../assets/icons/engine-red.svg";
import EngineGreenIcon from "../../../../../../assets/icons/engine-green.svg";

const VehicleImage = ({ vehicle }) => {
  const { checkEngine, vinData } = vehicle;
  const ENGINE_ICON = checkEngine ? EngineRedIcon : EngineGreenIcon;

  const [avatar, setAvatar] = useState({
    isSet: false,
    URL: DefaultCarAvatar,
  });

  if (!avatar.isSet && vinData) {
    const { year, make, model } = vinData;
    if (year && make && model) {
      setAvatar({
        isSet: true,
        URL: `https://images.mechanicadvisor.com/Resources/Upload/Images/Specifications/${year}_${replaceSpaceToUnderscore(
          make
        )}_${replaceSpaceToUnderscore(model)}.png`,
      });
    }
  }

  useEffect(() => {
    if (avatar.isSet) {
      (async () => {
        try {
          const res = await fetch(avatar.URL);
          if (!res.ok) {
            setAvatar((prev) => ({ ...prev, URL: DefaultCarAvatar }));
          }
        } catch {
          setAvatar((prev) => ({ ...prev, URL: DefaultCarAvatar }));
        }
      })();
    }
  }, [avatar.isSet]);

  return (
    <div
      style={{ backgroundImage: `url(${avatar.URL})` }}
      className={cn([styles.vehicleAvatar, "flex-shrink-0 mr-16"])}
    >
      <EngineIcon icon={ENGINE_ICON} className={styles.engineIcon} />
    </div>
  );
};

export default VehicleImage;
