import React, { useState } from "react";
import cn from "classnames";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { useScreenSize } from "../../../../utility/hooks";
import { getSelectedShopId } from "../../../shops/shops.selectors";

import { InfoIcon } from "../../../../assets/icons";
import styles from "./VehiclesCell.module.scss";
import Popper from "../../../../components/popper/Popper";
import Typography from "../../../../components/typography/Typography";
import { VehiclesList, VehiclesWarningList } from "./components";

const VehiclesCell = ({
  row,
  value,
  isConnectedCol = false,
  isWarningsCol = false,
}) => {
  const { id: customerId, vehiclesData } = row.row.original;
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const { isMobile, isTablet } = useScreenSize();
  const history = useHistory();
  const shopId = useSelector(getSelectedShopId);

  const vehicles = value?.map((id) => {
    return { ...vehiclesData[id], id };
  });

  function onVehicleSelect(id) {
    history.push(`/${shopId}/customer/${customerId}/vehicle/${id}`);
  }

  return (
    <div
      className={styles.vehicleContent}
      onMouseEnter={() => {
        setIsTooltipOpen(true);
      }}
      onMouseLeave={() => {
        setIsTooltipOpen(false);
      }}
      onClick={() => {
        if (isMobile || isTablet) {
          setIsTooltipOpen(true);
        }
      }}
    >
      {value && value.length ? (
        <Typography className="mr-5" fontSize="md">
          {value.length}
        </Typography>
      ) : (
        <Typography fontSize="md">-</Typography>
      )}
      {value && value.length > 0 && (
        <Popper
          toggler={<InfoIcon className={cn("flex", styles.tooltipIcon)} />}
          placement="top-start"
          isOpen={isTooltipOpen}
          onOutsideClick={() => setIsTooltipOpen(false)}
          modifiers={[
            {
              name: "flip",
              options: {
                fallbackPlacements: [
                  "top-end",
                  "top-start",
                  "bottom-start",
                  "bottom-end",
                ],
              },
            },
          ]}
        >
          <div className={cn(styles.tooltip, "flex items-center")}>
            {isWarningsCol ? (
              <VehiclesWarningList
                vehicles={vehicles}
                onClick={onVehicleSelect}
              />
            ) : (
              <VehiclesList
                vehicles={vehicles}
                isConnectedCol={isConnectedCol}
                onClick={onVehicleSelect}
              />
            )}
          </div>
        </Popper>
      )}
    </div>
  );
};

export default VehiclesCell;
