import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { debounce } from "lodash";
import cn from "classnames";

import SearchField from "../../shops/HeaderShopToggle/components/SearchField/SearchField";

import { setCustomerFilter } from "../customers.slice";

import classes from "./CustomerSearch.module.scss";

export default function CustomerSearch() {
  const dispatch = useDispatch();
  const debouncedActionDispatch = useCallback(
    debounce((value) => {
      dispatch(setCustomerFilter(value));
    }, 500),
    []
  );

  return (
    <SearchField
      className={cn([classes.CustomerSearch, classes.CustomerSearch__search])}
      placeholder="Search for customer..."
      onChange={debouncedActionDispatch}
    />
  );
}
