import React, { useEffect, useState } from "react";
import cn from "classnames";
import {
  useRowSelect,
  useColumnOrder,
  useFlexLayout,
  useSortBy,
  useTable,
  useExpanded,
} from "react-table";
import PropTypes from "prop-types";
import styles from "./Table.module.scss";
import TableContainer from "./components/TableContainer";
import HeaderGroup from "./components/HeaderGroup/HeaderGroup";
import Row from "./components/Row/Row";

const Table = ({
  sortArrowClassName,
  columns, // memoized columns description array (created by useColumns);
  columnsSetting, // array with columns settings (visibility/order);
  CollapseRowComponent, // component (function or class component) that will be displayed when collapse is extended.
  data, // raw data for creating rows
  getRowId = (row) => row.id || row._id, // need for creating custom id
  isHideHeaderRow, // do not render header row;
  withHidingColumns, // disable the column flex grow when columns count equal 4 or less
  setRowProps = () => {}, // set row props (style ,className etc) be careful it may override existing ones!!!
  wrapperClasses,
  headRowClassName,
  headWrapperClassName,
  headCellClassName,
  togglerClassName,
  tableContainerClasses,
  hasBoxShadow = true,
  CollapseRowToggleComponent,
}) => {
  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    setColumnOrder,
    setHiddenColumns,
  } = useTable(
    {
      columns,
      data,
      getRowId, // here place to create custom id for subrows to, check documentation
      manualPagination: true,
    },
    useSortBy,
    useExpanded,
    useRowSelect,
    useColumnOrder,
    useFlexLayout
  );

  useEffect(() => {
    if (columnsSetting) {
      setColumnOrder(columnsSetting.map(({ id }) => id));
      const columnVisibility = columnsSetting
        .filter(({ isVisible }) => !isVisible)
        .map(({ id }) => id);
      setHiddenColumns(columnVisibility);
    }
  }, [columnsSetting, setColumnOrder, setHiddenColumns]);

  const [expandedRowId, setExpandedRowId] = useState(null);

  return (
    <div
      className={cn(styles.wrapper, wrapperClasses, "tableWrapper", {
        [styles.boxShadow]: hasBoxShadow,
      })}
    >
      <div>
        <TableContainer className={cn(tableContainerClasses)}>
          <div
            {...getTableProps()}
            className={cn(styles.table, " box-shadow-light", {})}
          >
            {!isHideHeaderRow && (
              <div
                className={cn(
                  styles.head,
                  headWrapperClassName,
                  "flex items-center w-full"
                )}
              >
                {headerGroups.map((headerGroup, i) => {
                  return (
                    <HeaderGroup
                      sortArrowClassName={sortArrowClassName}
                      headRowClassName={headRowClassName}
                      headCellClassName={headCellClassName}
                      key={i}
                      headerGroup={headerGroup}
                      CollapseRowComponent={CollapseRowComponent}
                      withHidingColumns={withHidingColumns}
                    />
                  );
                })}
              </div>
            )}
            <div>
              {rows.map((row) => {
                prepareRow(row);
                const { key } = row.getRowProps();
                const isExpanded = expandedRowId === row.id;

                return (
                  <div
                    className={cn({ [styles.rowExpanded]: isExpanded })}
                    key={key}
                  >
                    <Row
                      withHidingColumns={withHidingColumns}
                      key={key}
                      row={row}
                      data={data}
                      CollapseRowComponent={CollapseRowComponent}
                      ToggleComponent={CollapseRowToggleComponent}
                      setRowProps={setRowProps}
                      togglerClassName={togglerClassName}
                      setCollapsedRowId={setExpandedRowId}
                      collapsedRowId={expandedRowId}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </TableContainer>
      </div>
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  columnsSetting: PropTypes.array,
  CollapseRowComponent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
  ]),
  getRowId: PropTypes.func,
  setRowProps: PropTypes.func,
  isHideHeaderRow: PropTypes.bool,
};

export default Table;
