import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Provider } from "react-redux";
import jwt_decode from "jwt-decode";
import store from "./app.store";

import { useSelector, useDispatch } from "react-redux";
import {
  getIsLoggedIn,
  logoutUser,
  refreshUser,
  setIsLoggedIn,
  setAdmin,
} from "./ui.slice";

import { getSelectedTenant } from "../scenes/tenants/tenants.selectors";
import { selectTenant } from "../scenes/tenants/tenants.slice";

import Login from "../scenes/login/Login";
import AppLoader from "./AppLoader";
import Layout from "../layout/Layout";
import CustomerDetailsPage from "../scenes/customer-details/CustomerDetails";
import VehicleDetailsPage from "../scenes/customer-details/VehicleDetails";
import CustomerSelectionPage from "../scenes/customers/Customers";
import AdminUsers from "../scenes/adminUsers/AdminUsers";
import {
  setOnTokenInvalid,
  setClientAuthorizationHeader,
  setShopIdHeader,
} from "../services/api.service";
import { getFromLocalStorage, setToLocalStorage } from "../utils";

import "./App.scss";

function App() {
  const [isAppReady, setIsAppReady] = useState(false);

  useEffect(() => {
    if (window.self !== window.top) {
      const handleTelematicsDataChange = (e) => {
        if (e.data.type === "telematicsData") {
          setToLocalStorage("shopId", e.data.shopId);
          setToLocalStorage("accessToken", e.data.token);
          setToLocalStorage("refreshToken", "not-provided");
          setClientAuthorizationHeader(e.data.token);
          setShopIdHeader(e.data.shopId);

          setIsAppReady(true);
        }

        window.document.head.insertAdjacentHTML(
          "beforeend",
          "<style>body{scrollbar-width: thin; scrollbar-color: #90a4ae #cfd8dc;} body::-webkit-scrollbar{width: 7px; height: 7px;} body::-webkit-scrollbar-thumb{background-color: #95A1B2; border-radius: 5px;}</style>"
        );
      };

      window.addEventListener("message", handleTelematicsDataChange);

      window.parent.postMessage({ type: "getTelematicsData" }, "*");
    } else {
      setIsAppReady(true);
    }
  }, []);

  if (!isAppReady) {
    return null;
  }

  return (
    <div className="App">
      <Provider store={store}>
        <Router>
          <Switch>
            <Route path={`/login`} component={Login} />
            <PrivateRoutes>
              <AppLoader>
                <Switch>
                  <Route
                    path={`/:shopId/customer/:id/vehicle/:vehicleId/overview`}
                  >
                    <VehicleDetailsPage />
                  </Route>
                  <Layout>
                    <Switch>
                      {/* <Route exact path={`/admin`}>
                      <AdminUsers />
                    </Route> */}
                      <Route exact path={`/:shopId/`}>
                        <CustomerSelectionPage />
                      </Route>
                      <Route path={`/:shopId/customer/:id/vehicle/:vehicleId`}>
                        <CustomerDetailsPage />
                      </Route>
                      <Route path={`/:shopId/customer/:id`}>
                        <CustomerDetailsPage />
                      </Route>
                      <Route path={`/:shopId/vin/:vin`}>
                        <CustomerDetailsPage />
                      </Route>
                    </Switch>
                  </Layout>
                </Switch>
              </AppLoader>
            </PrivateRoutes>
          </Switch>
        </Router>
      </Provider>
    </div>
  );
}

// app init
setClientAuthorizationHeader(getFromLocalStorage("accessToken"));

function PrivateRoutes({ children }) {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const selectedTenant = useSelector(getSelectedTenant);

  const accessToken = getFromLocalStorage("accessToken");
  const refreshToken = getFromLocalStorage("refreshToken");
  const [refreshingTokens, setRefreshingTokens] = useState(false);

  useEffect(() => {
    if (refreshingTokens) dispatch(refreshUser(refreshToken));
  }, [refreshingTokens]);

  useEffect(() => {
    // app init
    setOnTokenInvalid(() => dispatch(logoutUser()));
  }, []);

  if (accessToken && refreshToken) {
    const accessExpiresAt = jwt_decode(accessToken).exp;
    const accessMinutesToExpire = (accessExpiresAt - Date.now() / 1000) / 60;

    const tokenData = jwt_decode(accessToken);

    const isAdmin = tokenData.user.role === "admin";
    dispatch(setAdmin(isAdmin));

    const { tenantId } = tokenData.user;
    if (tenantId && !selectedTenant) {
      dispatch(selectTenant({ id: tenantId }));
    }

    if (accessMinutesToExpire < 1) {
      dispatch(logoutUser());
    } else {
      if (accessMinutesToExpire < 30 && !refreshingTokens) {
        setRefreshingTokens(true);
      } else if (accessMinutesToExpire >= 30 && refreshingTokens) {
        setRefreshingTokens(false);
      }
      dispatch(setIsLoggedIn(true));
      return children;
    }
  }

  return isLoggedIn ? children : <Redirect to={`/login`} />;
}

export default App;
