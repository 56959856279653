import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import cn from "classnames";

import { fetchCoreVehicleData } from "../../vehicles.slice";
import {
  getCoreVehicleData,
  getSelectedVehicle,
  getTirePressure,
  getVisualBlockParameters,
  getFuelFilterLifeRemaining,
  getEngineOilLifeRemaining,
  getLatestFuelMetrics,
  getVehicleBodyType,
} from "../../vehicles.selectors";
import { getMeasurementSystem } from "../../../customers/customers.selectors";

import { ReactComponent as CoolantTempIcon } from "../../../../assets/icons/coolant-temp.svg";
import { ReactComponent as BatteryVolageIcon } from "../../../../assets/icons/battery-voltage.svg";
import { ReactComponent as FuelLevelIcon } from "../../../../assets/icons/fuel-level.svg";
import { ReactComponent as TransmissionTempIcon } from "../../../../assets/icons/transmission-temp.svg";

import classes from "./CoreVehicleParameters.module.scss";
import { calculateGradientValueColor } from "../../../../utils";
import Warnings from "../warnings/Warnings";
import TotalFuelUsed from "../total-fuel-used/TotalFuelUsed";

export default function CoreVehicleParameters() {
  const dispatch = useDispatch();
  const selectedVehicle = useSelector(getSelectedVehicle);
  const coreParams = useSelector(getCoreVehicleData);

  useEffect(() => {
    dispatch(fetchCoreVehicleData(selectedVehicle));
  }, [selectedVehicle && selectedVehicle.id]);

  if (!coreParams) {
    return null;
  }

  return (
    <div>
      <TirePressure />
      <VisualParameterBlocksList />
      <LifeIndicator
        selector={getFuelFilterLifeRemaining}
        title="Fuel Filter Life"
        iconClassName={classes.fuelFilter}
      />
      <LifeIndicator
        selector={getEngineOilLifeRemaining}
        title="Engine Oil Life"
        iconClassName={classes.engineOil}
      />
      <LatestFuelUsageStatistics />
      <Warnings />
      <TotalFuelUsed />
    </div>
  );
}

function TirePressure() {
  const tirePressure = useSelector(getTirePressure);
  const bodyType = useSelector(getVehicleBodyType);
  const measurementSystem = useSelector(getMeasurementSystem);
  const isMeasurementSystemImperial = measurementSystem === "imperial";

  const tirePressureParams = [
    {
      key: "fl",
      label: "front left",
      className: "tire-pressure-value-fl",
      value: "N/A",
    },
    {
      key: "fr",
      label: "front right",
      className: "tire-pressure-value-fr",
      value: "N/A",
    },
    {
      key: "rr",
      label: "rear right",
      className: "tire-pressure-value-rr",
      value: "N/A",
    },
    {
      key: "rl",
      label: "rear left",
      className: "tire-pressure-value-rl",
      value: "N/A",
    },
  ];

  // TIRE_PRESSURE_THRESHOLD in psi or bar
  const TIRE_PRESSURE_THRESHOLD = isMeasurementSystemImperial ? 20 : 1.5;

  if (Object.keys(tirePressure).length === 0) {
    return null;
  }

  const problematicTires = [];
  let imageClass = "car-tire-pressure-";

  tirePressureParams.forEach((param) => {
    const pressureEntry = tirePressure[param.key];

    param.value = pressureEntry || param.value;
    if (pressureEntry && pressureEntry < TIRE_PRESSURE_THRESHOLD) {
      problematicTires.push(param);
      imageClass += "0";
    } else {
      imageClass += "1";
    }
  });
  const hasProblems = problematicTires.length > 0;
  const descriptionText = `Your ${problematicTires
    .map((entry) => entry.label)
    .join(", ")} ${
    problematicTires.length === 1 ? "tire" : "tires"
  } should be checked as soon as
    possible`;

  return (
    <div className={cn(classes.TirePressure)}>
      <div className={cn(classes.TirePressure__Label, "text-center")}>
        Tire Pressure:{" "}
        {hasProblems ? (
          <span className={classes["TirePressure__LabelAccent--low"]}>Low</span>
        ) : (
          <span className={classes["TirePressure__LabelAccent--normal"]}>
            Normal
          </span>
        )}
      </div>
      {hasProblems && (
        <div
          className={cn(
            classes.TirePressure__Description,
            "text-center",
            "mt-2"
          )}
        >
          {descriptionText}
        </div>
      )}
      <div
        className={cn(
          classes.TirePressure__Visual,
          imageClass,
          "mt-3",
          "position-relative"
        )}
      >
        <div
          className={classes.carBodyType}
          style={{
            backgroundImage: `url(/static/body-types/${bodyType}.png)`,
          }}
        />
        {tirePressureParams.map((param) => (
          <div
            key={param.key}
            className={cn(
              classes.TirePressure__VisualLabel,
              {
                [classes["TirePressure__VisualLabel--low"]]:
                  param.value < TIRE_PRESSURE_THRESHOLD,
              },
              param.className,
              "position-absolute"
            )}
          >
            <span className={classes.TirePressure__VisualValue}>
              {param.value}
            </span>
            {isMeasurementSystemImperial ? " PSI" : " BAR"}
          </div>
        ))}
      </div>
    </div>
  );
}

function VisualParameterBlocksList() {
  const {
    coolantTemperature,
    crankingVoltage,
    fuelLevelPercentage,
    transmissionOilTemperature,
  } = useSelector(getVisualBlockParameters);
  const measurementSystem = useSelector(getMeasurementSystem);
  const isMeasurementSystemImperial = measurementSystem === "imperial";

  let coolantColor = "green";
  let coolantLabelValue = "normal";
  if (
    isMeasurementSystemImperial
      ? coolantTemperature > 220
      : coolantTemperature > 104
  ) {
    coolantColor = "red";
    coolantLabelValue = "high";
  } else if (
    isMeasurementSystemImperial
      ? coolantTemperature < 190
      : coolantTemperature < 87
  ) {
    coolantColor = "blue";
    coolantLabelValue = "low";
  }

  let voltageColor = "green";
  let voltageLabelValue = "normal";
  if (crankingVoltage < 12) {
    voltageColor = "red";
    voltageLabelValue = "low";
  } else if (crankingVoltage > 15) {
    voltageColor = "green";
    voltageLabelValue = "high";
  }

  let transmissionOilColor = "green";
  let transmissionOilLabelValue = "normal";
  if (
    isMeasurementSystemImperial
      ? transmissionOilTemperature > 260
      : transmissionOilTemperature > 126
  ) {
    transmissionOilColor = "red";
    transmissionOilLabelValue = "high";
  } else if (
    isMeasurementSystemImperial
      ? transmissionOilTemperature < 230
      : transmissionOilTemperature < 110
  ) {
    transmissionOilColor = "blue";
    transmissionOilLabelValue = "low";
  }

  let fuelPercentageColor = "green";
  let fuelLevelPercentageLabelValue = "normal";
  if (fuelLevelPercentage <= 5) {
    fuelPercentageColor = "yellow";
    fuelLevelPercentageLabelValue = "low";
  }

  return (
    <div className={cn(classes.VisualParamsBlocksList, "d-flex", "flex-wrap")}>
      {coolantTemperature && (
        <VisualParameterBlock
          Icon={CoolantTempIcon}
          label="Coolant Temp"
          value={`${coolantTemperature}${
            isMeasurementSystemImperial ? "°F" : "°C"
          }`}
          valueDescription={`Temp is ${coolantLabelValue}`}
          color={coolantColor}
        />
      )}
      {transmissionOilTemperature && (
        <VisualParameterBlock
          Icon={TransmissionTempIcon}
          label="Transmission Oil Temp"
          value={`${transmissionOilTemperature}${
            isMeasurementSystemImperial ? "°F" : "°C"
          }`}
          valueDescription={`Temp is ${transmissionOilLabelValue}`}
          color={transmissionOilColor}
        />
      )}
      {crankingVoltage && (
        <VisualParameterBlock
          Icon={BatteryVolageIcon}
          label="Battery Voltage"
          value={`${crankingVoltage.toFixed(1)}v`}
          valueDescription={`battery is ${voltageLabelValue}`}
          color={voltageColor}
        />
      )}
      {fuelLevelPercentage && (
        <VisualParameterBlock
          Icon={FuelLevelIcon}
          label="Fuel Level"
          value={`${fuelLevelPercentage}%`}
          valueDescription={
            fuelLevelPercentageLabelValue === "normal"
              ? ""
              : `Fuel is ${fuelLevelPercentageLabelValue}`
          }
          color={fuelPercentageColor}
        />
      )}
    </div>
  );
}

function VisualParameterBlock({ Icon, label, value, valueDescription, color }) {
  return (
    <div
      className={cn(
        classes.VisualParamsBlock,
        "visual-param-block-" + color,
        "text-center"
      )}
    >
      <Icon
        className={cn(
          classes.VisualParamsBlock__Icon,
          "visual-param-block-icon-" + color,
          "my-3"
        )}
      />
      <div className={cn(classes.VisualParamsBlock__Label, "mb-2")}>
        {label}
      </div>
      <div className={cn(classes.VisualParamsBlock__Value, "mb-1")}>
        {value}
      </div>
      <div
        className={cn(
          classes.VisualParamsBlock__ValueDescription,
          "visual-param-block-value-description-" + color
        )}
      >
        {valueDescription}
      </div>
    </div>
  );
}

function LifeIndicator({ selector, title, iconClassName }) {
  const lifeRemaining = useSelector(selector);

  if (lifeRemaining === null || lifeRemaining === undefined) return null;

  const valueColor = calculateGradientValueColor(
    [
      { color: "#D7282F", percentage: 0 },
      { color: "#F8B131", percentage: 50 },
      { color: "#29C07B", percentage: 100 },
    ],
    lifeRemaining
  );

  return (
    <div
      className={cn(
        classes.LifeIndicator,
        "d-flex flex-wrap align-items-baseline"
      )}
    >
      <div className={cn(classes.LifeIndicator__Title, "text-left")}>
        {title}
      </div>
      <div className={cn(classes.LifeIndicator__Icon, iconClassName)} />
      <div
        className={cn(classes.LifeIndicator__Value, "text-right")}
        style={{ color: valueColor }}
      >
        {Math.floor(lifeRemaining)}%
      </div>
      <LifeLineTicks />
      <div
        className={cn(
          classes.LifeIndicator__Line,
          "w-100 my-1 position-relative"
        )}
      >
        <div
          className={cn(classes.LifeIndicator__ValueLine)}
          style={{
            width: lifeRemaining + "%",
            backgroundSize: `${(100 / lifeRemaining) * 100}% 16px`,
          }}
        />
      </div>
      <LifeLineTicks />
      <div className={cn(classes.LifeIndicator__ChangeLabel, "w-50 text-left")}>
        Change
      </div>
      <div className={cn(classes.LifeIndicator__GoodLabel, "w-50 text-right")}>
        Good
      </div>
    </div>
  );
}

function LifeLineTicks() {
  return (
    <div className={cn(classes.LifeIndicator_LineTicks, "w-100 d-flex")}>
      {Array(9)
        .fill(1)
        .map((_, index) => (
          <div key={index} className={classes.LifeIndicator__LineTick} />
        ))}
    </div>
  );
}

function LatestFuelUsageStatistics() {
  const fuelUsageMetrics = useSelector(getLatestFuelMetrics);
  const { distanceDriven, fuelUsed, idleFuelUsed, mpg } = fuelUsageMetrics;
  const measurementSystem = useSelector(getMeasurementSystem);
  const isMeasurementSystemImperial = measurementSystem === "imperial";

  if (!Object.values(fuelUsageMetrics).some((value) => value !== null)) {
    return null;
  }

  return (
    <div className={cn([classes.FuelUsageMetrics, "d-flex flex-wrap"])}>
      <div className={cn([classes.FuelUsageMetrics__Title, "w-100"])}>
        Last 30 days
      </div>
      {!!distanceDriven && (
        <FuelUsageMetricLine
          label="Distance"
          value={distanceDriven}
          dimension={isMeasurementSystemImperial ? "mi" : "km"}
        />
      )}
      {!!fuelUsed && (
        <FuelUsageMetricLine
          label="Fuel Used"
          value={fuelUsed}
          dimension={isMeasurementSystemImperial ? "gal" : "l"}
        />
      )}
      {!!mpg && (
        <FuelUsageMetricLine
          label={isMeasurementSystemImperial ? "MPG" : "L/100km"}
          value={mpg.toFixed(1)}
        />
      )}
      {!!idleFuelUsed && (
        <FuelUsageMetricLine
          label="Idle Gas Usage"
          value={idleFuelUsed}
          dimension={isMeasurementSystemImperial ? "gal" : "l"}
        />
      )}
    </div>
  );
}

function FuelUsageMetricLine({ label, value, dimension = "" }) {
  return (
    <>
      <div
        className={cn([
          classes.FuelUsageMetrics__LineLabel,
          "text-left w-50 mt-3",
        ])}
      >
        {label}
      </div>
      <div
        className={cn([
          classes.FuelUsageMetrics__LineValue,
          "text-right w-50 mt-3",
        ])}
      >
        {value}{" "}
        <span className={classes.FuelUsageMetrics__LineValueDimension}>
          {dimension}
        </span>
      </div>
    </>
  );
}
