import React, { useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import "../../../../custom-modal.css";

import cn from "classnames";
import { useForm, Controller } from "react-hook-form";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/close.svg";
import { ReactComponent as InformationIcon } from "../../../../assets/icons/information.svg";
import AlertViewIcon from "../../../../assets/icons/alert-view.svg";
import AlertEditIcon from "../../../../assets/icons/alert-edit.svg";
import classes from "../../../../styles/modal.module.scss";
import formClasses from "../../../../styles/input.module.scss";

export default function AlertItemModal({
  isOpen,
  toggle,
  isEdit,
  type,
  text,
  updateAlertText,
}) {
  const onSubmit = (data) => {
    updateAlertText(data.template);
    toggle();
  };

  const { handleSubmit, control, errors, reset } = useForm({
    defaultValues: { template: text },
  });

  useEffect(() => {
    reset({ template: text });
  }, [text]);

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className={classes.Modal__Dialog}
        contentClassName={cn(
          classes.Modal__Content,
          classes.Modal__Content__Alert
        )}
        centered={true}
      >
        <div className={classes.Modal__Header}>
          <div>
            <img
              src={isEdit ? AlertEditIcon : AlertViewIcon}
              className={classes.Modal__AlertIcon}
              alt="Message Icon"
            />
            <div className={classes.Modal__Title}>
              {isEdit ? "Edit " : "View "}
              {type === "shop" ? "Shop Alert" : "Customer Alert"} Template
            </div>
          </div>
          <CloseIcon className={classes.Modal__CloseIcon} onClick={toggle} />
        </div>
        <hr className={classes.Modal_HorizontalLine} />
        {isEdit ? (
          <>
            <ModalBody className={classes.Modal__Body}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                  <Label for="notes" className={formClasses.InputLabel}>
                    Template
                  </Label>
                  <Controller
                    as={Input}
                    control={control}
                    type="textarea"
                    name="template"
                    className={cn(
                      formClasses.TextArea,
                      formClasses.AlertTextArea
                    )}
                    id="template"
                    placeholder="Type here"
                  />
                </FormGroup>
              </form>
              <div className={classes.Modal__InfoPanel}>
                <div className={classes.Modal__InfoPanel__Title}>
                  <InformationIcon
                    className={classes.Modal__InfoPanel__Icon}
                    onClick={toggle}
                  />
                  List of available dynamic keywords:
                </div>
                <div className={classes.Modal__InfoPanel__Description}>
                  {"{customerName}, {vehicleName}, {shopName}, {value}"}
                </div>
              </div>
            </ModalBody>
            <ModalFooter
              className={cn(
                classes.Modal__Footer,
                classes.Modal__Footer__Alert
              )}
            >
              <button className={classes.Modal__CancelBtn} onClick={toggle}>
                Cancel
              </button>
              <button
                className={classes.Modal__SubmitBtn}
                onClick={handleSubmit(onSubmit)}
              >
                Save Template
              </button>
            </ModalFooter>
          </>
        ) : (
          <ModalBody className={classes.Modal__Body}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                <Label for="notes" className={formClasses.InputLabel}>
                  Template
                </Label>
                <div
                  className={cn(
                    formClasses.TextArea,
                    formClasses.AlertTextArea,
                    formClasses.AlertTextArea__NotEditable
                  )}
                >
                  {text}
                </div>
              </FormGroup>
            </form>
          </ModalBody>
        )}
      </Modal>
    </div>
  );
}
