import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import cn from "classnames";
import classes from "./Alerts.module.scss";
import PastActivityIcon from "../../../../assets/icons/past-activity.svg";
import { EmptyPastActivityIcon } from "../../../../assets/icons";
import PastActivityItem from "./PastActivityItem";
import { fetchVehicleAlertsPastActivity } from "../../vehicles.slice";

import { alertsParams } from "../../../../constants";

import {
  getVehicleAlertsPastActivity,
  getSelectedVehicle,
  getHasMorePastAcvivity,
} from "../../vehicles.selectors";
import { getMeasurementSystem } from "../../../customers/customers.selectors";
import InfiniteScroll from "react-infinite-scroll-component";

export default function PastActivity() {
  const dispatch = useDispatch();
  const activityRecords = useSelector(getVehicleAlertsPastActivity);
  const measurementSystem = useSelector(getMeasurementSystem);

  const selectedVehicle = useSelector(getSelectedVehicle);
  const [offset, setOffset] = useState(10);
  const isNext = useSelector(getHasMorePastAcvivity);

  function fetchMoreData() {
    setOffset((prev) => prev + 10);
    dispatch(
      fetchVehicleAlertsPastActivity({ offset, id: selectedVehicle.id })
    );
  }

  return (
    <div className={classes.Activity__Wrapper}>
      <div className="VehicleInfoPanel__headerLabel w-50 flex mb-3">
        <div className={classes.Title}>
          <img src={PastActivityIcon} alt="" className="mr-14555" />
          <span>Past Activity</span>
        </div>
      </div>
      <div className={classes.Activity__ScrollWrapper} id="scrollableDiv">
        {activityRecords.length ? (
          <InfiniteScroll
            dataLength={activityRecords.length}
            next={fetchMoreData}
            hasMore={isNext}
            loader={<div className={classes.Activity__Loader}>Loading...</div>}
            scrollableTarget="scrollableDiv"
            style={{ overflow: "visible" }}
          >
            {activityRecords.map((activity, i) => (
              <div key={activity._id}>
                {i !== 0 && <div className={classes.Activity__Delimiter} />}
                <PastActivityItem
                  activity={makeActivityItem({
                    ...activity,
                    measurementSystem,
                  })}
                />
              </div>
            ))}
          </InfiniteScroll>
        ) : (
          <div className="flex justify-center">
            <div
              className={cn([classes.Activity__NoData, "font-md font-bold"])}
            >
              <EmptyPastActivityIcon className={classes.icon} />
              There is no data to display.
            </div>
          </div>
        )}
        <div className={classes.Activity__BottomPadding} />
      </div>
    </div>
  );
}

function makeActivityItem(record) {
  const alertConfig = alertsParams[record.parameter];
  const date = new Date(record.timestamp);
  const isMeasurementSystemImperial = record.measurementSystem === "imperial";

  const activityItem = {
    title: `${alertConfig.name} alert sent`,
    range: record.triggerRange.length
      ? `Value range: ${record.triggerRange[0]}-${record.triggerRange[1]} ${
          alertConfig.measurementUnits[isMeasurementSystemImperial ? 1 : 0]
        }`
      : "",
    value: record.value,
    day: date.toLocaleDateString(["en-US"], {
      month: "short",
      day: "numeric",
      year: "numeric",
    }),
    time: date.toLocaleString(["en-US"], {
      hour12: true,
      hour: "numeric",
      minute: "2-digit",
    }),
    ring: !!record.subscribers.Shop,
    chat: !!record.subscribers.Customer,
    icon: alertConfig.icon,
  };

  return activityItem;
}
