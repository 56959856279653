/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import Spinner from "../components/spinner/Spinner";
import { fetchCustomers } from "../scenes/customers/customers.slice";
import { isLoaded as isCustomerListLoaded } from "../scenes/customers/customers.selectors";
import {
  isLoaded as isShopsListLoaded,
  getSelectedShopId,
  getIsShopsError,
} from "../scenes/shops/shops.selectors";
import { fetchShops, fetchTenantShops } from "../scenes/shops/shops.slice";
import { getAdminStatus, logoutUser } from "./ui.slice";
import { getRoutePrefix } from "../services/env.service";
import { fetchTenants } from "../scenes/tenants/tenants.slice";
import {
  getSelectedTenant,
  getIsTenantsError,
} from "../scenes/tenants/tenants.selectors";
import { getFromLocalStorage } from "../utils";

export default function AppLoader({ children }) {
  const isCustomersLoaded = useSelector(isCustomerListLoaded);
  const isShopsLoaded = useSelector(isShopsListLoaded);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const selectedShopId = useSelector(getSelectedShopId);
  const isAdmin = useSelector(getAdminStatus);
  const selectedTenant = useSelector(getSelectedTenant);
  const isShopsError = useSelector(getIsShopsError);
  const isTenantsError = useSelector(getIsTenantsError);

  useEffect(() => {
    if (isAdmin) {
      dispatch(fetchTenants());
    } else {
      dispatch(fetchShops({ URLShopId: getRoutePrefix() }));

      // vinChange message is sent from Extension to display vehicle with vincode if found
      if (window.self !== window.top) {
        const handleVinChange = (e) => {
          if (e.data.type === "vinChange") {
            const shopId = getFromLocalStorage("shopId");
            history.push(`/${shopId}/vin/${e.data.vin}`);
          }
        };
        window.addEventListener("message", handleVinChange);
      }
    }
  }, []);

  useEffect(() => {
    if (selectedTenant && isAdmin) {
      dispatch(
        fetchTenantShops({
          URLShopId: getRoutePrefix(),
          selectedTenantId: selectedTenant.id,
        })
      );
    }
  }, [selectedTenant]);

  useEffect(() => {
    if (selectedShopId) {
      dispatch(fetchCustomers());

      if (isAdmin && location.pathname === "/admin") return;

      if (getRoutePrefix() !== selectedShopId) {
        history.push(`/${selectedShopId}/`);
      }
    }
  }, [selectedShopId]);

  useEffect(() => {
    if (isShopsError || isTenantsError) dispatch(logoutUser());
  }, [isShopsError, isTenantsError]);

  return isCustomersLoaded && isShopsLoaded ? (
    <> {children}</>
  ) : (
    <div className="main-spinner">
      <Spinner />
    </div>
  );
}
