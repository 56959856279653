import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Container, Row, Col, Alert } from "reactstrap";

import { resendCustomerConsent } from "../../services/api.service";

import {
  fetchCustomerVehicles,
  selectVehicle,
  closeAlert,
  fetchDiagnosticOptions,
  selectChartOption,
  fetchVehicleOverview,
} from "./vehicles.slice";
import { getSelectedVehicle, getDeviceAlertStatus } from "./vehicles.selectors";
import { getSelectedCustomer } from "../customers/customers.selectors";
import { selectCustomer } from "../customers/customers.slice";
import GeneralVehicleData from "./components/general-vehicle-data/GeneralVehicleData";
import ReadinessMonitors from "./components/readiness-monitors/ReadinessMonitors";
import RecommendedServices from "./components/recommended-services/RecommendedServices";
import ServicesHistory from "./components/services-history/ServicesHistory";
import PidGraphs from "./components/pid-graphs/PidGraphs";
import DtcCodes from "./components/dtc-codes/DtcCodes";
import Alerts from "./components/alerts/Alerts";

import { ReactComponent as AttentionIcon } from "../../assets/icons/attention.svg";
import { ReactComponent as SendSMSIcon } from "../../assets/icons/force-send-sms.svg";
import alertClasses from "../../styles/alert.module.scss";
import "./CustomerDetails.scss";

export default function CustomerDetailsPage() {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  const [visible, setVisible] = useState(false);
  const { vehicleId, id: customerId, vin, shopId } = params;
  const [isConsentWarningVisible, setConsentWarningVisible] = useState(false);

  const selectedVehicle = useSelector(getSelectedVehicle);
  const isShownDeviceAlert = useSelector(getDeviceAlertStatus);
  const selectedCustomer = useSelector(getSelectedCustomer);

  useEffect(() => {
    if (customerId) {
      dispatch(selectVehicle(null));
      dispatch(selectCustomer(customerId));
      dispatch(fetchCustomerVehicles(customerId));
    }
  }, [customerId]);

  useEffect(() => {
    if (vehicleId) {
      dispatch(selectVehicle(vehicleId));
    }
  }, [vehicleId]);

  useEffect(() => {
    if (selectedVehicle) {
      dispatch(selectChartOption(null));
      dispatch(fetchDiagnosticOptions(selectedVehicle.deviceId));
      dispatch(fetchVehicleOverview(vehicleId));
    }
  }, [selectedVehicle && selectedVehicle.deviceId]);

  useEffect(() => {
    const isDeviceDetached =
      selectedVehicle &&
      selectedVehicle.overview &&
      selectedVehicle.overview.data &&
      !selectedVehicle.overview.data.isDeviceCommunicating;

    const isDeviceAlertClosed =
      selectedVehicle &&
      isShownDeviceAlert &&
      isShownDeviceAlert.hasOwnProperty(selectedVehicle.id);

    setVisible(
      Boolean(selectedVehicle && isDeviceDetached && !isDeviceAlertClosed)
    );

    return () => setVisible(false);
  }, [selectedVehicle]);

  useEffect(() => {
    const isConsentWarningVisible =
      selectedCustomer && !selectedCustomer.customerConsented;
    setConsentWarningVisible(isConsentWarningVisible);
  }, [selectedCustomer]);

  const handleCloseAlert = () => {
    dispatch(closeAlert(selectedVehicle.id));
    setVisible(false);
  };

  const handleCloseConsentAlert = () => {
    setConsentWarningVisible(false);
  };

  const handleResendApprovalRequest = () => {
    resendCustomerConsent(customerId);
    setConsentWarningVisible(false);
  };

  if (vin) return null;

  return (
    <Container fluid className="p-5 MobileContainer SteerContainer">
      <Row className="pt-3">
        <Col xs="12" className="d-flex pb-2">
          <Alert
            isOpen={isConsentWarningVisible}
            toggle={handleCloseConsentAlert}
            id={alertClasses.ConsentAlert}
          >
            <AttentionIcon className="WarningIcon" />
            <div>
              Unable to unlock access to your vehicles. You may have entered the
              wrong phone number. Try to resend approval request or contact
              support.
            </div>
            <div
              className={alertClasses.button}
              onClick={handleResendApprovalRequest}
            >
              <SendSMSIcon />
              Resend Approval Request
            </div>
          </Alert>
          <Alert
            isOpen={visible}
            toggle={handleCloseAlert}
            id={alertClasses.WarningAlert}
          >
            <AttentionIcon className="WarningIcon" /> Please note! The device
            for this vehicle is currently disconnected. Only historical data is
            shown.
          </Alert>
        </Col>
        <Col xs="12" lg="4" className="MobileWrapper d-flex">
          <GeneralVehicleData className="VehicleInfoPanel" />
        </Col>
        <Col xs="12" lg="8">
          <Row>
            <Col xs="12" className="Col">
              <ReadinessMonitors className="VehicleInfoPanel d-flex flex-column" />
            </Col>
            <Col xs="12" className="Col">
              <ServicesHistory className="VehicleInfoPanel d-flex flex-column" />
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <DtcCodes className="VehicleInfoPanel d-flex  w-100" />
            </Col>
            <Col xs="12">
              <PidGraphs className="VehicleInfoPanel w-100" />
            </Col>
            <Col xs="12">
              <Alerts className="VehicleInfoPanel w-100" />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
