import React from "react";
import styles from "./ViewMoreBtn.module.scss";

import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { selectCustomer } from "../../customers.slice";
import { getSelectedShopId } from "../../../shops/shops.selectors";

const ViewMoreBtn = ({ value }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const shopId = useSelector(getSelectedShopId);

  function handleOnClick(id) {
    dispatch(selectCustomer(null));
    history.push(`/${shopId}/customer/${id}`);
  }
  return (
    <button
      className={styles.viewMoreButton}
      onClick={() => value && handleOnClick(value)}
    >
      View More
    </button>
  );
};

export default ViewMoreBtn;
