import React, { useEffect, useState, useMemo } from "react";
import { Modal, ModalBody, ModalFooter, FormGroup, Label } from "reactstrap";
import cn from "classnames";
import "../../../../custom-modal.css";

import { ReactComponent as CloseIcon } from "../../../../assets/icons/close.svg";
import ManageAlertsIcon from "../../../../assets/icons/manage-alerts-icon.svg";
import { NoResultIcon } from "../../../../assets/icons";
import classes from "../../../../styles/modal.module.scss";
import styles from "../../../shops/HeaderShopToggle/components/RequestResult/RequestResult.module.scss";
import ownClasses from "./Alerts.module.scss";
import Switch from "../../../../components/ui/switch/Switch";
import CounterInput from "../../../../components/ui/counterInput/CounterInput";
import SearchField from "../../../shops/HeaderShopToggle/components/SearchField/SearchField";
import RequestResult from "../../../shops/HeaderShopToggle/components/RequestResult/RequestResult";

const ONE_HOUR = 3600000;

export default function AlertsModal({
  isOpen,
  toggle,
  alerts,
  alertsParams,
  onSubmit,
  initialSilenceTime,
}) {
  const { toggled, toggledOff } = Object.entries(alerts)
    .sort()
    .reduce(
      (acc, [parameter, alert]) => {
        acc[alert ? "toggled" : "toggledOff"][parameter] = !!alert;
        return acc;
      },
      { toggled: {}, toggledOff: {} }
    );

  const initialState = { ...toggled, ...toggledOff };

  const [alertsTogglers, setAlertsTogglers] = useState(initialState);
  const [allAlertsToggled, setAllAlertsToggled] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [silenceHours, setSilenceHours] = useState(24);

  useEffect(() => {
    const initialTimeInHours = initialSilenceTime / ONE_HOUR;

    setSilenceHours(initialTimeInHours);
    setAlertsTogglers(initialState);
  }, [alerts]);

  useEffect(() => {
    const hasFalseState = Object.values(alertsTogglers).some((el) => !el);

    setAllAlertsToggled(!hasFalseState);
  }, [alertsTogglers]);

  const filteredTogglers = useMemo(
    () =>
      Object.entries(alertsTogglers).filter((el) =>
        el[0].toLowerCase().includes(searchString)
      ),
    [alertsTogglers, searchString]
  );

  const handleSubmit = () => {
    const silenceTime = silenceHours * ONE_HOUR;
    onSubmit({ alertsTogglers, silenceTime });
    toggle();
  };

  const handleCancel = () => {
    setAlertsTogglers(initialState);
    toggle();
  };

  const handleTogglerChange = (newAlertState) => {
    setAlertsTogglers((alertsTogglers) => ({
      ...alertsTogglers,
      ...newAlertState,
    }));
  };

  const handleSearchChange = (value) => setSearchString(value.toLowerCase());

  const incrementValue = () => {
    setSilenceHours((prev) => prev + 1);
  };

  const decrementValue = () => {
    setSilenceHours((prev) => (prev > 1 ? prev - 1 : 0));
  };

  const handleTimeChange = (value) => {
    const numValue = Number(value);
    if (!isFinite(numValue) || numValue < 0) return;
    setSilenceHours(numValue);
  };

  const toggleAll = () => {
    const hasFalseState = Object.values(alertsTogglers).some((el) => !el);

    const newAlertsTogglers = {};
    Object.keys(alertsTogglers).forEach(
      (parameter) => (newAlertsTogglers[parameter] = hasFalseState)
    );

    setAlertsTogglers(newAlertsTogglers);
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={handleCancel}
        contentClassName={classes.Modal__Content__Alerts}
        centered={true}
        className={classes.Modal__Dialog}
        backdrop={"background: rgba(19, 50, 112, 0.20);"}
      >
        <div className={classes.Modal__Header}>
          <div>
            <img
              src={ManageAlertsIcon}
              className={classes.Modal__ManageAlertsIcon}
              alt="Siren Icon"
            />
            <div className={classes.Modal__AlertTitle}>Manage Alerts</div>
          </div>
          <CloseIcon
            className={classes.Modal__CloseIcon}
            onClick={handleCancel}
          />
        </div>
        <hr className={classes.Modal_HorizontalLine} />
        <ModalBody className={classes.Modal__Body}>
          <div className={ownClasses.Modal__Container}>
            <div className={ownClasses.Modal__Label}>
              <div className={ownClasses.Modal__CounterLabel}>
                Alerts{" "}
                <span>{`(${
                  Object.values(alertsTogglers).filter((el) => el).length
                }/${Object.keys(alertsTogglers).length})`}</span>
              </div>
              <div
                className={ownClasses.Modal__ToggleButton}
                onClick={() => toggleAll()}
              >
                {allAlertsToggled ? "Unselect All" : "Select All"}
              </div>
            </div>
            <SearchField
              className={ownClasses.AlertsSearch}
              placeholder="Search for alert..."
              value={searchString}
              onChange={handleSearchChange}
            />
            <div className={ownClasses.Modal__ItemsContainer}>
              {filteredTogglers.length ? (
                filteredTogglers.map(([parameter, state]) => (
                  <div
                    className={ownClasses.Modal__ItemWrapper}
                    key={parameter}
                  >
                    <FormGroup
                      row
                      className={ownClasses.Modal__Item}
                      key={parameter}
                    >
                      <Label
                        for={parameter}
                        className={ownClasses.Modal__Item__Label}
                      >
                        {alertsParams[parameter].name} Alert
                      </Label>
                      <Switch
                        name={parameter}
                        isChecked={state}
                        onChange={() =>
                          handleTogglerChange({ [parameter]: !state })
                        }
                      />
                    </FormGroup>
                  </div>
                ))
              ) : (
                <RequestResult
                  wrapperClassName={styles.noSearchDataAlertsWrapper}
                  className={cn(styles.noSearchData, "mt-0 mb-0")}
                  image={<NoResultIcon width={160} height={160} />}
                  title="No Results Found"
                  message={
                    "We couldn't find anything matching your search.\nPlease try again."
                  }
                />
              )}
            </div>
          </div>
          <div
            className={cn(
              ownClasses.Modal__Container,
              ownClasses.Modal__AlertFrequency
            )}
          >
            <div className={ownClasses.Modal__CounterLabel}>
              Alert Frequency
            </div>
            <div className={ownClasses.Modal__AlertFrequencyGroup}>
              <div className={ownClasses.Modal__AlertFrequencyLabel}>
                Receive each alert once per:
              </div>
              <CounterInput
                onChange={handleTimeChange}
                incrementValue={incrementValue}
                decrementValue={decrementValue}
                adiitionalLabel="hour(s)"
                value={silenceHours}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className={ownClasses.Modal__AlertsFooter}>
          <button className={classes.Modal__CancelBtn} onClick={handleCancel}>
            Cancel
          </button>
          <button className={classes.Modal__SubmitBtn} onClick={handleSubmit}>
            Save
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
