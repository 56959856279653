import React, { useState, useRef, useEffect } from "react";
import cn from "classnames";

import TextInput from "../input/TextInput";
import Track from "./Track";
import Slider from "./Slider";
import { ReactComponent as AddIcon } from "../../../assets/icons/alerts/add-range-plus.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/alerts/delete-cross.svg";

import {
  clamp,
  getPercentagesFromValues,
  getPositionsFromValues,
  getValueFromPosition,
  getStepValueFromValue,
  length,
  distanceTo,
  formInputValue,
} from "./utils";
import classes from "./styles.module.scss";

export default function RangeInput({
  label,
  className,
  value = { min: 10, max: 35 },
  step = 5,
  minValue = 0,
  maxValue = 50,
  onChange,
  onFinalChange,
  onSliderFinalChange,
  onInputFinalChange,
  withLabels = false,
  withInputs = false,
  inscription,
  inscriptionPosition,
  measureLabel,
  addRange,
  deleteRange,
  withAddRange,
}) {
  const [values, setValues] = useState({ min: value.min, max: value.max });
  const [isInputRangeIncorrect, setIsInputRangeIncorrect] = useState(false);

  useEffect(() => {
    setIsInputRangeIncorrect(false);
  }, [values]);

  useEffect(() => {
    setValues({ min: value.min, max: value.max });
  }, [value]);

  const trackNode = useRef(null);
  const stateRef = useRef(values);

  /* function provide possibility handle change event inside listener */
  function setState(values) {
    stateRef.current = values;
    setValues(values);
  }

  /* return the bounding rect of the track */
  function getTrackClientRect() {
    return trackNode.current.getBoundingClientRect();
  }

  /* convert an event into a point */
  function getPositionFromEvent(event, clientRect) {
    const length = clientRect.width;
    const { clientX } = event.touches ? event.touches[0] : event;

    return {
      x: clamp(clientX - clientRect.left, 0, length),
      y: 0,
    };
  }

  /* update the position of a slider */
  function updatePosition(key, position) {
    const positions = getPositionsFromValues(
      values,
      minValue,
      maxValue,
      getTrackClientRect()
    );

    positions[key] = position;
    return updatePositions(positions);
  }

  /**
   * return true if the difference between the new and the current value is
   * greater or equal to the step amount of the component
   */
  function hasStepDifference(newValues) {
    return (
      length(newValues.min, values.min) >= step ||
      length(newValues.max, values.max) >= step
    );
  }

  /* return true if the range is within the max and min value of the component */
  function isWithinRange(values) {
    return (
      values.min >= minValue &&
      values.max <= maxValue &&
      values.min < values.max
    );
  }

  /* return true if the new value should trigger a render */
  function shouldUpdate(values) {
    return isWithinRange(values) && hasStepDifference(values);
  }

  /* update the values of sliders */
  function updateValues(values) {
    if (shouldUpdate(values)) {
      setState(values);
      onChange && onChange(values);
    }
  }

  /* update the positions of sliders */
  function updatePositions(positions) {
    const values = {
      min: getValueFromPosition(
        positions.min,
        minValue,
        maxValue,
        getTrackClientRect()
      ),
      max: getValueFromPosition(
        positions.max,
        minValue,
        maxValue,
        getTrackClientRect()
      ),
    };

    const transformedValues = {
      min: getStepValueFromValue(values.min, step),
      max: getStepValueFromValue(values.max, step),
    };

    return updateValues(transformedValues);
  }

  function onSliderDrag(event, key) {
    const position = getPositionFromEvent(event, getTrackClientRect());

    return updatePosition(key, position);
  }

  /* return the slider key closest to a point */
  function getKeyByPosition(position) {
    const positions = getPositionsFromValues(
      values,
      minValue,
      maxValue,
      getTrackClientRect()
    );

    const distanceToMin = distanceTo(position, positions.min);
    const distanceToMax = distanceTo(position, positions.max);

    if (distanceToMin < distanceToMax) {
      return "min";
    }

    return "max";
  }

  function onTrackMouseDown(event, position) {
    event.preventDefault();

    return updatePosition(getKeyByPosition(position), position);
  }

  function renderSliders() {
    const percentages = getPercentagesFromValues(values, minValue, maxValue);
    return ["min", "max"].map((key) => {
      const percentage = percentages[key];

      return (
        <Slider
          key={key}
          type={key}
          percentage={percentage}
          onSliderDrag={onSliderDrag}
          onFinalChange={onSliderFinalChange}
        />
      );
    });
  }

  function RangeMarks() {
    const { min, max } = values;
    const marksCount =
      minValue > 0
        ? Math.round(maxValue / step)
        : Math.round(maxValue / step) + 1;

    return (
      <div
        className={cn(classes.RangeMarks, "flex", "justify-between", "ml-4")}
      >
        {Array(marksCount)
          .fill(1)
          .map((_, index) => {
            let labelValue = index * step;
            if (minValue > 0) {
              labelValue = index === 0 ? 1 : (index + 1) * step;
            }
            const isActive = min <= labelValue && max >= labelValue;
            return (
              <div
                key={index}
                className={cn(
                  classes.RangeMark,
                  "font-md",
                  "font-normal",
                  "text-color-tertiary",
                  {
                    [classes.isActive]: isActive,
                  }
                )}
              >
                {labelValue}
              </div>
            );
          })}
      </div>
    );
  }

  const formattedMinValue = formInputValue(
    values.min,
    inscription,
    inscriptionPosition
  );

  const formattedMaxValue = formInputValue(
    values.max,
    inscription,
    inscriptionPosition
  );

  const percentages = getPercentagesFromValues(values, minValue, maxValue);

  const onMinValueChange = (val) => {
    const value = Number(val.replace(",", ""));
    if (val && minValue <= value && value <= values.max) {
      setValues({ min: value, max: values.max });
      onChange({ min: value, max: values.max });
      setIsInputRangeIncorrect(false);
    } else {
      setIsInputRangeIncorrect(true);
    }
  };
  const onMaxValueChange = (val) => {
    const value = Number(val.replace(",", ""));
    if (val && values.min <= value && value <= maxValue) {
      setValues({ min: values.min, max: value });
      onChange({ min: values.min, max: value });
      setIsInputRangeIncorrect(false);
    } else {
      setIsInputRangeIncorrect(true);
    }
  };

  return (
    <div className={cn(className, classes.InputWrapper)}>
      {label && (
        <div
          className={cn(
            classes.Label,
            { [classes.withInputs]: withInputs },
            "font-semibold font-md text-color-tertiary mb-8"
          )}
        >
          {label}
          {withAddRange && (
            <span
              onClick={addRange}
              className={cn(classes.rangeBtn, classes.addRange)}
            >
              <AddIcon />
              <span>Add New</span>
            </span>
          )}
          {!withAddRange && (
            <span
              onClick={deleteRange}
              className={cn(classes.rangeBtn, classes.deleteRange)}
            >
              <DeleteIcon />
              <span>Delete</span>
            </span>
          )}
        </div>
      )}
      <div className={classes.RangeInput}>
        {withInputs && (
          <>
            <TextInput
              // value={formattedMinValue}
              value={values.min.toLocaleString("en-US")}
              isRangeError={isInputRangeIncorrect}
              // TODO MP: handle input change event in a different way
              onInputChange={onMinValueChange}
              onFinalChange={onInputFinalChange}
              cls={classes.RangeTextInput}
            />
            <div className={classes.Separator} />
            <TextInput
              // value={formattedMaxValue}
              value={values.max.toLocaleString("en-US")}
              isRangeError={isInputRangeIncorrect}
              // TODO MP: handle input change event in a different way
              onInputChange={onMaxValueChange}
              onFinalChange={onInputFinalChange}
              cls={classes.RangeTextInput}
            />
          </>
        )}
        <div
          className={cn(classes.Range, { [classes.withInputs]: withInputs })}
        >
          {withLabels && RangeMarks()}
          <div className="flex justify-between items-center mb-2">
            <div>
              {minValue === values.min
                ? "Min"
                : values.min.toLocaleString("en-US")}
            </div>
            <div>{measureLabel}</div>
            <div>
              {maxValue === values.max
                ? "Max"
                : values.max.toLocaleString("en-US")}
            </div>
          </div>
          <Track
            ref={trackNode}
            percentages={percentages}
            onTrackMouseDown={onTrackMouseDown}
            onFinalChange={onSliderFinalChange}
          >
            {renderSliders()}
          </Track>
        </div>
      </div>
    </div>
  );
}
