import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchAllUsers,
  createUser as createUserRequest,
  editUser as editUserRequest,
  deleteUser as deleteUserRequest,
} from "../../services/api.service";
import { keyBy } from "lodash";

const fetchUsersThunk = createAsyncThunk("users/fetchAll", fetchAllUsers);

const createUserThunk = createAsyncThunk(
  "users/createUser",
  async (data, thunkAPI) => {
    try {
      await createUserRequest(data);
      await thunkAPI.dispatch(fetchUsersThunk());
    } catch (e) {
      const message =
        (e.response && e.response.data && e.response.data.error) ||
        e.message ||
        e;
      thunkAPI.dispatch(setUserError({ message }));
      throw e;
    }
  }
);

const editUserThunk = createAsyncThunk(
  "users/editUser",
  async (data, thunkAPI) => {
    await editUserRequest(data);
    thunkAPI.dispatch(fetchUsersThunk());
  }
);

const deleteUserThunk = createAsyncThunk("users/deleteUser", deleteUserRequest);

const usersSlice = createSlice({
  name: "users",
  initialState: {
    byId: null,
    isLoading: false,
    hasError: false,
    isShownEditModal: false,
    isShownCreateModal: false,
    error: "",
  },
  reducers: {
    setUserFilter(state, action) {
      state.userFilter = action.payload;
    },
    setUserError(state, action) {
      state.error = action.payload.message;
    },
    showModal(state, action) {
      if (action.payload.modal === "edit") {
        state.isShownEditModal = action.payload.isOpen;
      } else {
        state.isShownCreateModal = action.payload.isOpen;
      }
    },
    clearUserError(state) {
      state.error = "";
    },
    selectUser(state, action) {
      state.selectedUser = action.payload;
    },
  },
  extraReducers: {
    [fetchUsersThunk.pending](state) {
      state.byId = null;
      state.isLoading = true;
      state.hasError = false;
    },
    [fetchUsersThunk.fulfilled](state, action) {
      state.isLoading = false;
      state.hasError = false;
      state.byId = keyBy(action.payload, "_id");
    },
    [fetchUsersThunk.rejected](state) {
      state.byId = null;
      state.isLoading = false;
      state.hasError = true;
    },
    [createUserThunk.pending](state) {
      state.isLoading = true;
      state.hasError = false;
    },
    [createUserThunk.fulfilled](state) {
      state.isLoading = false;
      state.hasError = false;
      state.isShownCreateModal = false;
    },
    [createUserThunk.rejected](state) {
      state.byId = null;
      state.isLoading = false;
      state.hasError = true;
      state.isShownCreateModal = true;
    },
    [editUserThunk.pending](state) {
      state.isLoading = true;
      state.hasError = false;
    },
    [editUserThunk.fulfilled](state) {
      state.isLoading = false;
      state.hasError = false;
      state.isShownEditModal = false;
    },
    [editUserThunk.rejected](state) {
      state.isLoading = false;
      state.hasError = true;
      state.isShownEditModal = true;
    },
    [deleteUserThunk.fulfilled](state, action) {
      delete state.byId[action.meta.arg.id];
    },
  },
});

export {
  fetchUsersThunk as fetchUsers,
  createUserThunk as createUser,
  editUserThunk as editUser,
  deleteUserThunk as deleteUser,
};

const { actions, reducer } = usersSlice;

export const {
  setUserFilter,
  setUserError,
  showModal,
  clearUserError,
  selectUser,
} = actions;

export default reducer;
