import { createSelector } from "@reduxjs/toolkit";

export const isLoaded = (state) => state.tenants.byId;

export const getTenants = (state) =>
  state.tenants.byId && Object.values(state.tenants.byId);

export const getSelectedTenant = (state) => state.tenants.selectedTenant;

export const getTenantsShown = (state) => state.tenants.isTenantsShown;

export const isTenantsLoading = (state) => state.tenants.isLoading;

export const getIsFirstTenantRender = (state) => state.tenants.isFirstRender;

export const getIsTenantsError = (state) => state.tenants.hasError;
