import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Container, Row, Col, Alert } from "reactstrap";
import cn from "classnames";

import { resendCustomerConsent } from "../../services/api.service";

import {
  fetchCustomerVehicles,
  selectVehicle,
  closeAlert,
  fetchDiagnosticOptions,
  selectChartOption,
} from "./vehicles.slice";
import {
  getSelectedVehicle,
  getDeviceAlertStatus,
  getFilteredVehicles,
  getVehicleFilter,
} from "./vehicles.selectors";
import {
  getSelectedCustomer,
  getIdsByVins,
} from "../customers/customers.selectors";
import { selectCustomer } from "../customers/customers.slice";
import CustomerBanner from "./components/customer-banner/CustomerBanner";
import CustomerVehiclesPanel from "./components/customer-vehicles-panel/CustomerVehiclesPanel";
import GeneralVehicleData from "./components/general-vehicle-data/GeneralVehicleData";
import ReadinessMonitors from "./components/readiness-monitors/ReadinessMonitors";
import RecommendedServices from "./components/recommended-services/RecommendedServices";
import ServicesHistory from "./components/services-history/ServicesHistory";
import PidGraphs from "./components/pid-graphs/PidGraphs";
import DtcCodes from "./components/dtc-codes/DtcCodes";
import Alerts from "./components/alerts/Alerts";

import { ReactComponent as AttentionIcon } from "../../assets/icons/attention.svg";
import { ReactComponent as SendSMSIcon } from "../../assets/icons/force-send-sms.svg";
import alertClasses from "../../styles/alert.module.scss";
import "./CustomerDetails.scss";

export default function CustomerDetailsPage() {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  const [visible, setVisible] = useState(false);
  const { vehicleId, id: customerId, vin, shopId } = params;
  const [isConsentWarningVisible, setConsentWarningVisible] = useState(false);

  const selectedVehicle = useSelector(getSelectedVehicle);
  const isShownDeviceAlert = useSelector(getDeviceAlertStatus);
  const selectedCustomer = useSelector(getSelectedCustomer);
  const idsByVins = useSelector(getIdsByVins);

  const viewableVehicles = useSelector(getFilteredVehicles);
  const vehicleFilter = useSelector(getVehicleFilter);
  const isVehicleNotFound =
    selectedCustomer &&
    !selectedCustomer?.isVehiclesListLoading &&
    !viewableVehicles.length &&
    vehicleFilter;

  const alarmRef = useRef(null);

  useEffect(() => {
    const updateAlarmHeight = () => {
      const alarm = alarmRef.current;

      if (alarm) {
        const alarmHeight = alarm.offsetHeight;
        document.documentElement.style.setProperty(
          "--alarm-height",
          `${alarmHeight}px`
        );
      }
    };
    updateAlarmHeight();
    window.addEventListener("resize", updateAlarmHeight);

    return () => {
      window.removeEventListener("resize", updateAlarmHeight);
    };
  }, []);

  useEffect(() => {
    if (vin) {
      const ids = idsByVins[vin];
      if (ids) {
        window.parent.postMessage({ type: "vinFound" }, "*");
        history.push(
          `/${shopId}/customer/${ids.customerId}/vehicle/${ids.vehicleId}`
        );
      } else {
        window.parent.postMessage({ type: "vinNotFound" }, "*");
        history.push(`/${shopId}/`);
      }
    }
  }, [vin]);

  useEffect(() => {
    if (customerId) {
      dispatch(selectVehicle(null));
      dispatch(selectCustomer(customerId));
      dispatch(fetchCustomerVehicles(customerId));
    }
  }, [customerId]);

  useEffect(() => {
    if (vehicleId) {
      dispatch(selectVehicle(vehicleId));
    }
  }, [vehicleId]);

  useEffect(() => {
    if (selectedVehicle) {
      dispatch(selectChartOption(null));
      dispatch(fetchDiagnosticOptions(selectedVehicle.deviceId));
    }
  }, [selectedVehicle && selectedVehicle.deviceId]);

  useEffect(() => {
    const isDeviceDetached =
      selectedVehicle &&
      selectedVehicle.overview &&
      selectedVehicle.overview.data &&
      !selectedVehicle.overview.data.isDeviceCommunicating;

    const isDeviceAlertClosed =
      selectedVehicle &&
      isShownDeviceAlert &&
      isShownDeviceAlert.hasOwnProperty(selectedVehicle.id);

    setVisible(
      Boolean(selectedVehicle && isDeviceDetached && !isDeviceAlertClosed)
    );

    return () => setVisible(false);
  }, [selectedVehicle]);

  useEffect(() => {
    const isConsentWarningVisible =
      selectedCustomer && !selectedCustomer.customerConsented;
    setConsentWarningVisible(isConsentWarningVisible);
  }, [selectedCustomer]);

  const handleCloseAlert = () => {
    dispatch(closeAlert(selectedVehicle.id));
    setVisible(false);
  };

  const handleCloseConsentAlert = () => {
    setConsentWarningVisible(false);
  };

  const handleResendApprovalRequest = () => {
    resendCustomerConsent(customerId);
    setConsentWarningVisible(false);
  };

  if (vin) return null;

  return (
    <Container fluid className="p-5 Container MobileContainer">
      <Row>
        <Col xs="12">
          <CustomerBanner />
        </Col>
      </Row>
      <Row className="VehiclesPanelContainer">
        <Col xs="12">
          <CustomerVehiclesPanel />
        </Col>
      </Row>
      <Row className="Row pt-24">
        <Col xs="12" className="d-flex px-12">
          <Alert
            isOpen={isConsentWarningVisible}
            toggle={handleCloseConsentAlert}
            id={alertClasses.ConsentAlert}
            innerRef={alarmRef}
          >
            <AttentionIcon className="WarningIcon" />
            <div className={alertClasses.text}>
              Unable to unlock access to your vehicles. You may have entered the
              wrong phone number. Try to resend approval request or contact
              support.
            </div>
            <div
              className={alertClasses.button}
              onClick={handleResendApprovalRequest}
            >
              <SendSMSIcon />
              Resend Approval Request
            </div>
          </Alert>
          <Alert
            isOpen={visible}
            toggle={handleCloseAlert}
            id={alertClasses.WarningAlert}
          >
            <AttentionIcon className="WarningIcon" /> Please note! The device
            for this vehicle is currently disconnected. Only historical data is
            shown.
          </Alert>
        </Col>
        {!isVehicleNotFound && (
          <>
            <Col xs="12" lg="4" className="MobileWrapper d-flex px-12">
              <GeneralVehicleData className="VehicleInfoPanel" />
            </Col>
            <Col xs="12" lg="8" className="px-12">
              <Row className="Row">
                <Col xs="12" className="Col px-12">
                  <ReadinessMonitors className="VehicleInfoPanel d-flex flex-column" />
                </Col>
                <Col xs="12" className="Col px-12">
                  <ServicesHistory className="VehicleInfoPanel d-flex flex-column" />
                </Col>
              </Row>
              <Row className="Row">
                <Col xs="12" className="px-12">
                  <DtcCodes className="VehicleInfoPanel d-flex  w-100" />
                </Col>
                <Col xs="12" className="px-12">
                  <PidGraphs className="VehicleInfoPanel w-100" />
                </Col>
                <Col xs="12" className="px-12">
                  <Alerts className="VehicleInfoPanel w-100" />
                </Col>
              </Row>
            </Col>
          </>
        )}
      </Row>
    </Container>
  );
}
