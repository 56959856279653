import React from "react";
import cn from "classnames";
import classes from "./TotalFuelUsed.module.scss";

import { ReactComponent as EngineOperationIcon } from "../../../../assets/icons/engine-operation.svg";
import { useSelector } from "react-redux";
import {
  getLatestFuelMetrics,
  getEngineOperationalTime,
  getLatestGenericMetrics,
} from "../../vehicles.selectors";
import { getMeasurementSystem } from "../../../customers/customers.selectors";

export default function TotalFuelUsed() {
  const fuelUsageMetrics = useSelector(getLatestFuelMetrics);
  const engineOperationalTime = useSelector(getEngineOperationalTime);
  const {
    distanseWithMilOn,
    timeSinceMilOn,
    disanceSinceCodesCleared,
    timeSinceCodesCleared,
    warmsup,
  } = useSelector(getLatestGenericMetrics);
  const { fuelUsed, idleFuelUsed } = fuelUsageMetrics;
  const idlePercentage = (idleFuelUsed * 100) / fuelUsed;

  const [engineOperationalHours, engineOperationalMinutes] = [
    Math.floor(engineOperationalTime / 3600),
    Math.floor((engineOperationalTime % 3600) / 60),
  ];

  if (!fuelUsed && !idleFuelUsed) return null;

  return (
    <div className={classes.TotalFuelUsed}>
      <div className={cn(classes.TotalFuelUsed__MobileNameContainer, "mb-3")}>
        <span className={classes.TotalFuelUsed__Title}>Total fuel used </span>
        <span className={classes.TotalFuelUsed__Description}>
          (since telematics device install)
        </span>
      </div>
      <div className={classes.TotalFuelUsed__Count}>
        <div className={classes.TotalFuelUsed__CountGraph}>
          <div
            className={classes.TotalFuelUsed__CountIdlingGraph}
            style={{ width: `${idlePercentage}%` }}
          ></div>
        </div>
        <div className={classes.TotalFuelUsed__GraphLegend}>
          <div className="mt-3">
            <LegendItem
              label="Total fuel used"
              value={fuelUsed.toFixed(1)}
              className="TotalFuelUsed__GraphLegendDotTotal"
            />
          </div>
          {idleFuelUsed && (
            <LegendItem
              label="Total Fuel Used While Idling"
              value={idleFuelUsed.toFixed(1)}
              className="TotalFuelUsed__GraphLegendDotIdle"
            />
          )}
        </div>
      </div>
      {engineOperationalTime && (
        <div
          className={cn(classes.TotalFuelUsed__EngineOperation, "mt-4", "mb-4")}
        >
          <EngineOperationIcon />
          <div className={classes.TotalFuelUsed__EngineOperationTitle}>
            Engine Operational Time
          </div>
          <div>
            <span className={classes.TotalFuelUsed__EngineOperationTime}>
              {engineOperationalHours}
            </span>{" "}
            <span className={classes.TotalFuelUsed__EngineOperationUnit}>
              h
            </span>{" "}
            <span className={classes.TotalFuelUsed__EngineOperationTime}>
              {engineOperationalMinutes}{" "}
            </span>{" "}
            <span className={classes.TotalFuelUsed__EngineOperationUnit}>
              m
            </span>
          </div>
        </div>
      )}
      {disanceSinceCodesCleared ||
      timeSinceCodesCleared ||
      distanseWithMilOn ||
      timeSinceMilOn ||
      warmsup ? (
        <div className={classes.GenericItemsContainer}>
          {disanceSinceCodesCleared ? (
            <GenericItem
              label="Generic Distance Traveled Since Codes Cleared"
              value={disanceSinceCodesCleared}
            />
          ) : null}
          {timeSinceCodesCleared ? (
            <GenericItem
              label="Generic Time Since Codes Cleared"
              value={timeSinceCodesCleared}
              time
            />
          ) : null}
          {distanseWithMilOn ? (
            <GenericItem
              label="Generic Distance Traveled With MIL on"
              value={distanseWithMilOn}
            />
          ) : null}
          {timeSinceMilOn ? (
            <GenericItem
              label="Generic Time Since MIL on"
              value={timeSinceMilOn}
              time
            />
          ) : null}
          {warmsup ? (
            <GenericItem
              label="Generic warm-ups since codes cleared"
              value={warmsup}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

const LegendItem = ({ label, value, className }) => {
  const measurementSystem = useSelector(getMeasurementSystem);
  const isMeasurementSystemImperial = measurementSystem === "imperial";

  return (
    <div
      className={cn(
        classes.TotalFuelUsed__GraphLegendItem,
        "flex items-center justify-between"
      )}
    >
      <div className="flex items-center">
        <div
          className={cn(
            classes.TotalFuelUsed__GraphLegendDot,
            classes[className]
          )}
        />
        <span className={classes.TotalFuelUsed__GraphLegendLabel}>{label}</span>
      </div>
      <div>
        <span className={classes.TotalFuelUsed__GraphLegendValue}>{value}</span>{" "}
        <span className={classes.TotalFuelUsed__GraphLegendUnit}>
          {isMeasurementSystemImperial ? "gal" : "l"}
        </span>
      </div>
    </div>
  );
};

const GenericItem = ({
  label,
  value,
  horizontal,
  vertical,
  paddingLeft,
  time,
}) => {
  const measurementSystem = useSelector(getMeasurementSystem);
  const isMeasurementSystemImperial = measurementSystem === "imperial";

  const horizontalSeparatorStyles = {
    [horizontal]: 0,
  };

  const [hours, minutes] = time
    ? [Math.floor(value / 3600), Math.floor((value % 3600) / 60)]
    : [];

  return (
    <div className={classes.TotalFuelUsed__GenericsItem}>
      <div className={classes.TotalFuelUsed__GenericLabel}>{label}</div>
      <div className="mb-3">
        {time ? (
          <div>
            <span className={classes.TotalFuelUsed__GenericValue}>{hours}</span>{" "}
            <span className={classes.TotalFuelUsed__GenericUnit}>h</span>{" "}
            <span className={classes.TotalFuelUsed__GenericValue}>
              {minutes}
            </span>{" "}
            <span className={classes.TotalFuelUsed__GenericUnit}>m</span>
          </div>
        ) : (
          <div>
            <span className={classes.TotalFuelUsed__GenericValue}>{value}</span>
            {label === "Generic warm-ups since codes cleared" ? null : (
              <span className={classes.TotalFuelUsed__GenericUnit}>
                {isMeasurementSystemImperial ? " mi" : " km"}
              </span>
            )}
          </div>
        )}
      </div>

      {horizontal && (
        <div
          className={classes.TotalFuelUsed__HorizontalSeparator}
          style={horizontalSeparatorStyles}
        />
      )}

      {vertical && <div className={classes.TotalFuelUsed__VerticalSeparator} />}
    </div>
  );
};
