import PropTypes from "prop-types";

const DATE_TYPE = PropTypes.oneOfType([
  PropTypes.instanceOf(Date),
  PropTypes.string,
]);

export const PROP_TYPES_TEMPLATES = {
  CHILDREN: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.oneOf([null, undefined]),
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.node,
  ]),
  INPUT_VALUE: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  CLASS_NAME: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  LABEL: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  DATE: DATE_TYPE,
  SELECTED_DATE_RANGE: PropTypes.shape({
    startDate: DATE_TYPE,
    endDate: DATE_TYPE,
  }),
  ERROR: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.object,
  ]),
  ICON: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]),
  COMPONENT: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
  ]),
  TITLE: PropTypes.string,
  COLOR: PropTypes.string,
  REF: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.element]),
  APPOINTMENTS_STATUS: PropTypes.oneOf([
    "RequestMade",
    "Booked",
    "Finished",
    "Missed",
    "Declined",
    "CheckedIn",
    "InProgress",
  ]),
  CAMPAIGN_STATUSES: PropTypes.oneOf(["Active", "Drafts", "Archive"]),
  CAMPAIGNS_LIST_TYPE: PropTypes.oneOf(["active", "draft", "archive"]),
  CAMPAIGNS_TYPE: PropTypes.oneOf([
    "FollowUp",
    "Outreach",
    "ServiceReminders",
    "DeclinedServices",
  ]),
  MARKETING_TYPES: PropTypes.oneOf(["Text", "Email", "Postcard", "Letter"]),
  RANGE_TYPES: PropTypes.oneOf([
    "all-time",
    "this-month",
    "previous-month",
    "next-month",
    "last-one-month",
    "next-one-month",
    "last-three-months",
    "next-three-months",
    "last-six-months",
    "next-six-months",
    "last-twelve-months",
    "next-twelve-months",
    "this-year",
    "previous-year",
    "next-year",
    "custom",
  ]),
  APPOINTMENTS_SCHEDULING_TYPES: PropTypes.oneOf([
    "Appointment",
    "Task",
    "BlockedTime",
  ]),
  MESSAGES_TYPES: PropTypes.oneOf(["text", "mails"]),
  CUSTOMERS_GROUP_TYPES: PropTypes.oneOf(["Dynamic", "StaticList"]),
};
