import React, { useState } from "react";
import cn from "classnames";
import onClickOutside from "react-onclickoutside";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import { joinShopAddress } from "../../../../../utils";
import SearchField from "../SearchField/SearchField";
import RequestResult from "../RequestResult/RequestResult";
import { NoResultIcon, ArrowBackIcon } from "../../../../../assets/icons";
import styles from "./HeaderShopsList.module.scss";
import { ShopItem } from "./components/ShopItem/ShopItem";
import Typography from "../../../../../components/typography/Typography";
import { getAdminStatus } from "../../../../../App/ui.slice";
import {
  setTenantsShown,
  fetchTenants,
} from "../../../../tenants/tenants.slice";

function HeaderShopsList({
  shops,
  selectedShopId,
  onShopClick,
  setDropdownOpen,
  toggleDisplayPosition,
}) {
  HeaderShopsList.handleClickOutside = () => {
    setDropdownOpen(false);
  };
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState("");
  const isAdmin = useSelector(getAdminStatus);

  const filteredList = shops.filter(({ name }) => {
    const normalizedSearchValue = searchValue.trim().toLowerCase();
    return name.toLowerCase().includes(normalizedSearchValue);
  });

  const isShopsAvailable = shops.length > 0 && filteredList.length > 0;

  const showTenantsList = () => {
    dispatch(fetchTenants());
    dispatch(setTenantsShown(true));
  };

  return (
    <div
      className={cn(
        styles.HeaderShopsList,
        { [styles.rightPosition]: toggleDisplayPosition === "right" },
        { [styles.leftPosition]: toggleDisplayPosition === "left" },
        "absolute border-radius-7 box-shadow-dark pb-10 pt-10"
      )}
    >
      {isAdmin && (
        <div
          onClick={showTenantsList}
          className={cn("flex", { "mb-10": shops.length === 1 })}
        >
          <ArrowBackIcon className={styles.arrowBack} />
          <Typography fontSize="xl" fontWeight="bold" className="mx-20 mt-10">
            Shop Locations
          </Typography>
        </div>
      )}

      {shops.length > 1 && (
        <div className={styles.searchField}>
          <SearchField
            placeholder="Search for location..."
            value={searchValue}
            onChange={setSearchValue}
          />
        </div>
      )}
      <div className={styles.scrollbar}>
        <div>
          {isShopsAvailable && (
            <ul>
              {filteredList.map((shop) => {
                const { id, name } = shop;
                const shopAddress = joinShopAddress(shop, false);

                return (
                  <ShopItem
                    key={id}
                    name={name}
                    streetAddress={shopAddress}
                    isSelected={id === selectedShopId}
                    onShopClick={() => onShopClick(shop)}
                  />
                );
              })}
            </ul>
          )}

          {!isShopsAvailable && (
            <RequestResult
              wrapperClassName={cn(styles.noData)}
              image={<NoResultIcon width={100} height={100} />}
              title="No results found"
              message={
                "We can't find any results matching your search.\nPlease check spelling or try other keywords."
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}

HeaderShopsList.propTypes = {
  shops: PropTypes.array,
  selectedShopId: PropTypes.string,
  onShopClick: PropTypes.func,
  setDropdownOpen: PropTypes.func,
  toggleDisplayPosition: PropTypes.oneOf(["right", "left"]),
};

const clickOutsideConfig = {
  handleClickOutside: () => HeaderShopsList.handleClickOutside,
};

export default onClickOutside(HeaderShopsList, clickOutsideConfig);
