import React from "react";
import cn from "classnames";

import { ReactComponent as CalendarIcon } from "../../assets/icons/calendar-icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";

import classes from "./Input.module.scss";

const Input = ({
  onClick,
  value = "",
  placeholder,
  disabled,
  label,
  isPickerOpen,
  withCloseBtn,
  onCancelClick,
}) => (
  <div className={classes.DatePicker__input__wrapper} onClick={onClick}>
    {label && <label className={classes.DatePicker__label}>{label}</label>}

    {withCloseBtn && isPickerOpen && (
      <CloseIcon
        className={cn(classes.DatePicker__closeIcon, {
          [classes["DatePicker__icon--withLabel"]]: !!label,
        })}
        onClick={() => onCancelClick()}
      />
    )}
    <CalendarIcon
      className={cn(classes.DatePicker__icon, {
        [classes["DatePicker__icon--withLabel"]]: !!label,
        [classes.DatePicker__icon_blue]: isPickerOpen,
      })}
    />
    <input
      type="text"
      readOnly
      value={value}
      className={cn(
        classes.DatePicker__input,
        isPickerOpen && classes.DatePicker__input_active
      )}
      placeholder={placeholder}
      disabled={disabled}
    />
  </div>
);

export default Input;
