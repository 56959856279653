import React, { useState } from "react";
import cn from "classnames";
import { useSelector } from "react-redux";

import CustomersTable from "./customer-table/CustomersTable";
import CustomerCounter from "./customer-counter/CustomerCounter";
import CustomerSearch from "./customer-search/CustomerSearch";
import { CustomerModal } from "../customer-details/components/customer-modal/CustomerModal";
import {
  getCustomers,
  getFilteredCustomers,
} from "../../scenes/customers/customers.selectors";
import classes from "./Customers.module.scss";
import styles from "../shops/HeaderShopToggle/components/RequestResult/RequestResult.module.scss";
import { CustomerGroupIcon, NoResultIcon } from "../../assets/icons";
import RequestResult from "../shops/HeaderShopToggle/components/RequestResult/RequestResult";

export default function CustomerSelectionPage() {
  const [showModal, setShowModal] = useState(false);
  const customers = useSelector(getCustomers);
  const filteredCustomers = useSelector(getFilteredCustomers);
  const hasCustomers = customers && customers.length > 0;
  const isSearchResultEmpty =
    filteredCustomers && filteredCustomers.length === 0;

  return (
    <div
      className={cn(classes.Container, { [classes.noIndents]: !hasCustomers })}
    >
      {hasCustomers && (
        <>
          <div className={classes.HeaderContainer}>
            <div className={classes.Title}>Customers</div>
            <CustomerSearch />
            <CustomerCounter className={classes.CounterContainer} />
            <AddCustomerButton onClick={() => setShowModal(!showModal)} />
          </div>

          {isSearchResultEmpty ? (
            <RequestResult
              wrapperClassName={styles.noSearchDataCustomersWrapper}
              className={styles.noSearchData}
              image={<NoResultIcon width={208} height={208} />}
              title="No results found"
              message={
                "We couldn't find anything matching your search.\nPlease try again."
              }
            />
          ) : (
            <CustomersTable customers={filteredCustomers} />
          )}
        </>
      )}
      {!hasCustomers && (
        <EmptyCustomers onClick={() => setShowModal(!showModal)} />
      )}
      <CustomerModal
        isOpen={showModal}
        toggle={() => setShowModal(!showModal)}
      />
    </div>
  );
}

function AddCustomerButton({ onClick, isEmptyCustomers }) {
  return (
    <button
      onClick={onClick}
      className={cn([classes.AddCustomer, classes.AddCustomer__button], {
        [classes.AddCustomer__buttonEmpty]: isEmptyCustomers,
      })}
    ></button>
  );
}

function EmptyCustomers({ onClick }) {
  return (
    <div className={classes.EmptyCustomers}>
      <CustomerGroupIcon />
      <div className={classes.Title}>It's a bit empty here...</div>
      <div className={classes.Message}>No customers have been added yet.</div>
      <AddCustomerButton onClick={onClick} isEmptyCustomers />
    </div>
  );
}
