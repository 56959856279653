import React, { forwardRef, useEffect } from "react";
import cn from "classnames";
import scroll from "react-scroll";

import styles from "./ScrollBar.module.scss";

const Scrollbar = forwardRef(
  (
    {
      children,
      height,
      id = "customScrollBarIdentification",
      className,
      containerClassName,
      resetScrollCount,
    },
    ref
  ) => {
    const { Element, scroller } = scroll;

    useEffect(() => {
      if (resetScrollCount) {
        scroller.scrollTo("scrollContent", {
          smooth: true,
          offset: -150,
          containerId: id,
        });
      }
    }, [resetScrollCount]);

    return (
      <div
        ref={ref}
        id={id}
        style={{ height }}
        className={cn(styles.scrollBar, containerClassName)}
      >
        <Element name="scrollContent" className={cn("w-full", className)}>
          {children}
        </Element>
      </div>
    );
  }
);

export default Scrollbar;
