import React, { useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Row } from "reactstrap";
import cn from "classnames";
import lodash from "lodash";

import {
  getSelectedCustomerVehicles,
  getSelectedCustomer,
} from "../../../customers/customers.selectors";
import {
  getFilteredVehicles,
  getVehicleState,
  getSelectedVehicleId,
  getVehicleFilter,
  isVehicleLoading,
} from "../../vehicles.selectors";
import { getSelectedShopId } from "../../../shops/shops.selectors";

import classes from "./CustomerVehiclesPanel.module.scss";
import styles from "../../../shops/HeaderShopToggle/components/RequestResult/RequestResult.module.scss";
import { setVehicleFilter, showModal } from "../../vehicles.slice";
import { CustomerVehicleCard } from "./CustomerVehicleCard";
import { VehicleModal } from "../vehicle-modal/VehicleModal";
import { ReactComponent as ArrowIcon } from "../../../../assets/icons/arrow-back.svg";
import { NoVehiclesIcon, NoResultIcon } from "../../../../assets/icons";
import RequestResult from "../../../shops/HeaderShopToggle/components/RequestResult/RequestResult";
import SearchField from "../../../shops/HeaderShopToggle/components/SearchField/SearchField";

export default function CustomerVehiclesPanel() {
  const vehicleState = useSelector(getVehicleState);
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedVehicleId = useSelector(getSelectedVehicleId);
  const selectedCustomer = useSelector(getSelectedCustomer);
  const customerVehicles = useSelector(getSelectedCustomerVehicles);
  const shopId = useSelector(getSelectedShopId);
  const vehiclesFilter = useSelector(getVehicleFilter);
  const refScrollData = useRef({ scrolled: 0 });
  const refScrollWrapper = useRef(null);

  useEffect(() => {
    if (customerVehicles?.length === 1) {
      history.push(
        `/${shopId}/customer/${selectedCustomer.id}/vehicle/${customerVehicles[0]}`
      );
    }
  }, [customerVehicles]);

  const handleToggle = () =>
    dispatch(
      showModal({ modal: "create", isOpen: !vehicleState.isShownCreateModal })
    );

  const handleVehiclesListDrag = (e) => {
    if (e.buttons === 1) {
      //left mouse button is being pressed
      refScrollData.current.scrolled += Math.abs(e.movementX);
      const scrollContainer = e.currentTarget.childNodes[0];
      scrollContainer.scrollLeft = scrollContainer.scrollLeft - e.movementX;
    }
  };

  const handleVehicleListClick = (e) => {
    if (refScrollData.current.scrolled > 10) {
      e.stopPropagation();
    }
    refScrollData.current.scrolled = 0;
  };

  const isVehicleListScrollable =
    refScrollWrapper?.current?.childNodes[0].clientWidth <
    refScrollWrapper?.current?.childNodes[0].scrollWidth;

  const isSearchHidden =
    !vehiclesFilter &&
    (selectedVehicleId
      ? !isVehicleListScrollable
      : customerVehicles?.length < 5);

  return (
    <>
      {selectedCustomer && customerVehicles.length ? (
        <>
          <CustomerVehicleCounter customerVehicles={customerVehicles} />
          <div
            className={cn("d-flex pt-3 align-items-center", {
              [classes.searchHider]: isSearchHidden,
            })}
          >
            <CustomerVehicleSearch />
            <AddVehicleButton onClick={handleToggle} />
            {selectedVehicleId && <ArrowControls />}
          </div>
          <div
            ref={refScrollWrapper}
            className={cn({
              [classes.vehiclesListHider]: customerVehicles.length === 1,
            })}
            onMouseMoveCapture={handleVehiclesListDrag}
            onClickCapture={handleVehicleListClick}
          >
            <CustomerVehiclesList
              onClick={handleToggle}
              selectedCustomer={selectedCustomer}
            />
          </div>
        </>
      ) : (
        <EmptyVehicles onClick={handleToggle} />
      )}
      <VehicleModal toggle={handleToggle} />
    </>
  );
}

function CustomerVehicleCounter({ customerVehicles }) {
  const numOfVehicles = customerVehicles ? customerVehicles.length : null;

  if (numOfVehicles === null) {
    return null;
  }

  return (
    <div className={classes.CustomerVehiclesCount}>
      Vehicles{" "}
      <span className={classes.CustomerVehiclesCount__number}>
        ({numOfVehicles})
      </span>
    </div>
  );
}

function CustomerVehicleSearch() {
  const dispatch = useDispatch();

  function onSearchInput(value) {
    dispatch(setVehicleFilter(value));
  }

  return (
    <SearchField
      className={cn([
        classes.CustomerVehicleSearch,
        classes.CustomerVehicleSearch__search,
      ])}
      placeholder="Search for vehicle by VIN or name..."
      onChange={onSearchInput}
    />
  );
}

function AddVehicleButton({ onClick, isEmptyVehicles }) {
  return (
    <button
      onClick={onClick}
      className={cn(classes.AddVehicle, classes.AddVehicle__button, {
        [classes.AddVehicle__buttonEmpty]: isEmptyVehicles,
      })}
    >
      Add Vehicle
    </button>
  );
}

function ArrowControls() {
  function sideScroll(direction, step) {
    const vehiclesContainer = document.getElementById("vehicleList");
    vehiclesContainer.scrollBy({
      left: direction === "left" ? -step : step,
      behavior: "smooth",
    });
  }

  return (
    <div className={classes.ArrowControls}>
      <ArrowIcon
        className={classes.ArrowButton}
        onClick={() => sideScroll("left", 300)}
      />
      <ArrowIcon
        className={cn([classes.RightArrow, classes.ArrowButton])}
        onClick={() => sideScroll("right", 300)}
      />
    </div>
  );
}

function CustomerVehiclesList({ selectedCustomer }) {
  const viewableVehicles = useSelector(getFilteredVehicles);
  const selectedVehicleId = useSelector(getSelectedVehicleId);

  if (!selectedCustomer || selectedCustomer.hasVehicleListError) {
    return null;
  }

  const sortedByAlphabet = lodash.orderBy(
    viewableVehicles,
    [(vehicle) => vehicle.name.toLowerCase()],
    ["asc"]
  );

  return (
    <Row
      className={cn([
        classes.ScrollContainer,
        `${selectedVehicleId ? "flex-nowrap" : "flex-wrap"} overflow-auto`,
        `${selectedVehicleId ? "" : classes.ScrollContainer__tiles}`,
      ])}
      id="vehicleList"
    >
      {selectedCustomer.isVehiclesListLoading ? (
        selectedCustomer.vehicles
          .map((id) => ({
            overview: { isLoading: true },
          }))
          .map((vehicle, i) => (
            <CustomerVehicleCard key={i} vehicle={vehicle} />
          ))
      ) : sortedByAlphabet.length ? (
        sortedByAlphabet.map((vehicle) => (
          <CustomerVehicleCard key={vehicle.id} vehicle={vehicle} />
        ))
      ) : (
        <RequestResult
          wrapperClassName={styles.noSearchDataVehiclesWrapper}
          className={cn(styles.noSearchData, "mt-0 mb-0")}
          image={<NoResultIcon width={208} height={208} />}
          title="No results found"
          message={
            "We couldn't find anything matching your search.\nPlease try again."
          }
        />
      )}
    </Row>
  );
}

function EmptyVehicles({ onClick }) {
  return (
    <div className={cn(classes.EmptyVehicles, "EmptyVehicles")}>
      <NoVehiclesIcon />
      <div className={classes.Title}>It's a bit empty here</div>
      <div className={classes.Message}>There is no data to display.</div>
      <AddVehicleButton onClick={onClick} isEmptyVehicles />
    </div>
  );
}
