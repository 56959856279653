import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import cn from "classnames";
import Chart from "react-apexcharts";
import { format } from "date-fns";

import {
  getSelectedVehicle,
  getSelectedVehicleId,
  getSelectedChartOptions,
  getChartLoading,
  getMultipleChartData,
  getSelectedChartDateRange,
  getFilteredChartOptions,
  isVehicleLoading as getIsVehicleLoading,
} from "../../vehicles.selectors";

import classes from "./PidGraphs.module.scss";
import {
  fetchDiagnosticValues,
  selectChartOption,
  selectChartDateRange,
  setChartLoading,
} from "../../vehicles.slice";
import DateRangePicker from "../../../../components/date-time-picker/DateRangePicker";
import SelectInput from "../../../../components/ui/input/SelectInput";
import { ReactComponent as AttentionIcon } from "../../../../assets/icons/attention.svg";
import { Alert } from "reactstrap";
import Spinner from "../../../../components/spinner/Spinner";
import { EmptyVehiclePerformanceIcon } from "../../../../assets/icons";

export default function PidGraphs({ className }) {
  const dispatch = useDispatch();
  const selectedVehicle = useSelector(getSelectedVehicle);
  const selectedVehicleId = useSelector(getSelectedVehicleId);
  const selectedOptions = useSelector(getSelectedChartOptions);
  const selectedDateRange = useSelector(getSelectedChartDateRange);
  const isChartLoading = useSelector(getChartLoading);
  const dataToShow = useSelector(getMultipleChartData);
  const optionsToShow = useSelector(getFilteredChartOptions);
  const isVehicleLoading = useSelector(getIsVehicleLoading);

  // Show this ranges in chart if 0 options are selected
  const xRanges = [
    {
      data: [
        {
          x: new Date(selectedDateRange.start),
        },
        {
          x: new Date(selectedDateRange.end),
        },
      ],
    },
  ];

  const formattedYaxis = dataToShow.map((option) => {
    if (option) {
      return {
        title: {
          text: `${option.name}, ${option.unit
            .replace(/Id$/, "")
            .replace(/^UnitOfMeasure/, "")}`,
        },
      };
    }
  });

  const fileExportName = selectedVehicle
    ? `${selectedVehicle.name}(${format(
        new Date(selectedDateRange.start),
        "MM-dd-yyyy"
      )})-(${format(new Date(selectedDateRange.end), "MM-dd-yyyy")})`
    : " ";

  useEffect(() => {
    if (selectedVehicle && selectedOptions) {
      selectedOptions.forEach((option) => {
        if (option) {
          dispatch(
            fetchDiagnosticValues({
              deviceId: selectedVehicle.deviceId,
              diagnosticId: option.value,
              from: new Date(selectedDateRange.start).toISOString(),
              to: new Date(selectedDateRange.end).toISOString(),
            })
          );
        }
      });
    }
  }, [selectedOptions, selectedDateRange, selectedVehicleId]);

  const handleSelect = (values) => {
    dispatch(selectChartOption(values));
  };

  const handleSelectDate = (range) => {
    dispatch(
      selectChartDateRange({
        start: String(range.start),
        end: String(range.end),
      })
    );
  };

  const options = {
    series: selectedOptions.length ? dataToShow : xRanges,
    noData: {
      text: "No data available",
      align: "center",
      verticalAlign: "middle",
      offsetY: -15,
      offsetX: 20,
      style: {
        color: "#49505A",
        fontSize: "24px",
        fontFamily: "Lato, sans-serif",
      },
    },
    yaxis: formattedYaxis,
    xaxis: {
      type: "datetime",
      tooltip: {
        enabled: false,
      },
      min: new Date(selectedDateRange.start).getTime(),
      max: new Date(selectedDateRange.end).getTime(),
      labels: {
        datetimeUTC: false,
        datetimeFormatter: {
          year: "yyyy",
          month: "MMM 'yy",
          day: "dd MMM",
          hour: "hh:mm TT",
        },
      },
    },
    stroke: {
      curve: "straight",
      width: 2,
    },
    markers: {
      size: 0,
    },
    tooltip: {
      enabled: true,
      marker: {
        show: false,
      },
      x: {
        format: "d MMM h:mm TT",
        show: true,
      },
      y: {
        formatter: (value) => `${value}`,
        title: {
          formatter: (value) => value,
        },
      },
    },
    chart: {
      defaultLocale: "en",
      animations: {
        enabled: false,
      },
      type: "line",
      toolbar: {
        tools: {
          pan: false,
          reset: false,
        },
        export: {
          csv: {
            filename: fileExportName,
            headerCategory: "Date",
            dateFormatter(timestamp) {
              return format(new Date(timestamp), "MM-dd-yyyy HH:mm:ss");
            },
          },
        },
      },

      events: {
        beforeZoom: function () {
          dispatch(setChartLoading(true));
        },
        zoomed: function (_, { xaxis }) {
          dispatch(
            selectChartDateRange({
              start: String(new Date(xaxis.min)),
              end: String(new Date(xaxis.max)),
            })
          );
        },
      },
    },
    legend: {
      onItemClick: {
        toggleDataSeries: false,
      },
    },
  };

  if (!selectedVehicle) {
    return null;
  }

  if (isVehicleLoading) {
    return (
      <div
        className={cn([
          classes.SpinnerWrapper,
          className,
          "VehicleInfoPanel__headerLabel position-relative",
        ])}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div className={className}>
      <div
        className={cn(
          classes.HeaderLabel,
          "VehicleInfoPanel__headerLabel w-50 text-left mb-3"
        )}
      >
        Vehicle Performance
      </div>

      {optionsToShow.length ? (
        <div>
          <div
            className={cn([
              classes.PidGraphs__ControllsWrapper,
              "mb-2",
              "mt-4",
            ])}
          >
            <div className={cn(classes.SelectWrapper, "w-50 mr-4")}>
              <SelectInput
                cls={classes.ActionSelect}
                value={selectedOptions}
                isMulti
                defaultValue={selectedOptions}
                options={optionsToShow}
                onInputChange={handleSelect}
              />
            </div>
            <div className={cn(classes.DatePickerWrapper, "w-50 ml-4")}>
              <DateRangePicker
                onChange={handleSelectDate}
                withTimePicker
                dateFormat="MMM d yyyy h:mm a"
                placeholder={"mm/dd/yy hh:mm - mm/dd/yy hh:mm"}
              />
            </div>
          </div>
          <div className={classes.PidGraphs__ChartWrapper}>
            {/* Uncomment if necessary */}
            {/* {data.length > MAX_VALUES && (
              <Alert id={alertClasses.WarningAlert}>
                <AttentionIcon /> The range you selected contains more than{" "}
                {MAX_VALUES} records. The latest values are shown. To see all
                data points, narrow down the time range.
              </Alert>
            )} */}
            <div className={isChartLoading ? "visible" : "invisible"}>
              <Spinner />
            </div>
            <Chart options={options} series={options.series} height={300} />
          </div>
        </div>
      ) : (
        <div className={classes.noData}>
          <EmptyVehiclePerformanceIcon className={classes.icon} />
          <div className="font-md font-bold">There is no data to display.</div>
        </div>
      )}
    </div>
  );
}
