/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import Select from "react-select";

import InputWrapper from "./InputWrapper";

const SelectInput = ({
  cls,
  defaultValue,
  value: valueProp,
  onInputChange,
  placeholder,
  disabled,
  isMulti,
  options,
  label,
  additionalLabel,
  withButton,
  buttonType,
  addButtonText,
  onInputButtonClick,
}) => {
  const [value, setValue] = useState(valueProp);

  useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  const onChange = (newValue) => {
    setValue(newValue);
    onInputChange && onInputChange(newValue);
  };

  let isMenuOpen = false;
  const customStyles = {
    option: (provided, state) => {
      return {
        ...provided,
        backgroundColor: state.isSelected && "#DBEEFE !important",
        fontSize: 14,
        color: "#49505A",
        fontFamily: "Lato",
        fontWeight: 600,
        paddingTop: 14,
        paddingLeft: 20,
        paddingRight: 20,
        minHeight: 45,
        cursor: "pointer",

        ":hover": {
          backgroundColor: "#EFF5FC",
        },
      };
    },
    control: (provided, { isDisabled, menuIsOpen }) => {
      isMenuOpen = menuIsOpen;
      return {
        ...provided,
        minHeight: 45,
        borderColor: menuIsOpen
          ? "#118AF9"
          : isDisabled
          ? "transparent"
          : "#EBEFF4",
        boxShadow: menuIsOpen && "0 0 0 1px #118AF9",
        borderRadius: 5,
        paddingLeft: 8,
        backgroundColor: "#FFFFFF",
        opacity: isDisabled ? 0.6 : 1,
        cursor: "pointer",

        ":hover": {
          borderColor: "#118AF9",
          boxShadow: "0 0 0 1px #118AF9",
        },
      };
    },
    dropdownIndicator: (provided) => {
      const transform = isMenuOpen ? "rotate(-180deg)" : "rotate(0deg)";
      const color = isMenuOpen ? "#118AF9" : "#A5AAC7";

      return {
        ...provided,
        color: "#A5AAC7",

        svg: {
          height: 16,
          width: 16,
          transform,
          color,
          transition: "all 0.3s",
        },

        ":hover": {
          color: "#A5AAC7",
        },
      };
    },
    placeholder: (provider) => ({
      ...provider,
      fontSize: 14,
      lineHeight: 36,
      color: "#95A1B2",
      fontFamily: "Lato",
      fontWeight: 500,
    }),
    indicatorSeparator: () => ({
      border: "none",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: 14,
      lineHeight: 36,
      color: "#49505A",
      fontFamily: "Lato",
      fontWeight: 700,
    }),
    menu: (provided) => ({
      ...provided,
      paddingTop: 10,
      paddingBottom: 10,
      borderRadius: 5,
      boxShadow: "2px 10px 50px #3F4B8021",
      zIndex: 200,
    }),
  };

  return (
    <InputWrapper
      cls={cls}
      label={label}
      disabled={disabled}
      additionalLabel={additionalLabel}
      withButton={withButton}
      buttonType={buttonType}
      addButtonText={addButtonText}
      onInputButtonClick={onInputButtonClick}
    >
      <Select
        value={value}
        onChange={onChange}
        isMulti={isMulti}
        isClearable={false}
        placeholder={placeholder}
        styles={customStyles}
        options={options}
        defaultValue={defaultValue}
        isDisabled={disabled}
      />
    </InputWrapper>
  );
};

export default SelectInput;
