import React, { forwardRef } from "react";
import cn from "classnames";

import { calculateGradientValueColor } from "./utils";
import classes from "./styles.module.scss";

export default forwardRef(function Track(
  { children, percentages, onTrackMouseDown, onFinalChange, noSlider },
  ref
) {
  const startGradientColor = noSlider
    ? calculateGradientValueColor(
        [
          { color: "#FF5151", percentage: 0 },
          { color: "#F8B131", percentage: 50 },
          { color: "#29C07B", percentage: 100 },
        ],
        percentages.min * 100
      )
    : calculateGradientValueColor(
        [
          { color: "#00C6FF", percentage: 0 },
          { color: "#0072FF", percentage: 100 },
        ],
        percentages.min * 100
      );

  const endGradientColor = noSlider
    ? calculateGradientValueColor(
        [
          { color: "#FF5151", percentage: 0 },
          { color: "#F8B131", percentage: 50 },
          { color: "#29C07B", percentage: 100 },
        ],
        percentages.max * 100
      )
    : calculateGradientValueColor(
        [
          { color: "#00C6FF", percentage: 0 },
          { color: "#0072FF", percentage: 100 },
        ],
        percentages.max * 100
      );

  function setActiveTrackGradient(percentages) {
    if (noSlider && percentages.min === 0 && percentages.max === 1) {
      return `#FF5151`;
    } else if (percentages.min <= 0.5 && percentages.max > 0.5) {
      // eslint-disable-next-line max-len
      return noSlider
        ? `linear-gradient(90deg, ${startGradientColor} 0%, #F8B131 50%, ${endGradientColor} 100%)`
        : `linear-gradient(90deg, ${startGradientColor} 0%, ${endGradientColor} 100%)`;
    }
    return `linear-gradient(90deg, ${startGradientColor}, ${endGradientColor})`;
  }

  function getActiveTrackStyle() {
    const width = `${(percentages.max - percentages.min) * 100}%`;
    const left = `${percentages.min * 100}%`;
    const activeTrackgGadient = setActiveTrackGradient(percentages);
    const borderRadius = `3px`;
    return { left, width, background: activeTrackgGadient, borderRadius };
  }

  function getClientRect() {
    return ref.current.getBoundingClientRect();
  }

  function onMouseDown(event) {
    if (noSlider) return;
    const clientX = event.touches ? event.touches[0].clientX : event.clientX;
    const trackClientRect = getClientRect();
    const position = {
      x: clientX - trackClientRect.left,
      y: 0,
    };

    onTrackMouseDown(event, position);
    onFinalChange();
  }

  function onTouchStart(event) {
    if (noSlider) return;
    event.preventDefault();

    onMouseDown(event);
  }

  const style = getActiveTrackStyle();
  return (
    <div
      ref={ref}
      className={cn(
        classes.Track,
        { [classes.FullTrack]: noSlider },
        "absolute",
        "mr-auto",
        "ml-auto",
        "border-radius-3"
      )}
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
    >
      <div style={style} className={cn(classes.ActiveTrack, "relative")} />
      {children}
    </div>
  );
});
