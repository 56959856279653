import React from "react";
import cn from "classnames";

import styles from "./Loader.module.scss";

const Loader = ({ className }) => {
  return (
    <div className={cn(className, "flex flex-col items-center")}>
      <div className={cn(styles.Loader, "relative")}>
        <span className={cn(styles.Loader__shape, styles.Loader__shape1)} />
        <span className={cn(styles.Loader__shape, styles.Loader__shape2)} />
        <span className={cn(styles.Loader__shape, styles.Loader__shape3)} />
        <span className={cn(styles.Loader__shape, styles.Loader__shape4)} />
      </div>
      <span className="font-semibold font-sm text-color-tertiary mt-12">
        Loading...
      </span>
    </div>
  );
};

export default Loader;
