import React, { Fragment } from "react";
import classNames from "classnames";

import danger from "./Danger.module.scss";
import primary from "./Primary.module.scss";
import light from "./Light.module.scss";
import dangerLight from "./DangerLight.module.scss";
import primaryLight from "./PrimaryLight.module.scss";
import dangerLarge from "./DangerLarge.module.scss";

/**
 * Component Button
 * @param {string} props danger, primary, light
 * @param {function} props (click event)
 * @param {boolean} props disabled
 * @param {object} props cls
 * @param {boolean} props isLoad
 * @param {node} props startIcon
 * @param {node} props endIcon
 * @param {boolean} props onlyIconOnMobile
 */

const Button = ({
  children,
  type,
  cls,
  clicked,
  disabled,
  isLoad,
  startIcon,
  endIcon,
  onlyIconOnMobile,
}) => {
  const CLASSES = {
    danger,
    primary,
    light,
    "danger--light": dangerLight,
    "primary--light": primaryLight,
    "danger--large": dangerLarge,
  };

  const classes = CLASSES[type];
  const button = (
    <Fragment>
      {startIcon && (
        <span className={classes["Button__start-icon"]}>{startIcon}</span>
      )}
      <span className={classes.Button__text}>{children}</span>
      {endIcon && (
        <span className={classes["Button__end-icon"]}>{endIcon}</span>
      )}
    </Fragment>
  );

  return (
    <button
      className={classNames(
        cls,
        classes.Button,
        classes[`Button__${type}`],
        {
          [classes["Button--isLoad"]]: isLoad,
        },
        { [classes["Button--onlyIcon"]]: onlyIconOnMobile }
      )}
      onClick={clicked}
      disabled={disabled}
    >
      {isLoad ? <div className={classes.Button__Loader}></div> : button}
    </button>
  );
};

export default Button;
