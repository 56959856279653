import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { usePopper } from "react-popper";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import cn from "classnames";
import styles from "./Popper.module.scss";
import { PROP_TYPES_TEMPLATES } from "../../commonPropTypes.js";

const Popper = ({
  isOpen,
  className,
  popperClassName,
  togglerClassName,
  toggler,
  children,
  placement = "bottom-end",
  modifiers,
  onOutsideClick = () => {},
  withBoxShadow = true,
}) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles: popperStyles, attributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement,
      modifiers,
    }
  );
  const togglerId = uuidv4();

  return (
    <div className={className}>
      <div
        className={togglerClassName}
        ref={setReferenceElement}
        id={togglerId}
      >
        {toggler}
      </div>
      {isOpen && (
        <>
          <OutsideClickHandler
            onOutsideClick={(event) => {
              const togglerNode = document.getElementById(togglerId);
              if (togglerNode && !togglerNode.contains(event.target)) {
                onOutsideClick();
              }
            }}
            disabled={!isOpen}
          >
            <div
              ref={setPopperElement}
              style={popperStyles.popper}
              className={cn(styles.popper, popperClassName)}
              {...attributes.popper}
            >
              <div
                className={cn({
                  [styles.boxShadow]: withBoxShadow,
                })}
              >
                {children}
              </div>
            </div>
          </OutsideClickHandler>
        </>
      )}
    </div>
  );
};

Popper.propTypes = {
  className: PROP_TYPES_TEMPLATES.CLASS_NAME,
  togglerClassName: PROP_TYPES_TEMPLATES.CLASS_NAME,
  toggler: PropTypes.element.isRequired,
  children: PROP_TYPES_TEMPLATES.CHILDREN.isRequired,
  placement: PropTypes.oneOf([
    "auto",
    "auto-start",
    "auto-end",
    "top",
    "top-start",
    "top-end",
    "bottom",
    "bottom-start",
    "bottom-end",
    "right",
    "right-start",
    "right-end",
    "left",
    "left-start",
    "left-end",
  ]),
  isOpen: PropTypes.bool,
  onOutsideClick: PropTypes.func,
};

export default Popper;
