import React from "react";
import cn from "classnames";
import classes from "./EngineIcon.module.scss";

export default function EngineIcon({ icon, className }) {
  return (
    <div
      className={cn([
        classes.EngineIcon__EngineWrapper,
        "d-flex",
        "justify-content-center",
        "align-items-center",
        className,
      ])}
    >
      <img className={classes.EngineImg} src={icon} alt="" />
    </div>
  );
}
