import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";

import styles from "./RequestResult.module.scss";
import { PROP_TYPES_TEMPLATES } from "../../../../../commonPropTypes";

const RequestResult = React.memo(
  ({ image, title, message, className, wrapperClassName, height }) => {
    return (
      <div
        className={cn(styles.noDataWrapper, wrapperClassName)}
        style={{ height }}
      >
        <div className={cn(className, styles.noData)}>
          {typeof image === "string" ? (
            <img src={image} alt="no-data" />
          ) : (
            image
          )}
          {title && <div className={styles.title}>{title}</div>}
          {message && <div className={styles.message}>{message}</div>}
        </div>
      </div>
    );
  }
);
RequestResult.propTypes = {
  image: PropTypes.object,
  title: PropTypes.string,
  message: PropTypes.string,
  className: PROP_TYPES_TEMPLATES.CLASS_NAME,
  wrapperClassName: PROP_TYPES_TEMPLATES.CLASS_NAME,
  height: PropTypes.string,
};
export default RequestResult;
