import React, { useMemo } from "react";
import { TABLE_COLUMN_ID } from "../../constants";
import Typography from "../typography/Typography";
import { useScreenSize } from "../../utility/hooks";

import CustomerCell from "../../scenes/customers/customer-table/customer-cell/CustomerCell";
import VehiclesCell from "../../scenes/customers/customer-table/vehicles-cell/VehiclesCell";
import ViewMoreBtn from "../../scenes/customers/customer-table/view-more-button/ViewMoreBtn";

export const useColumns = (isSelectableMobile) => {
  const { windowWidth, isMobile, isTablet } = useScreenSize();

  return useMemo(
    () => [
      {
        Header: "Customer",
        label: "Customer",
        id: TABLE_COLUMN_ID.CUSTOMER,
        accessor: "name",
        sticky: true,
        isVisible: true,
        flexGrow: 1,
        width: isTablet ? 180 : 250,
        disableSortBy: true,
        Cell: (row) => <CustomerCell row={row} />,
      },
      {
        Header: "Fleet Size",
        id: TABLE_COLUMN_ID.VEHICLES,
        accessor: "vehicles",
        label: "Fleet Size",
        width: 67,
        isVisible: !isMobile,
        disableSortBy: true,
        Cell: (row) => <VehiclesCell row={row} value={row.cell.value} />,
      },
      {
        Header: "# Connected",
        id: TABLE_COLUMN_ID.VEHICLES_CONNECTED,
        accessor: "vehiclesWithConnectedDevices",
        label: "Connected",
        width: isTablet ? 90 : 76,
        isVisible: !isMobile,
        disableSortBy: true,
        Cell: (row) => (
          <VehiclesCell row={row} value={row.cell.value} isConnectedCol />
        ),
      },
      {
        Header: "# Driving Now",
        id: TABLE_COLUMN_ID.VEHICLES_DRIVING,
        accessor: "vehiclesWithIgnitionOn",
        label: "Driving Now",
        width: isTablet ? 92 : 85,
        isVisible: !isMobile,
        disableSortBy: true,
        Cell: (row) => (
          <Typography fontSize="md">{row.cell.value || "-"}</Typography>
        ),
      },
      {
        Header: "# Inactive",
        id: TABLE_COLUMN_ID.VEHICLES_INACTIVE,
        accessor: "vehiclesInactive",
        label: "Inactive",
        width: isTablet ? 75 : 67,
        isVisible: !isMobile,
        disableSortBy: true,
        Cell: (row) => (
          <Typography fontSize="md">{row.cell.value || "-"}</Typography>
        ),
      },
      {
        Header: "# Engine Lights",
        id: TABLE_COLUMN_ID.VEHICLES_ENGINE_LIGHTS,
        accessor: "vehiclesWithCheckEngineLight",
        label: "Engine Lights",
        width: 85,
        isVisible: !isMobile,
        disableSortBy: true,
        Cell: (row) => <VehiclesCell row={row} value={row.cell.value} />,
      },
      {
        Header: "# Warnings",
        id: TABLE_COLUMN_ID.VEHICLES_WARNINGS,
        accessor: "vehiclesWithWarnings",
        label: "Warnings",
        width: isTablet ? 85 : 69,
        isVisible: !isMobile,
        disableSortBy: true,
        Cell: (row) => (
          <VehiclesCell row={row} value={row.cell.value} isWarningsCol />
        ),
      },
      {
        id: TABLE_COLUMN_ID.VIEW_MORE,
        accessor: "id",
        label: "View more",
        width: 95,
        isVisible: !isTablet && !isMobile,
        disableSortBy: true,
        alignment: "right",
        Cell: (row) => <ViewMoreBtn value={row.cell.value} />,
      },
    ],
    [isMobile, windowWidth, isSelectableMobile]
  );
};
