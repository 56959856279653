import React from "react";
import cn from "classnames";
import styles from "../VehiclesList/VehiclesList.module.scss";
import Typography from "../../../../../../components/typography/Typography";
import WarningItem from "../../../../../customer-details/components/warnings/WarningItem";

const VehiclesWarningList = ({ vehicles, onClick }) => {
  const getActiveWarnings = (warnings) => {
    return Object.entries(warnings)
      .map((warning) => {
        const [key, value] = warning;
        return { id: key, value };
      })
      .filter((warning) => warning.value.data);
  };

  return (
    <div className="p-20 h-full">
      <Typography
        color="primary"
        fontSize="xl"
        fontWeight="bold"
        className="flex items-center mb-20"
        tag="div"
      >
        Warning Lights
        <Typography
          color="quaternary"
          fontSize="xl"
          fontWeight="normal"
          className="ml-4"
        >
          ({vehicles.length})
        </Typography>
      </Typography>
      <ul className={styles.list}>
        {vehicles.map((vehicle) => {
          return (
            <li
              key={vehicle.id}
              className={cn(styles.item, "flex-col", styles.warningItem)}
              onClick={() => onClick(vehicle.id)}
            >
              <div className="flex-col justify-center">
                <Typography
                  color="secondary"
                  fontSize="md"
                  fontWeight="medium"
                  ellipsis
                >
                  {vehicle.name}
                </Typography>
                {getActiveWarnings(vehicle.warnings).map((warning) => {
                  return (
                    <div key={warning.id}>
                      <WarningItem
                        type={warning.id}
                        text={warning.value.text}
                        isPopupWarning
                      />
                    </div>
                  );
                })}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default VehiclesWarningList;
