import React from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import cn from "classnames";
import "../../../../custom-modal.css";
import classes from "../../../../styles/modal.module.scss";
import { CloseIcon, DeleteAlertIcon } from "../../../../assets/icons";

export default function DeleteModal({ isOpen, onClose, onClick, alertName }) {
  const handleClick = () => {
    document.body.classList.remove("modal-open");
    onClose();
    onClick();
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={onClose}
        contentClassName={cn(
          classes.Modal__Content,
          classes.Modal__Content__Confirm
        )}
        className={classes.Modal__Dialog}
        centered={true}
      >
        <div className={classes.Modal__Header}>
          <div className={classes.Modal__ConfirmTitle}>Delete Alert?</div>
          <CloseIcon className={classes.Modal__CloseIcon} onClick={onClose} />
        </div>
        <ModalBody className={classes.Modal__Body}>
          <div className={classes.Modal__Confirm}>
            <DeleteAlertIcon className={classes.Modal__DeleteAlertIcon} />
            <div className={classes.Modal__ConfirmSubtitle}>
              Are you sure you want to delete{" "}
              <span>{`${alertName} Alert?`}</span>
              <br />
              This cannot be undone.
            </div>
          </div>
        </ModalBody>
        <ModalFooter
          className={cn(classes.Modal__Footer, classes.Modal__Footer__Confirm)}
        >
          <button
            className={cn(
              classes.Modal__CancelBtn__Confirm,
              classes.Modal__CancelBtn
            )}
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className={cn(
              classes.Modal__SubmitBtn,
              classes.Modal__SubmitBtn__Confirm
            )}
            onClick={handleClick}
          >
            Yes, Delete
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
