import React from "react";
import { Input } from "reactstrap";
import classes from "./CounterInput.module.scss";
import cn from "classnames";
import { PlusIcon, MinusIcon } from "../../../assets/icons";

export default function CounterInput({
  onChange,
  incrementValue,
  decrementValue,
  adiitionalLabel,
  value,
  className,
  emptyIfZero,
}) {
  function handleChange() {
    return function (evt) {
      onChange(evt.target.value);
    };
  }

  return (
    <div className={classes.counterWrapper}>
      <div className={classes.inputContainer}>
        <Input
          className={cn(classes.input, className)}
          value={emptyIfZero ? value || "" : value}
          onChange={handleChange()}
        ></Input>
        <div className={classes.controls}>
          <div
            className={cn(classes.controlButton, classes.plus)}
            onClick={() => incrementValue()}
          >
            <PlusIcon />
          </div>
          <div
            className={cn(classes.controlButton, classes.minus)}
            onClick={() => decrementValue()}
          >
            <MinusIcon />
          </div>
        </div>
      </div>
      {adiitionalLabel && (
        <div className={classes.additionalLabel}>{adiitionalLabel}</div>
      )}
    </div>
  );
}
