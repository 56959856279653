import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { FormGroup, Label, Input, Alert } from "reactstrap";
import cn from "classnames";

import formClasses from "../../styles/input.module.scss";
import alertClasses from "../../styles/alert.module.scss";
import classes from "./Login.module.scss";

import { ReactComponent as AttentionIcon } from "../../assets/icons/attention.svg";
import { ReactComponent as PasswordEyeIcon } from "../../assets/icons/password-eye.svg";
import { ReactComponent as PasswordEyeClosedIcon } from "../../assets/icons/password-eye-closed.svg";

import { useDispatch, useSelector } from "react-redux";
import {
  getIsLoggedIn,
  loginUser,
  getIsLoading,
  getIsError,
  refreshUser,
} from "../../App/ui.slice";
import { getFromLocalStorage } from "../../utils";

const hour = 1000 * 60 * 60; // hour in ms;

export default function Login() {
  const { handleSubmit, control } = useForm();
  const history = useHistory();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(getIsLoggedIn);
  const isLoading = useSelector(getIsLoading);
  const isError = useSelector(getIsError);

  const [isUsernameFilled, setUsernameFilled] = useState(false);
  const [isPasswordFilled, setPasswordFilled] = useState(false);
  const [isPasswordHidden, setPasswordHidden] = useState(true);

  const [viewHeight, setViewHeight] = useState(window.innerHeight);

  const updateViewHeight = () => setViewHeight(window.innerHeight);

  useEffect(() => {
    window.addEventListener("resize", updateViewHeight);
    return () => window.removeEventListener("resize", updateViewHeight);
  });

  useEffect(() => {
    if (isLoggedIn) {
      history.push(`/`);

      setInterval(() => {
        dispatch(refreshUser(getFromLocalStorage("refreshToken")));
      }, hour * 3);
    }
  }, [isLoggedIn]);

  const onSubmit = async (data) => {
    if (isUsernameFilled && isPasswordFilled) {
      dispatch(loginUser(data));
    }
  };

  const onUsernameChange = (value) => setUsernameFilled(!!value.length);
  const onPasswordChange = (value) => setPasswordFilled(!!value.length);

  return (
    <div className={classes.Wrapper}>
      <div className={classes.FormSection} style={{ height: viewHeight }}>
        <div className={classes.TextMain}>Welcome to Steermatics!</div>
        <div className={classes.Text}>Log in to your account</div>
        <Alert isOpen={isError} id={alertClasses.WarningAlert}>
          <AttentionIcon /> Incorrect username or password! Please try again.
        </Alert>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label for="username" className={formClasses.InputLabel}>
              Username
            </Label>
            <Controller
              render={({ onChange }) => (
                <Input
                  onChange={(e) => {
                    onChange(e.target.value);
                    onUsernameChange(e.target.value);
                  }}
                  className={cn(formClasses.Input, classes.Input)}
                />
              )}
              control={control}
              type="input"
              name="username"
              id="username"
              defaultValue=""
            />
          </FormGroup>
          <FormGroup>
            <Label for="password" className={formClasses.InputLabel}>
              Password
            </Label>
            <Controller
              render={({ onChange }) => (
                <>
                  <Input
                    onChange={(e) => {
                      onChange(e.target.value);
                      onPasswordChange(e.target.value);
                    }}
                    className={cn(formClasses.Input, classes.Input)}
                    type={isPasswordHidden ? "password" : "text"}
                  />
                  {isPasswordHidden ? (
                    <PasswordEyeIcon
                      className={classes.PasswordEye}
                      onClick={() => setPasswordHidden((state) => !state)}
                    />
                  ) : (
                    <PasswordEyeClosedIcon
                      className={classes.PasswordEyeClosed}
                      onClick={() => setPasswordHidden((state) => !state)}
                    />
                  )}
                </>
              )}
              control={control}
              type="input"
              name="password"
              id="password"
              defaultValue=""
            />
          </FormGroup>
          <button
            className={cn(
              classes.SubmitButton,
              !isUsernameFilled || !isPasswordFilled
                ? classes.Inactive
                : classes.Active
            )}
            onClick={handleSubmit(onSubmit)}
          >
            {isLoading ? "Logging In..." : "Log In"}
          </button>
        </form>
        <div className={classes.TextSmall}>
          Mechanic Advisor © 2023. All rights reserved
        </div>
      </div>
    </div>
  );
}
