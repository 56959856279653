import React from "react";
import cn from "classnames";

import { ReactComponent as ArrowLeftIcon } from "../../assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRightIcon } from "../../assets/icons/arrow-right.svg";
import classes from "./MonthToggle.module.scss";

import { addMonths, subMonths } from "date-fns";

const MonthToggle = ({ onChange, focusedDate }) => {
  const prevMonth = () => {
    onChange(subMonths(focusedDate, 1));
  };

  const nextMonth = () => {
    onChange(addMonths(focusedDate, 1));
  };

  return (
    <div className={classes.Toggle}>
      <span
        className={cn(classes.Toggle__arrow, classes["Toggle__arrow--left"])}
        onClick={prevMonth}
      >
        <ArrowLeftIcon className={classes.Toggle__icon} />
      </span>
      <span
        className={cn(classes.Toggle__arrow, classes["Toggle__arrow--right"])}
        onClick={nextMonth}
      >
        <ArrowRightIcon className={classes.Toggle__icon} />
      </span>
    </div>
  );
};

export default MonthToggle;
