import React from "react";
import { useSelector } from "react-redux";
import cn from "classnames";

import { getCustomers } from "../customers.selectors";

import classes from "./CustomerCounter.module.scss";

export default function CustomerCounter({ className }) {
  const customers = useSelector(getCustomers);
  return (
    <div className={className}>
      Customers:{" "}
      <span
        className={cn([classes["CustomerCounter__totalValue"], "align-middle"])}
      >
        {customers.length}
      </span>
    </div>
  );
}
