import React, { Fragment } from "react";
import cn from "classnames";

import { ReactComponent as AddCircleIcon } from "../../../assets/icons/add-circle-blue.svg";
import { ReactComponent as TimesIcon } from "../../../assets/icons/times.svg";
import { ReactComponent as InformationIcon } from "../../../assets/icons/information.svg";
import classes from "./InputWrapper.module.scss";

const InputWrapper = ({
  cls,
  children: input,
  label,
  disabled,
  isError,
  additionalLabel,
  withButton,
  buttonType,
  addButtonText,
  withInformationBadge,
  onInputButtonClick,
  isCharacterCounter,
  maxCharactersCount = 50,
  charactersCount,
}) => {
  return (
    <div className={cn(classes.InputWrapper, cls)}>
      {label && (
        <div
          className={cn(classes.InputWrapper__controls, {
            [classes["InputWrapper__controls--disabled"]]: disabled,
          })}
        >
          <span className={classes["InputWrapper__label"]}>{label}</span>
          {additionalLabel && (
            <span className={classes["InputWrapper__additionalLabel"]}>
              ({additionalLabel})
            </span>
          )}
          {withButton && (
            <Fragment>
              {buttonType === "add" && (
                <span
                  className={cn(
                    classes["InputWrapper__button"],
                    classes["InputWrapper__button--add"]
                  )}
                  onClick={onInputButtonClick}
                >
                  <AddCircleIcon className={classes["InputWrapper__icon"]} />
                  {addButtonText}
                </span>
              )}
              {buttonType === "remove" && (
                <span
                  className={cn(
                    classes["InputWrapper__button"],
                    classes["InputWrapper__button--remove"]
                  )}
                  onClick={onInputButtonClick}
                >
                  <TimesIcon className={classes["InputWrapper__icon"]} />
                </span>
              )}
            </Fragment>
          )}
          {withInformationBadge && (
            <span className={classes.InputWrapper__information}>
              <InformationIcon />
            </span>
          )}
          {isCharacterCounter && (
            <span
              className={classes.InputWrapper__charactersCounter}
            >{`${charactersCount}/${maxCharactersCount}`}</span>
          )}
        </div>
      )}
      {input}
      {isError && (
        <span className={classes["InputWrapper__error"]}>
          You`ve entered incorrect value!
        </span>
      )}
    </div>
  );
};

export default InputWrapper;
