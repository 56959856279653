import React from "react";
import cn from "classnames";
import { format } from "date-fns";
import styles from "./VehiclesList.module.scss";
import VehicleImage from "../VehicleImage/VehicleImage";
import Typography from "../../../../../../components/typography/Typography";

const VehiclesList = ({ vehicles, isConnectedCol, onClick }) => {
  return (
    <div className={cn(styles.listWrapper, "p-20 h-full")}>
      <Typography
        color="primary"
        fontSize="xl"
        fontWeight="bold"
        className="flex items-center mb-20"
        tag="div"
      >
        Vehicles
        <Typography
          color="quaternary"
          fontSize="xl"
          fontWeight="normal"
          className="ml-4"
        >
          ({vehicles.length})
        </Typography>
      </Typography>
      <ul className={styles.list}>
        {vehicles.map((vehicle) => {
          return (
            <li
              key={vehicle.id}
              className={cn(styles.item, "flex")}
              onClick={() => onClick(vehicle.id)}
            >
              <VehicleImage vehicle={vehicle} width={42} height={42} />
              <div className="flex-col justify-center">
                <Typography
                  color="primary"
                  fontSize="md"
                  fontWeight="bold"
                  className={styles.vehicleName}
                  ellipsis
                >
                  {vehicle.name}
                </Typography>
                {isConnectedCol && (
                  <div className="flex items-end mt-1">
                    <Typography
                      color="quaternary"
                      fontSize="sm"
                      fontWeight="normal"
                      className="mr-4"
                    >
                      Last connected:
                    </Typography>
                    <Typography
                      color="secondary"
                      fontSize="md"
                      fontWeight="medium"
                    >
                      {format(
                        new Date(vehicle.lastTimeCommunicated),
                        "MMM dd, yyyy, hh:mm a"
                      )}
                    </Typography>
                  </div>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default VehiclesList;
