export const DAYS = ["su", "mo", "tu", "we", "th", "fr", "sa"];

export const ACTIONS = {
  THIS_WEEK: "This week",
  PREVIOUS_WEEK: "Previous week",
  THIS_MONTH: "This month",
  PREVIOUS_MONTH: "Previous month",
  LAST_3_MONTH: "Last 3 month",
  THIS_YEAR: "This year",
  PREVIOUS_YEAR: "Previous year",
  CUSTOM: "Custom"
};
