import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";

import Table from "../../../components/table-components/Table";
import { useColumns } from "../../../components/table-components/useColumns";

import styles from "./CustomersTable.module.scss";
import MobileRow from "../../../components/table-components/components/MobileRow/MobileRow";
import { useScreenSize } from "../../../utility/hooks";
import { ArrowDownIcon } from "../../../assets/icons";

const CustomersTable = ({ customers }) => {
  const columns = useColumns();
  const { isMobile } = useScreenSize();
  const setTableState = () => {};

  const ShowMoreComponent = ({ isExpanded }) => {
    return (
      <div className={cn(styles.showMore, { [styles.expanded]: isExpanded })}>
        <ArrowDownIcon width="24" height="24" className={cn(styles.arrow)} />
      </div>
    );
  };

  const columnsSetting = useMemo(
    () =>
      columns.map(({ id, label, isVisible, flexGrow }) => ({
        id,
        label,
        isVisible,
        flexGrow,
      })),
    [columns]
  );

  const setRowProps = useCallback(
    ({ classes }) => {
      return {
        className: cn(classes, styles.row),
      };
    },
    [customers]
  );

  return (
    <div>
      <Table
        togglerClassName={styles.toggler}
        headRowClassName={styles.headRow}
        isHideHeaderRow={isMobile}
        columns={columns}
        setRowProps={setRowProps}
        columnsSetting={columnsSetting}
        setTableState={setTableState}
        data={customers}
        CollapseRowToggleComponent={isMobile ? ShowMoreComponent : null}
        CollapseRowComponent={
          isMobile ? (row) => <MobileRow row={row} data={customers} /> : null
        }
        hasBoxShadow={false}
      />
    </div>
  );
};

export default CustomersTable;
