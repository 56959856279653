import format from "date-fns/format";

const IMPERIAL_METHODS = {
  DiagnosticEngineRoadSpeedId: {
    method: convertKilometersToMiles,
    unit: "UnitOfMeasureMilesPerHourId",
  },
  DiagnosticFuelUnitsId: {
    method: convertLitersToGallons,
    unit: "UnitOfMeasureGallonsId",
  },
  DiagnosticEngineCoolantTemperatureId: {
    method: convertCelsiusToFahrenheit,
    unit: "UnitOfMeasureDegreesFahrenheitId",
  },
  DiagnosticEngineTransmissionOilTemperatureId: {
    method: convertCelsiusToFahrenheit,
    unit: "UnitOfMeasureDegreesFahrenheitId",
  },
  DiagnosticEngineOilTemperatureID: {
    method: convertCelsiusToFahrenheit,
    unit: "UnitOfMeasureDegreesFahrenheitId",
  },
  anhSofTlT20ayntgV7IvW8A: {
    method: convertPascalsToInHg,
    unit: "UnitOfMeasureinHgId",
  },
  DiagnosticTirePressureRearLeftId: {
    method: convertPascalsToPsi,
    unit: "UnitOfMeasurePSIId",
  },
  DiagnosticTirePressureFrontLeftId: {
    method: convertPascalsToPsi,
    unit: "UnitOfMeasurePSIId",
  },
  DiagnosticTirePressureRearRightId: {
    method: convertPascalsToPsi,
    unit: "UnitOfMeasurePSIId",
  },
  DiagnosticTirePressureFrontRightId: {
    method: convertPascalsToPsi,
    unit: "UnitOfMeasurePSIId",
  },
  DiagnosticOutsideTemperatureId: {
    method: convertCelsiusToFahrenheit,
    unit: "UnitOfMeasureDegreesFahrenheitId",
  },
  speed: {
    method: convertKilometersToMiles,
    unit: "mph",
  },
  engineOilTemp: {
    method: convertCelsiusToFahrenheit,
    unit: "°F",
  },
  coolantTemp: {
    method: convertCelsiusToFahrenheit,
    unit: "°F",
  },
  tirePressureFrontLeft: {
    method: convertPascalsToPsi,
    unit: "PSI",
  },
  tirePressureFrontRight: {
    method: convertPascalsToPsi,
    unit: "PSI",
  },
  tirePressureRearLeft: {
    method: convertPascalsToPsi,
    unit: "PSI",
  },
  tirePressureRearRight: {
    method: convertPascalsToPsi,
    unit: "PSI",
  },
};

function formatUsDateTime(date) {
  return format(new Date(date), "MMM d, yyyy, hh:mm aa");
}

function convertMetersToMiles(meters) {
  if (!Number.isFinite(meters)) {
    return null;
  }

  return Math.round(meters * 0.0006213712);
}

function convertMilesToMeters(miles) {
  if (!Number.isFinite(miles)) {
    return null;
  }

  return Math.round(miles * 1609.344);
}

function convertKilometersToMeters(kilometers) {
  if (!Number.isFinite(kilometers)) {
    return null;
  }

  return Math.round(kilometers * 1000);
}

function convertKilometersToMiles(kilometers) {
  if (!Number.isFinite(kilometers)) {
    return null;
  }

  return Math.round(kilometers * 0.621371192);
}

function convertMetersToKilometers(meters) {
  if (!Number.isFinite(meters)) {
    return null;
  }

  return Math.round(meters * 0.001);
}

function convertLitersToGallons(liters) {
  if (!Number.isFinite(liters)) {
    return null;
  }

  return Math.round(liters * 0.264172);
}

function convertPascalsToPsi(pascals) {
  if (!Number.isFinite(pascals)) {
    return null;
  }

  return Math.round(pascals * 0.000145038);
}

function convertPascalsToBar(pascals) {
  if (!Number.isFinite(pascals)) {
    return null;
  }

  return Math.round(pascals * 0.00001 * 10) / 10;
}

function convertPascalsToInHg(pascals) {
  if (!Number.isFinite(pascals)) {
    return null;
  }
  return Math.round(pascals * 0.0002953);
}

function convertCelsiusToFahrenheit(celsius) {
  if (!Number.isFinite(celsius)) {
    return null;
  }
  return Math.round(celsius * 1.8 + 32);
}

function convertlitersPer100KmToMpg(litersPer100Km) {
  if (!Number.isFinite(litersPer100Km)) {
    return null;
  }

  return 235.215 / litersPer100Km;
}

function convertToImperialSystem(values = [], unit = "") {
  let key = "";
  if (values.length && values[0].diagnostic) key = values[0].diagnostic.id;

  if (IMPERIAL_METHODS.hasOwnProperty(key)) {
    const convertedUnit = IMPERIAL_METHODS[key].unit;
    const convertedValues = values.map((value) => {
      return {
        ...value,
        data: IMPERIAL_METHODS[key].method(value.data),
      };
    });
    return [convertedValues, convertedUnit];
  }
  return [values, unit];
}

function calculateGradientValueColor(gradientConfig, percentage) {
  for (let i = 0; i < gradientConfig.length - 1; i++) {
    const rangeStart = gradientConfig[i].percentage;
    const rangeStartColor = gradientConfig[i].color;
    const rangeEnd = gradientConfig[i + 1].percentage;
    const rangeEndColor = gradientConfig[i + 1].color;

    if (percentage >= rangeStart && percentage < rangeEnd) {
      return determineColorWithinGradient({
        colorStart: rangeStartColor,
        colorEnd: rangeEndColor,
        ratio: (percentage - rangeStart) / (rangeEnd - rangeStart),
      });
    } else if (percentage === rangeEnd) {
      return rangeEndColor;
    }
  }
}

function determineColorWithinGradient({ colorStart, colorEnd, ratio }) {
  const color1 = colorStart.replace("#", "");
  const color2 = colorEnd.replace("#", "");

  const hex = (color) => {
    const colorString = color.toString(16);
    return colorString.length === 1 ? `0${colorString}` : colorString;
  };

  const r = Math.ceil(
    parseInt(color2.substring(0, 2), 16) * ratio +
      parseInt(color1.substring(0, 2), 16) * (1 - ratio)
  );
  const g = Math.ceil(
    parseInt(color2.substring(2, 4), 16) * ratio +
      parseInt(color1.substring(2, 4), 16) * (1 - ratio)
  );
  const b = Math.ceil(
    parseInt(color2.substring(4, 6), 16) * ratio +
      parseInt(color1.substring(4, 6), 16) * (1 - ratio)
  );

  return "#" + hex(r) + hex(g) + hex(b);
}

function replaceSpaceToUnderscore(value) {
  return value.replaceAll(" ", "_");
}

function getFromSessionStorage(key) {
  try {
    return JSON.parse(sessionStorage.getItem(key));
  } catch (e) {
    console.error(e.message);
    return null;
  }
}

function setToSessionStorage(key, data) {
  try {
    sessionStorage.setItem(key, JSON.stringify(data));
  } catch (e) {
    console.error(e.message);
  }
}

function getFromLocalStorage(key) {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (e) {
    console.error(e.message);
    return null;
  }
}

function setToLocalStorage(key, data) {
  try {
    localStorage.setItem(key, JSON.stringify(data));
  } catch (e) {
    console.error(e.message);
  }
}

function removeFromLocalStorage(key) {
  try {
    return JSON.parse(localStorage.removeItem(key));
  } catch (e) {
    console.error(e.message);
    return null;
  }
}

const alertsConvertMethods = {
  doNotConvert: (value) => value,
  convertCelsiusToFahrenheit: (C) => Math.round((C * 1.8 + 32) * 10) / 10,
  convertFahrenheitToCelsius: (F) => (F - 32) / 1.8,
  convertMetersToMiles: (m) => Math.round(m * 0.0006213712 * 10) / 10,
  convertMilesToMeters: (mi) => mi * 1609.344,
  convertKPHToMPH: (kph) => Math.round(kph * 0.6213711922 * 10) / 10,
  convertMPHToKPH: (mph) => mph * 1.609344,
  convertPascalsToInHg: (Pa) => Math.round(Pa * 0.0002953 * 10) / 10,
  convertInHgToPascals: (inHg) => inHg / 0.0002953,
  convertPascalsToPSI: (Pa) => Math.round(Pa * 0.00014504 * 10) / 10,
  convertPSIToPascals: (psi) => psi / 0.00014504,
  convertLitersToGallons: (l) => Math.round((l * 0.26417 * 10) / 10),
  convertGallonsToLiters: (gal) => gal / 0.26417,
};

//get phone number in format +12345678901
const formatPhoneNumber = (phone) => {
  const onlyDigits = phone.replace(/[^\d]/g, "");
  return `+${onlyDigits}`;
};

const metricToImperialConvertMethods = {
  "°C": (C) => ({ value: Math.round((C * 1.8 + 32) * 10) / 10, unit: "°F" }),
  kPa: (kPa) => ({ value: Math.round((kPa / 6.895) * 10) / 10, unit: "PSI" }),
  Pa: (Pa) => ({ value: Math.round(Pa * 0.000145 * 10) / 10, unit: "PSI" }),
  "km/h": (kph) => ({
    value: Math.round(kph * 0.6213711922 * 10) / 10,
    unit: "mph",
  }),
  "grams/sec": (gs) => ({
    value: Math.round(gs * 0.0022046226 * 10) / 10,
    unit: "lb/sec",
  }),
  "g/s": (gs) => ({
    value: Math.round(gs * 0.0022046226 * 10) / 10,
    unit: "lb/s",
  }),
  km: (km) => ({
    value: Math.round(km * 0.621371 * 10) / 10,
    unit: "mi",
  }),
  "L/h": (lh) => ({
    value: Math.round(lh * 0.2199692 * 10) / 10,
    unit: "gal (lmp)/h",
  }),
  Nm: (nm) => ({
    value: Math.round(nm * 0.73756 * 10) / 10,
    unit: "ft-lb",
  }),
  "kg/h": (kgh) => ({
    value: Math.round(kgh * 2.20462262 * 10) / 10,
    unit: "lb/h",
  }),
  ppm: (ppm) => ({
    value: Math.round(ppm * 0.00001002241285 * 10) / 10,
    unit: "lb/gal",
  }),
  "mg/stroke": (mg) => ({
    value: Math.round(mg * 0.000035274 * 10) / 10,
    unit: "oz/stroke",
  }),
};

const joinShopAddress = (shop = {}, showCountry = true) => {
  const { address } = shop;
  return `${address?.streetAddress || address?.address1 || ""} ${
    address?.city || ""
  } ${address?.state || ""} ${address?.postCode || address?.zipCode || ""} ${
    showCountry && address?.country ? address?.country : ""
  }`.trim();
};

const generateHeightBasedOnViewport = ({
  itemsCount,
  elementClassName,
  rowHeight = 70,
  headerRowHeight = 70,
  includePaddingBottom = true,
  paddingBottomHeight = 40,
  isUseTotalTableHeight = false,
}) => {
  const bodyPaddings = 160;
  const [element] = document.getElementsByClassName(elementClassName);
  const tableDistanceToTop =
    window.pageYOffset + element?.getBoundingClientRect().top;
  const viewportHeight = window.innerHeight;
  const distanceToViewportBottom = viewportHeight - tableDistanceToTop;
  const fullTableHeight = viewportHeight - bodyPaddings;
  const totalRowsHeight =
    includePaddingBottom && isUseTotalTableHeight
      ? itemsCount * rowHeight + headerRowHeight + paddingBottomHeight
      : itemsCount * rowHeight + headerRowHeight;
  const isEnoughSpace = distanceToViewportBottom > totalRowsHeight;
  const isFewItems = viewportHeight > totalRowsHeight;
  const paddingBottom = includePaddingBottom ? paddingBottomHeight : 0;
  if (isEnoughSpace) {
    if (isFewItems) {
      // Enough space, few items
      return distanceToViewportBottom - paddingBottom;
    }
    // Enough space, many items
    return isUseTotalTableHeight ? totalRowsHeight : fullTableHeight;
  }

  if (isFewItems) {
    // Not enough space, few items
    return totalRowsHeight;
  }
  // Not enough space, many items
  return isUseTotalTableHeight ? totalRowsHeight : fullTableHeight;
};

export {
  formatUsDateTime,
  convertMetersToMiles,
  convertMetersToKilometers,
  convertMilesToMeters,
  convertKilometersToMeters,
  convertLitersToGallons,
  convertPascalsToPsi,
  convertPascalsToBar,
  convertCelsiusToFahrenheit,
  convertlitersPer100KmToMpg,
  calculateGradientValueColor,
  replaceSpaceToUnderscore,
  getFromSessionStorage,
  setToSessionStorage,
  convertToImperialSystem,
  alertsConvertMethods,
  formatPhoneNumber,
  metricToImperialConvertMethods,
  joinShopAddress,
  setToLocalStorage,
  removeFromLocalStorage,
  getFromLocalStorage,
  generateHeightBasedOnViewport,
};
