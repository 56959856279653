import React, { useCallback, useEffect, useState } from "react";
import cn from "classnames";
import PropTypes from "prop-types";
import { debounce } from "lodash-es";

import { SearcherIcon, CloseIcon } from "../../../../../assets/icons";
import { PROP_TYPES_TEMPLATES } from "../../../../../commonPropTypes";
import styles from "./SearchField.module.scss";

const SearchField = (props) => {
  const {
    placeholder,
    value = "",
    inputDelay,
    onChange,
    onClearClick,
    disabled,
    className,
    autofocus,
    onMouseDown,
  } = props;
  const [inputValue, setInputValue] = useState(value);
  const [isFirstRender, setFirstRender] = useState(true);

  const debouncedChangeInputSearch = useCallback(
    debounce((value) => onChange && onChange(value), inputDelay),
    []
  );

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    if (inputDelay && !isFirstRender) {
      debouncedChangeInputSearch(inputValue);
      return;
    }
    if (!isFirstRender) {
      onChange && onChange(inputValue);
    }
    setFirstRender(false);
  }, [inputValue, inputDelay]);

  function onValueChange(event) {
    const { value } = event.target;
    setInputValue(value);
  }

  function handleClearClick() {
    onChange && onChange("");
    setInputValue("");
    onClearClick && onClearClick();
  }

  return (
    <div
      className={cn(
        className,
        styles.SearchField,
        "flex items-center border-radius-rounded",
        {
          [styles.SearchField__disabled]: disabled,
        }
      )}
    >
      <div
        className={cn(
          styles.SearchField__icon,
          "flex items-center justify-center"
        )}
      >
        <SearcherIcon />
      </div>
      <input
        onMouseDown={onMouseDown}
        autoFocus={autofocus}
        type="text"
        className={cn(
          styles.SearchField__input,
          "font-bold font-md text-color-secondary",
          {
            [styles.SearchField__disabled]: disabled,
          }
        )}
        onChange={onValueChange}
        value={inputValue}
        placeholder={placeholder}
        disabled={disabled}
      />
      <div
        className={cn(
          styles.SearchField__icon,
          "flex items-center justify-center"
        )}
      >
        {inputValue ? (
          <span
            className={cn(
              styles.SearchField__clearButton,
              "flex items-center justify-center"
            )}
            onClick={handleClearClick}
          >
            <CloseIcon />
          </span>
        ) : null}
      </div>
    </div>
  );
};

SearchField.propTypes = {
  className: PROP_TYPES_TEMPLATES.CLASS_NAME,
  value: PROP_TYPES_TEMPLATES.INPUT_VALUE,
  placeholder: PROP_TYPES_TEMPLATES.INPUT_VALUE,
  autofocus: PropTypes.bool,
  disabled: PropTypes.bool,
  inputDelay: PropTypes.number,
  onChange: PropTypes.func,
  onClearClick: PropTypes.func,
};

export default SearchField;
