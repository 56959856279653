import React from "react";
import cn from "classnames";
import onClickOutside from "react-onclickoutside";
import { useSelector } from "react-redux";
import styles from "./UserMenu.module.scss";
import { useLocation } from "react-router-dom";

import {
  LogoutIcon,
  AdminIcon,
  DashboardIcon,
} from "../../../../../assets/icons";
import { getAdminStatus } from "../../../../../App/ui.slice";
import Typography from "../../../../../components/typography/Typography";

function UserMenu({
  onLogout,
  setDropdownOpen,
  onClickAdmin,
  onClickDashboard,
}) {
  const location = useLocation();
  const isAdmin = useSelector(getAdminStatus);
  const isAdminPage = location.pathname === "/admin";

  UserMenu.handleClickOutside = () => {
    setDropdownOpen(false);
  };

  return (
    <div
      className={cn(
        styles.userMenu,
        "absolute border-radius-7 box-shadow-dark pb-10 pt-10",
        { [styles.wider]: isAdminPage }
      )}
    >
      <div>
        {/* {isAdmin &&
          (isAdminPage ? (
            <div className={styles.dropdownItem} onClick={onClickDashboard}>
              <DashboardIcon className={styles.dropdownIcon} />
              <Typography fontSize="md" color="secondary" fontWeight="semibold">
                Go to Dashboard
              </Typography>
            </div>
          ) : (
            <div className={styles.dropdownItem} onClick={onClickAdmin}>
              <AdminIcon className={styles.dropdownIcon} />
              <Typography fontSize="md" color="secondary" fontWeight="semibold">
                Admin Page
              </Typography>
            </div>
          ))} */}
        <div className={styles.dropdownItem} onClick={onLogout}>
          <LogoutIcon className={styles.dropdownIcon} />
          <Typography fontSize="md" color="secondary" fontWeight="semibold">
            Log Out
          </Typography>
        </div>
      </div>
    </div>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => UserMenu.handleClickOutside,
};

export default onClickOutside(UserMenu, clickOutsideConfig);
