import React from "react";
import cn from "classnames";

import DayCell from "./DayCell";
import { DAYS } from "./constants";
import { getMonthDisplayRange } from "./utils";
import classes from "./Month.module.scss";

import {
  eachDayOfInterval,
  format,
  isWithinInterval,
  isSameDay,
  subDays,
  isAfter,
  isBefore,
} from "date-fns";

const Month = ({
  month,
  pickedDate,
  onDayClick,
  onSelecteMove,
  dateRange = {},
  clsMonth,
}) => {
  const markInRange = (date) => {
    const { start, end } = dateRange;
    if (start && end) {
      return {
        isStartEdge: isSameDay(date, start),
        isEndEdge: isSameDay(date, end),
        isInRange: isAfter(date, start) && isBefore(date, subDays(end, 1)),
      };
    }
    return {};
  };

  const daysOfMonth = getMonthDisplayRange(month);
  const now = new Date();
  return (
    <div className={cn(classes.Month, clsMonth)}>
      <div className={classes.Month__name}>{format(month, "MMM yyyy")}</div>
      <div className={classes.Month__weekDays}>
        {DAYS.map((day, index) => (
          <span
            key={index}
            className={cn(classes.Month__dayName, {
              [classes["Month__dayName--weekend"]]: index === 0 || index === 6,
            })}
          >
            {day}
          </span>
        ))}
      </div>
      <div className={classes.Month__wrapper}>
        {eachDayOfInterval({
          start: daysOfMonth.start,
          end: daysOfMonth.end,
        }).map((date) => {
          const isCurrentMonth = isWithinInterval(date, {
            start: daysOfMonth.startDateOfMonth,
            end: daysOfMonth.endDateOfMonth,
          });
          const { isStartEdge, isEndEdge, isInRange } = markInRange(date);
          return (
            <DayCell
              key={date}
              date={date}
              isToday={isSameDay(date, now)}
              isCurrentMonth={isCurrentMonth}
              isStartEdge={isStartEdge && isCurrentMonth}
              isEndEdge={isEndEdge && isCurrentMonth}
              isInRange={isInRange && isCurrentMonth}
              pickedDate={pickedDate}
              onDayClick={onDayClick}
              onMouseEnter={onSelecteMove}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Month;
