import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import cn from "classnames";

import { fetchVehicleMonitors } from "../../vehicles.slice";
import {
  getSelectedVehicle,
  getSelectedVehicleMonitors,
  getIsMonitorsDataLoading,
} from "../../vehicles.selectors";
import Spinner from "../../../../components/spinner/Spinner";

import classes from "./ReadinessMonitors.module.scss";
import { EmptyReadinessMonitorsIcon } from "../../../../assets/icons";

export default function ReadinessMonitors({ className }) {
  const dispatch = useDispatch();

  const selectedVehicle = useSelector(getSelectedVehicle);
  const vehicleId =
    selectedVehicle && selectedVehicle.id && selectedVehicle.deviceId;

  const monitors = useSelector(getSelectedVehicleMonitors);
  const isMonitorsDataLoading = useSelector(getIsMonitorsDataLoading);
  const { completedCount, notCompletedCount } =
    calculateCompletedTotals(monitors);

  useEffect(() => {
    if (!selectedVehicle) {
      return;
    }

    dispatch(fetchVehicleMonitors(selectedVehicle));
  }, [vehicleId]);

  if (!selectedVehicle) {
    return null;
  }

  if (isMonitorsDataLoading) {
    return (
      <div
        className={cn([classes.SpinnerWrapper, className, "position-relative"])}
      >
        <Spinner />
      </div>
    );
  }

  if (!monitors.length) {
    return (
      <div
        className={cn(
          classes.ReadinessMonitors,
          classes["ReadinessMonitors__no-data"],
          className
        )}
      >
        <div className="VehicleInfoPanel__headerLabel">Readiness Monitors</div>
        <div className={classes.Container}>
          <EmptyReadinessMonitorsIcon className={classes.icon} />
          <div className="font-md font-bold">There is no data to display.</div>
        </div>
      </div>
    );
  }

  return (
    <div className={cn(classes.ReadinessMonitors, className)}>
      <div className="VehicleInfoPanel__headerLabel">
        Readiness Monitors{" "}
        <span className="VehicleInfoPanel__headerLabelCounter">
          ({monitors.length})
        </span>
      </div>
      <div className={cn(classes.summaryMobileWrapper, "d-flex mt-4")}>
        <div
          className={cn(
            classes.ReadinessMonitors__summary,
            classes["ReadinessMonitors__summary--completed"],
            "w-50",
            "mr-3"
          )}
        >
          <div
            className={cn(
              classes.ReadinessMonitors__summaryLabel,
              "d-xs-block"
            )}
          >
            Completed
          </div>
          <div
            className={cn(
              classes.ReadinessMonitors__summaryCounter,
              "mt-3",
              "mt-md-0"
            )}
          >
            {completedCount}
          </div>
        </div>
        <div
          className={cn(
            classes.ReadinessMonitors__summary,
            classes["ReadinessMonitors__summary--notCompleted"],
            "w-50"
          )}
        >
          <div
            className={cn(
              classes.ReadinessMonitors__summaryLabel,
              "d-xs-block"
            )}
          >
            Not completed
          </div>
          <div
            className={cn(
              classes.ReadinessMonitors__summaryCounter,
              "mt-3",
              "mt-md-0"
            )}
          >
            {notCompletedCount}
          </div>
        </div>
      </div>

      <div className="d-flex mt-md-4 mt-4 align-items-center">
        <div className={classes.ReadinessMonitors__tableHeader}>Monitor</div>
        <div
          className={cn(
            classes.ReadinessMonitors__tableHeader,
            classes["ReadinessMonitors__tableHeader--small"]
          )}
        >
          Available
        </div>
        <div
          className={cn(
            classes.ReadinessMonitors__tableHeader,
            classes["ReadinessMonitors__tableHeader--small"]
          )}
        >
          Completed
        </div>
      </div>
      <div className={classes.ReadinessMonitors__rowsContainer}>
        {monitors.map(({ name, isAvailable, isCompleted }) => (
          <MonitorRow
            label={name}
            available={isAvailable}
            completed={isCompleted}
            key={name}
          />
        ))}
      </div>
    </div>
  );
}

function calculateCompletedTotals(monitors) {
  let completedCount = 0;
  let notCompletedCount = 0;

  if (monitors && monitors.length) {
    completedCount = monitors.filter((monitor) => monitor.isCompleted).length;
    notCompletedCount = monitors.length - completedCount;
  }

  return { completedCount, notCompletedCount };
}

function MonitorRow({ label, available, completed }) {
  return (
    <div
      className={cn(
        classes.ReadinessMonitors__monitorRow,
        "d-flex",
        "py-3",
        "items-center"
      )}
    >
      <div
        className={cn(
          classes.ReadinessMonitors__monitorLabel,
          "text-capitalize"
        )}
      >
        {label}
        <span className={classes.MobileHidden}> monitor</span>
      </div>
      <div
        className={cn(
          classes.ReadinessMonitors__monitorIcon,
          available
            ? classes["ReadinessMonitors__monitorIcon--completed"]
            : classes["ReadinessMonitors__monitorIcon--notCompleted"],
          "flex-shrink-0"
        )}
      />
      <div
        className={cn(
          classes.ReadinessMonitors__monitorIcon,
          completed
            ? classes["ReadinessMonitors__monitorIcon--completed"]
            : classes["ReadinessMonitors__monitorIcon--notCompleted"],
          "flex-shrink-0"
        )}
      />
    </div>
  );
}
