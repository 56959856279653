import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styles from "./Header.module.scss";

import { selectShop } from "../shops/shops.slice";
import { setShopIdHeader } from "../../services/api.service";
import {
  selectTenant,
  setTenantsShown,
  setIsFirstTenantsRender,
} from "../tenants/tenants.slice";
import { setCustomerFilter } from "../customers/customers.slice";
import { getCustomerFilter } from "../customers/customers.selectors";

import HeaderShopToggle from "../shops/HeaderShopToggle";
import UserDropdown from "./userDropdown/UserDropdown";
import { SteerLogoIcon, SteerLogoMobIcon } from "../../assets/icons";

export default function Header() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const isAdminPage = location.pathname === "/admin";
  const customerFilterValue = useSelector(getCustomerFilter);

  function handleShopSelect(shop) {
    dispatch(selectShop(shop));
    setShopIdHeader(shop.id);
    history.push(`/${shop.id}/`);
    customerFilterValue && dispatch(setCustomerFilter(""));
  }

  function handleTenantSelect(tenant) {
    dispatch(selectTenant(tenant));
    dispatch(setTenantsShown(false));
    dispatch(setIsFirstTenantsRender(false));
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.logos}>
        <SteerLogoIcon className={styles.logo} />
        <SteerLogoMobIcon className={styles.logoMobile} />
        <div className={styles.divider}></div>
        <div className={styles.logoText}>Steermatics</div>
      </div>
      {!isAdminPage && (
        <HeaderShopToggle
          isBackgroundHover
          onShopClick={(shop) => handleShopSelect(shop)}
          onTenantClick={(tenant) => handleTenantSelect(tenant)}
        />
      )}
      <UserDropdown />
    </div>
  );
}
