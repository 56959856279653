import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";

import {
  ShopLogoGreyIcon,
  ShopLogoBlueIcon,
  ChooseAllShopLocationActiveIcon,
  ChooseAllShopLocationIcon,
} from "../../../../../assets/icons/index";
import styles from "./CompanyAvatar.module.scss";
import { PROP_TYPES_TEMPLATES } from "../../../../../commonPropTypes";

const CompanyAvatar = ({
  avatar,
  width = 80,
  height = 80,
  isLogoActive,
  className,
  alt = "Company avatar",
  defaultLogoSizePercent = 0.6,
  isAllLocation = false,
  hasBorder = false,
}) => {
  const ActiveDefaultIcon = isAllLocation
    ? ChooseAllShopLocationActiveIcon
    : ShopLogoBlueIcon;
  const DefaultIcon = isAllLocation
    ? ChooseAllShopLocationIcon
    : ShopLogoGreyIcon;

  return (
    <div className={cn(className)}>
      {avatar ? (
        <div
          className={cn(styles.imageContainer, {
            [styles.hasBorder]: hasBorder,
          })}
        >
          <img
            src={avatar}
            alt={alt}
            width={width}
            height={height}
            className={styles.avatarImg}
          />
        </div>
      ) : (
        <div
          className={cn(styles.profileIcon, {
            [styles.hasBorder]: hasBorder,
          })}
          style={{ width, height }}
        >
          {!isLogoActive && (
            <DefaultIcon
              width={width * defaultLogoSizePercent}
              height={height * defaultLogoSizePercent}
            />
          )}
          {isLogoActive && (
            <ActiveDefaultIcon
              width={width * defaultLogoSizePercent}
              height={height * defaultLogoSizePercent}
            />
          )}
        </div>
      )}
    </div>
  );
};

CompanyAvatar.propTypes = {
  avatar: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  isLogoActive: PropTypes.bool,
  className: PROP_TYPES_TEMPLATES.CLASS_NAME,
};

export default CompanyAvatar;
