import React from "react";
import cn from "classnames";

import { TOOLTIP_CLOSING_TIME } from "../../constants";
import { AttentionIcon } from "../../assets/icons";
import Tooltip from "../tooltip/Tooltip";
import styles from "./InputError.scss";

const InputError = ({
  isOpen,
  isDefaultTooltipShowing,
  errorMessage,
  className,
  tooltipAlign,
  direction,
}) => (
  <div className={cn(className, styles.inputError)}>
    {isOpen && (
      <Tooltip
        direction={direction}
        align={tooltipAlign}
        isOpen={!isDefaultTooltipShowing && !!isOpen}
        closingTime={TOOLTIP_CLOSING_TIME}
        content={<span>{errorMessage}</span>}
      >
        <AttentionIcon />
      </Tooltip>
    )}
  </div>
);

export default InputError;
