import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";

import { PROP_TYPES_TEMPLATES } from "../../commonPropTypes";
import styles from "./Typography.module.scss";

const Typography = ({
  parentClassName,
  className,
  color = "primary",
  fontSize = "md",
  fontWeight = "normal",
  children,
  tag = "div",
  onClick = () => {},
  ellipsis,
  maxWidth,
}) => {
  const Component = tag;
  const ParentTag = tag;

  return (
    <ParentTag className={cn(styles[`${fontSize}--margin`], parentClassName)}>
      <Component
        style={{ maxWidth }}
        onClick={onClick}
        className={cn(
          className,
          styles[color],
          styles[fontSize],
          styles[fontWeight],
          {
            [styles.ellipsis]: ellipsis,
          }
        )}
      >
        {children}
      </Component>
    </ParentTag>
  );
};

Typography.defaultProps = {
  children: "",
};

Typography.propTypes = {
  className: PROP_TYPES_TEMPLATES.CLASS_NAME,
  parentClassName: PROP_TYPES_TEMPLATES.CLASS_NAME,
  children: PROP_TYPES_TEMPLATES.CHILDREN,
  tag: PropTypes.string,
  fontWeight: PropTypes.oneOf(["normal", "medium", "semibold", "bold"]),
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "tertiary",
    "quaternary",
    "white",
    "warning",
    "error",
    "link",
  ]),
  fontSize: PropTypes.oneOf([
    "xs",
    "sm",
    "md",
    "lg",
    "xl",
    "xl-2",
    "xl-3",
    "xl-4",
    "xl-5",
    "xl-6",
    "xl-7",
  ]),
  onClick: PropTypes.func,
};

export default Typography;
