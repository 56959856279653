import React from "react";
import cn from "classnames";

import classes from "./DayCell.module.scss";

import { format, isSameDay } from "date-fns";

const DayCell = ({
  date,
  onDayClick,
  onMouseEnter = () => {},
  isCurrentMonth,
  isToday,
  pickedDate,
  isStartEdge,
  isEndEdge,
  isInRange,
}) => (
  <span
    className={cn(classes.Day, {
      [classes["Day--isCurrentMonth"]]: isCurrentMonth,
      [classes["Day--isInRange"]]: isInRange,
      [classes["Day--isStartEdge"]]: isStartEdge,
      [classes["Day--isEndEdge"]]: isEndEdge,
      [classes["Day--isToday"]]: isToday,
      [classes["Day--isSelected"]]: isSameDay(date, pickedDate),
    })}
    onMouseEnter={() => onMouseEnter(date)}
    onClick={() => onDayClick(date)}
  >
    <span>{format(date, "d")}</span>
  </span>
);

export default DayCell;
