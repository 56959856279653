import {
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  differenceInCalendarDays,
  addDays,
  format
} from "date-fns";

export const getMonthDisplayRange = date => {
  const startDateOfMonth = startOfMonth(date);
  const endDateOfMonth = endOfMonth(date);
  const startDateOfCalendar = startOfWeek(startDateOfMonth);
  let endDateOfCalendar = endOfWeek(endDateOfMonth);
  if (differenceInCalendarDays(endDateOfCalendar, startDateOfCalendar) <= 34) {
    endDateOfCalendar = addDays(endDateOfCalendar, 7);
  }
  return {
    start: startDateOfCalendar,
    end: endDateOfCalendar,
    startDateOfMonth,
    endDateOfMonth
  };
};

export const getFormattedDate = date => {
  if (date) {
    return {
      timeOfDay: format(date, "a"),
      hours: format(date, "h"),
      minutes: format(date, "m")
    };
  }
  return {
    timeOfDay: "AM",
    hours: 0,
    minutes: 0
  };
};
