import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";
import styles from "./UserDropdown.module.scss";

import { useHistory } from "react-router-dom";
import { useScreenSize } from "../../../utility/hooks";
import { logoutUser } from "../../../App/ui.slice";
import { getSelectedShopId } from "../../shops/shops.selectors";

import { ArrowDownIcon } from "../../../assets/icons";
import UserAvatar from "./components/UserAvatar/UserAvatar";
import UserMenu from "./components/UserMenu/UserMenu";

const UserDropdown = ({ className }) => {
  const toggleRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const { isMobile } = useScreenSize();
  const selectedShopId = useSelector(getSelectedShopId);

  function handleLogout() {
    dispatch(logoutUser());
  }

  function goToAdminPage() {
    history.push(`/admin`);
    setDropdownOpen(!isDropdownOpen);
  }

  function goToCustomersDashboard() {
    history.push(`/${selectedShopId}/`);
    setDropdownOpen(!isDropdownOpen);
  }

  return (
    <div
      ref={(elm) => {
        toggleRef.current = elm;
      }}
      className={cn(
        styles.dropdownWrapper,
        styles.isBackgroundHover,
        "relative flex items-center",
        className,
        {
          [styles.isActive]: isDropdownOpen,
        }
      )}
    >
      <div
        className={cn(
          styles.button,
          "flex justify-center items-center shops-dropdown-toggle pointer"
        )}
        onClick={() => {
          setDropdownOpen(!isDropdownOpen);
        }}
      >
        <div
          className={cn(
            "flex items-center justify-center border-radius-circle"
          )}
        >
          <UserAvatar
            className={cn(
              styles.userLogo,
              "flex items-center justify-center border-radius-circle"
            )}
            isLogoActive={isDropdownOpen}
            width={isMobile ? 32 : 40}
            height={isMobile ? 32 : 40}
          />
        </div>

        <ArrowDownIcon
          className={cn(styles.arrow, {
            [styles.isRotated]: isDropdownOpen,
          })}
        />
      </div>

      {isDropdownOpen && (
        <UserMenu
          onLogout={handleLogout}
          setDropdownOpen={setDropdownOpen}
          onClickAdmin={goToAdminPage}
          onClickDashboard={goToCustomersDashboard}
          outsideClickIgnoreClass="shops-dropdown-toggle"
        />
      )}
    </div>
  );
};

export default UserDropdown;
