import { createSelector } from "@reduxjs/toolkit";

export const isUsersListLoaded = (state) => state.users.byId;

export const getUsers = (state) =>
  state.users.byId && Object.values(state.users.byId);

export const getSelectedUser = (state) =>
  state.users.byId && state.users.byId[state.users.selectedUser];

export const getUserFilter = (state) => state.users.userFilter;

export const getFilteredUsers = createSelector(
  [getUsers, getUserFilter],
  (users, filterValue) => {
    return filterValue
      ? users.filter(
          ({ username }) =>
            username.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1
        )
      : users;
  }
);

export const isUserLoading = (state) => state.users.isLoading;

export const getUserError = (state) => state.users.error;

export const getEditUserModalShown = (state) => state.users.isShownEditModal;

export const getCreateUserModalShown = (state) =>
  state.users.isShownCreateModal;
