import React from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { CloseIcon } from "../../../../assets/icons";
import classes from "../../../../styles/modal.module.scss";
import "../../../../custom-modal.css";
import { RemoveVehicleIcon } from "../../../../assets/icons";
import cn from "classnames";

export function RemoveVehicleModal({
  isOpen,
  toggle,
  isLastVehicle,
  onClick,
  vehicleName,
}) {
  const onToggle = () => {
    toggle();
  };

  const handleDeleteVehicle = () => {
    onClick();
    toggle();
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={onToggle}
        contentClassName={cn(
          classes.Modal__Content,
          classes.Modal__Content__Confirm
        )}
        className={classes.Modal__Dialog}
        centered={true}
      >
        <div className={classes.Modal__Header}>
          <div className={classes.Modal__ConfirmTitle}>Disconnect Vehicle?</div>
          <CloseIcon className={classes.Modal__CloseIcon} onClick={onToggle} />
        </div>
        <ModalBody className={classes.Modal__Body}>
          <div className={classes.Modal__Confirm}>
            <RemoveVehicleIcon className={classes.Modal__RemoveVehicleIcon} />
            {isLastVehicle ? (
              <div className={classes.Modal__ConfirmSubtitle}>
                You are trying to remove your last vehicle{" "}
                <span>{vehicleName}</span>.
                <br />
                If you have a Mojio Force subscription, it will be cancelled.
                Are you sure you want to disconnect the vehicle?
              </div>
            ) : (
              <div className={classes.Modal__ConfirmSubtitle}>
                Are you sure you want to disconnect <span>{vehicleName}</span>?
                <br />
                This cannot be undone.
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter
          className={cn(classes.Modal__Footer, classes.Modal__Footer__Confirm)}
        >
          <button
            className={cn(
              classes.Modal__CancelBtn__Confirm,
              classes.Modal__CancelBtn
            )}
            onClick={onToggle}
          >
            Cancel
          </button>
          <button
            className={cn(
              classes.Modal__SubmitBtn,
              classes.Modal__SubmitBtn__Confirm
            )}
            onClick={handleDeleteVehicle}
          >
            Yes, Disconnect
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
