export const customizeColumnStyle = (column, options = {}) => {
  const styles = {};

  if (Object.keys(options).length !== 0) {
    if (options.noFlexGrow || options.isAlignLeft) {
      styles.flex = `0 0 ${column.width}px`;
      styles.maxWidth = column.width;
    }

    if (options.isColumnSticky) {
      styles.position = "sticky";
      styles.zIndex = 1;
      styles.left = options.totalLeftPosition;
      styles.backgroundColor = "inherit";
    }

    if (options.isAlignLeft && !options.isColumnSticky) {
      styles.marginLeft = "100px";
    }

    return styles;
  }

  return column.style;
};
