import React from "react";

import classes from "./Spinner.module.scss";

export default function Spinner() {
  return (
    <div className={classes.SpinnerWrapper}>
      <div className={classes.LoaderWrapper}>
        <div className={classes.Square}></div>
        <div className={[classes.Square, classes.Square2].join(" ")}></div>
        <div className={[classes.Square, classes.Square3].join(" ")}></div>
        <div className={[classes.Square, classes.Square4].join(" ")}></div>
      </div>
      <div className={classes.Text}>Loading...</div>
    </div>
  );
}
