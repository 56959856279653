import React, { useRef, useState, useEffect } from "react";
import cn from "classnames";

import { formatUsDateTime } from "../../../../utils";

import classes from "./DtcCodes.module.scss";

import { ReactComponent as InfoIcon } from "../../../../assets/icons/info-grey.svg";
import { xor } from "lodash";

export default function DtcCodeRow({
  diagnosticId,
  time,
  faultState,
  isFreezeFrameAvailable,
  freezeFrame,
  description,
  code,
  controller,
  count,
  engineType,
  source,
  isActive,
  isPending,
  isExpandable,
  isExpanded,
  onExpand,
  onCollapse,
}) {
  const [isFreezeFrameVisible, setFreezeFrameVisible] = useState(false);
  const [isMoreShowed, setShowMore] = useState(false);
  const [toggleHeight, setToggleHeight] = useState(false);
  const refDescription = useRef(null);

  const getDisplayHeightToggle = () => {
    try {
      const rect = refDescription.current.getBoundingClientRect();
      if (typeof rect === "undefined") {
        throw new Error("The ref to toggler is broken");
      }
      const value = rect.height;
      return value > 60 ? true : false;
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  useEffect(() => {
    setToggleHeight(getDisplayHeightToggle());
  }, [isMoreShowed]);

  return (
    <>
      {isExpandable && isExpanded && (
        <div className={cn(classes.MobileExpandedRow)}>
          <div className={classes.DtcCodesTable__Cell}>
            <div className={classes.DtcCodesTable__CellAccentValue}>
              {description}
            </div>
            <div className={classes.DtcCodesTable__CellAdditionalValue}>
              {formatUsDateTime(time)}
            </div>
          </div>
          <div className={classes.DtcCodesTable__Cell}>{code}</div>
          {isExpandable && isExpanded && (
            <div
              onClick={() => {
                onCollapse(diagnosticId);
              }}
              className={classes.DtcCodesTable__CollapseIcon}
            ></div>
          )}
          {isExpandable && !isExpanded && (
            <div
              onClick={() => {
                onExpand(diagnosticId);
              }}
              className={classes.DtcCodesTable__ExpandIcon}
            ></div>
          )}
          <div className={classes.MobileExpandedCell}>
            <div className={classes.MobileCellName}>Controller/Source</div>
            <div className={classes.MobileCellValue}>
              <div>{controller}</div>
              <div className={classes.DtcCodesTable__CellAdditionalValue}>
                {source}
              </div>
            </div>
          </div>
          <div className={classes.MobileExpandedCell}>
            <div className={classes.MobileCellName}>Engine Type</div>
            <div className={classes.MobileCellValue}>{engineType}</div>
          </div>
          <div className={classes.MobileExpandedCell}>
            <div className={classes.MobileCellName}>Freeze Frame</div>
            <div className={classes.MobileCellValue}>
              {isFreezeFrameAvailable ? "Yes" : "No"}
            </div>
          </div>
          <div className={cn(classes.MobileExpandedCell)}>
            <div className={classes.MobileCellName}>Fault State</div>
            <div className={classes.MobileCellValue}>
              <div
                className={cn(
                  classes.DtcCodesTable__FaultStateCell,
                  isPending
                    ? classes.DtcCodesTable__PendingFaultStateCell
                    : isActive
                    ? classes.DtcCodesTable__ActiveFaultStateCell
                    : classes.DtcCodesTable__NonActiveFaultStateCell,
                  "text-center"
                )}
              >
                {faultState}
              </div>
            </div>
          </div>
          <div className={cn(classes.MobileExpandedCell)}>
            <div className={classes.MobileCellName}>Timestamps</div>
            <div className={classes.MobileCellValue}>{count}</div>
          </div>
        </div>
      )}
      <div key={diagnosticId} className={classes.DtcCodesTable__Row}>
        <div className={classes.DtcCodesTable__Cell}>
          <div className={classes.DtcCodesTable__CellAccentValue}>
            <span
              ref={refDescription}
              className={cn({
                [classes.DtcCodesTable__CellTruncatedValue]:
                  toggleHeight && !isMoreShowed,
              })}
            >
              {description}
            </span>
            {toggleHeight && (
              <span
                className={classes.DtcCodesTable__CellMoreButton}
                onClick={() => setShowMore(!isMoreShowed)}
              >
                {isMoreShowed ? "  Less" : "More"}
              </span>
            )}
          </div>
          <div className={classes.DtcCodesTable__CellAdditionalValue}>
            {formatUsDateTime(time)}
          </div>
        </div>
        <div className={classes.DtcCodesTable__Cell}>{code}</div>
        <div className={classes.DtcCodesTable__Cell}>
          <div>{controller}</div>
          <div className={classes.DtcCodesTable__CellAdditionalValue}>
            {source}
          </div>
        </div>
        <div className={classes.DtcCodesTable__Cell}>{engineType}</div>
        <div className={classes.DtcCodesTable__Cell}>
          {isFreezeFrameAvailable ? (
            <>
              <FreezeFrame
                isVisible={isFreezeFrameVisible}
                freezeFrame={freezeFrame}
              />
              <span>Yes</span>
              {freezeFrame && (
                <InfoIcon
                  className={cn(classes.DtcCodesTable__FreezeFrameIcon, {
                    [classes.DtcCodesTable__FreezeFrameIcon_Active]:
                      isFreezeFrameVisible,
                  })}
                  onClick={() => setFreezeFrameVisible((x) => !x)}
                />
              )}
            </>
          ) : (
            "No"
          )}
        </div>
        <div className={cn(classes.DtcCodesTable__Cell)}>
          <div
            className={cn(
              classes.DtcCodesTable__FaultStateCell,
              isPending
                ? classes.DtcCodesTable__PendingFaultStateCell
                : isActive
                ? classes.DtcCodesTable__ActiveFaultStateCell
                : classes.DtcCodesTable__NonActiveFaultStateCell,
              "text-center"
            )}
          >
            {faultState}
          </div>
        </div>
        <div
          className={cn(
            classes.DtcCodesTable__Cell,
            "d-flex justify-content-between align-items-center"
          )}
        >
          <div
            className={cn(
              isExpandable && isExpanded && classes.DtcCodesTable__Count
            )}
          >
            {count}
          </div>

          {isExpandable && isExpanded && (
            <div
              onClick={() => {
                onCollapse(diagnosticId);
              }}
              className={classes.DtcCodesTable__CollapseIcon}
            ></div>
          )}

          {isExpandable && !isExpanded && (
            <div
              onClick={() => {
                onExpand(diagnosticId);
              }}
              className={classes.DtcCodesTable__ExpandIcon}
            ></div>
          )}
        </div>
      </div>
    </>
  );
}

function FreezeFrame({ freezeFrame, isVisible }) {
  if (!isVisible) return null;
  return (
    <div className={classes.DtcCodesTable__FreezeFrame}>
      <div className="VehicleInfoPanel__headerLabel text-left">
        Freeze Frame Data{" "}
        <span className={classes.DtcCodes__SecondaryLabel}>
          ({Object.keys(freezeFrame).length})
        </span>
      </div>
      <div className={classes.DtcCodesTable__FreezeFrame__Header}>
        <div className={classes.DtcCodesTable__HeaderCell}>Name</div>
        <div className={classes.DtcCodesTable__HeaderCell}>Value</div>
      </div>
      <div className={classes.DtcCodesTable__FreezeFrame__ItemsContainer}>
        {Object.values(freezeFrame).map(({ name, value, unit }) => (
          <div className={classes.DtcCodesTable__FreezeFrame__Row}>
            <div className={classes.DtcCodesTable__CellAccentValue}>{name}</div>
            <div
              className={classes.DtcCodesTable__Cell}
            >{`${value} ${unit}`}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
