import React, { useEffect } from "react";
import WarningItem from "./WarningItem";
import classes from "./Warnings.module.scss";

import { WARNINGS } from "../../../../constants";
import { useDispatch, useSelector } from "react-redux";
import {
  getSelectedChartDateRange,
  getSelectedVehicle,
  getActiveDiagnosticWarnings,
  getWarningsLoading,
} from "../../vehicles.selectors";
import { fetchDiagnosticWarnings } from "../../vehicles.slice";
import Spinner from "../../../../components/spinner/Spinner";

export default function Warnings() {
  const selectedVehicle = useSelector(getSelectedVehicle);
  const selectedDateRange = useSelector(getSelectedChartDateRange);
  const activeWarnings = useSelector(getActiveDiagnosticWarnings);
  const isWarningsLoading = useSelector(getWarningsLoading);
  const dispatch = useDispatch();

  const diagnosticIds = Object.keys(WARNINGS);

  useEffect(() => {
    if (selectedVehicle) {
      dispatch(
        fetchDiagnosticWarnings({
          deviceId: selectedVehicle.deviceId,
          diagnosticIds: diagnosticIds.join(),
          from: new Date(selectedDateRange.start).toISOString(),
          to: new Date(selectedDateRange.end).toISOString(),
        })
      );
    }
  }, []);

  if (!activeWarnings.length) return null;

  return (
    <div className={classes.Warnings}>
      {isWarningsLoading ? (
        <Spinner />
      ) : (
        <div>
          <div className={classes.Warnings__Title_Wrapper}>
            <span className={classes.Warnings__Title}>
              Active Warning Lights{" "}
            </span>
            <span className={classes.Warnings__Count}>
              ({activeWarnings.length})
            </span>
          </div>
          {activeWarnings.map((warning) => {
            return (
              <div key={warning.id}>
                <WarningItem type={warning.id} text={warning.value.text} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
