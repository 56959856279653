/* eslint-disable indent */
import React from "react";
import cn from "classnames";
import Select from "react-select";
import { format, setHours, setMinutes, addHours, subHours } from "date-fns";

import { getFormattedDate } from "./utils";
import classes from "./TimePicker.module.scss";

const TimePicker = ({ label, date, onChange }) => {
  let isMenuOpen = false;
  const customStyles = {
    option: (provided, { isSelected }) => ({
      ...provided,
      backgroundColor: isSelected && "#DBEEFE!important",
      fontSize: 14,
      color: "#49505A",
      fontFamily: "Lato",
      fontWeight: 600,
      paddingTop: 14,
      paddingLeft: 20,
      paddingRight: 20,
      height: 45,
      cursor: "pointer",

      ":hover": {
        backgroundColor: "#EFF5FC",
      },
    }),
    control: (provided, { menuIsOpen }) => {
      isMenuOpen = menuIsOpen;
      return {
        ...provided,
        height: 40,
        width: 70,
        borderColor: menuIsOpen ? "#118AF9" : "#EBEFF4",
        boxShadow: menuIsOpen && "0 0 0 1px #118AF9",
        backgroundColor: "#FFFFFF",
        borderRadius: 5,
        paddingLeft: 8,
        cursor: "pointer",

        ":hover": {
          borderColor: "#118AF9",
          boxShadow: "0 0 0 1px #118AF9",
        },

        "@media only screen and (min-width: 768px)": {
          width: 82,
        },
      };
    },
    dropdownIndicator: (provided) => {
      const transform = isMenuOpen ? "rotate(180deg)" : "rotate(0deg)";
      const color = isMenuOpen ? "#118AF9" : "#A5AAC7";

      return {
        ...provided,
        color: "#A5AAC7",

        svg: {
          height: 16,
          width: 16,
          transform,
          color,
        },

        ":hover": {
          color: "#A5AAC7",
        },
      };
    },
    placeholder: (provided) => ({
      ...provided,
      fontSize: 14,
      lineHeight: 36,
      color: "#95A1B2",
      fontFamily: "Lato",
      fontWeight: 500,
    }),
    indicatorSeparator: () => ({
      border: "none",
    }),
    singleValue: (provided, { isDisabled }) => ({
      ...provided,
      fontSize: 14,
      lineHeight: 36,
      color: "#49505A",
      fontFamily: "Lato",
      fontWeight: 700,
      opacity: isDisabled ? 0.4 : 1,
    }),
    menu: (provided) => ({
      ...provided,
      paddingTop: 10,
      paddingBottom: 10,
      borderRadius: 5,
      boxShadow: "2px 10px 50px #3F4B8021",
    }),
  };

  const onHoursChange = (hours) => {
    const timeOfDay = format(date, "a");
    if (timeOfDay === "AM") {
      onChange(setHours(date, hours));
    } else if (timeOfDay === "PM") {
      onChange(setHours(date, hours + 12));
    }
  };

  const onMinutesChange = (minutes) => {
    onChange(setMinutes(date, minutes));
  };

  const onTimeOfDayChange = (toggle) => {
    const timeOfDay = format(date, "a");
    if (toggle === "PM" && timeOfDay === "AM") {
      onChange(addHours(date, 12));
    }
    if (toggle === "AM" && timeOfDay === "PM") {
      onChange(subHours(date, 12));
    }
  };

  const { hours, minutes, timeOfDay } = getFormattedDate(date);
  return (
    <div className={classes.TimePicker}>
      <span className={classes.TimePicker__label}>{label}</span>
      <div className={classes.TimePicker__wrapper}>
        <Select
          label="Time:"
          value={{
            value: hours,
            label: hours < 10 ? `0${hours}` : hours,
          }}
          isDisabled={!date}
          styles={customStyles}
          onChange={(hours) => onHoursChange(hours.value)}
          options={[
            { value: 12, label: "12" },
            { value: 1, label: "01" },
            { value: 2, label: "02" },
            { value: 3, label: "03" },
            { value: 4, label: "04" },
            { value: 5, label: "05" },
            { value: 6, label: "06" },
            { value: 7, label: "07" },
            { value: 8, label: "08" },
            { value: 9, label: "09" },
            { value: 10, label: "10" },
            { value: 11, label: "11" },
          ]}
        />
        <span className={classes.TimePicker__separator}>:</span>
        <Select
          value={{
            value: minutes,
            label: minutes < 10 ? `0${minutes}` : minutes,
          }}
          isDisabled={!date}
          styles={customStyles}
          onChange={(minutes) => onMinutesChange(minutes.value)}
          options={[
            { value: 0, label: "00" },
            { value: 5, label: "05" },
            { value: 10, label: "10" },
            { value: 15, label: "15" },
            { value: 20, label: "20" },
            { value: 25, label: "25" },
            { value: 30, label: "30" },
            { value: 35, label: "35" },
            { value: 40, label: "40" },
            { value: 45, label: "45" },
            { value: 50, label: "50" },
            { value: 55, label: "55" },
          ]}
        />
        <div className={classes.TimeToggle}>
          <span
            className={cn(
              classes.TimeToggle__button,
              {
                [classes["TimeToggle__button--isActive"]]: timeOfDay === "AM",
              },
              { [classes["TimeToggle__button--isDisabled"]]: !date }
            )}
            onClick={() => onTimeOfDayChange("AM")}
          >
            AM
          </span>
          <span
            className={cn(
              classes.TimeToggle__button,
              {
                [classes["TimeToggle__button--isActive"]]: timeOfDay === "PM",
              },
              { [classes["TimeToggle__button--isDisabled"]]: !date }
            )}
            onClick={() => onTimeOfDayChange("PM")}
          >
            PM
          </span>
        </div>
      </div>
    </div>
  );
};

export default TimePicker;
