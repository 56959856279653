import { createSelector } from "@reduxjs/toolkit";

export const isLoaded = (state) => state.customers.byId;

export const getCustomers = (state) => Object.values(state.customers.byId);
export const getCustomerFilter = (state) => state.customers.customerFilter;

export const getSelectedCustomer = (state) =>
  state.customers.byId[state.customers.selectedCustomer];

export const getSelectedCustomerVehicles = createSelector(
  getSelectedCustomer,
  (selectedCustomer) => (selectedCustomer ? selectedCustomer.vehicles : null)
);

export const getFilteredCustomers = createSelector(
  [getCustomers, getCustomerFilter],
  (customers, filterValue) => {
    return customers.filter(
      ({ name }) => name.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1
    );
  }
);

export const getMeasurementSystem = createSelector(
  getSelectedCustomer,
  (selectedCustomer) => selectedCustomer && selectedCustomer.measurementSystem
);

export const getIdsByVins = (state) => state.customers.byVin;

export const getShopProvider = (state) => state.customers.shopProvider;

export const getSteerCustomers = (state) => state.customers.steerCustomers;

export const isSteerCustomersLoading = (state) =>
  state.customers.isSteerLoading;
export const getCustomerError = (state) => state.customers.error;

export const getIsCustomersLoading = (state) => state.customers.isLoading;

export const getRemoveModalShown = (state) =>
  state.customers.isRemoveModalShown;

export const getRemovingCustomer = (state) =>
  state.customers.isRemovingCustomer;
