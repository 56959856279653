import React, { useEffect, useState } from "react";
import classes from "./Alerts.module.scss";
import { useSelector, useDispatch } from "react-redux";
import {
  getSelectedVehicle,
  isAlertsLoading,
  getDiagnosticChartOptions,
  getSelectedVehicleMonitors,
} from "../../vehicles.selectors";
import {
  fetchVehicleAlerts,
  fetchVehicleAlertsPastActivity,
} from "../../vehicles.slice";
import {
  getSelectedCustomer,
  getMeasurementSystem,
} from "../../../customers/customers.selectors";
import { getSelectedTenant } from "../../../tenants/tenants.selectors";
import AlertItem from "./AlertItem";
import cn from "classnames";
import Spinner from "../../../../components/spinner/Spinner";
import AlertIcon from "../../../../assets/icons/alerts.svg";
import { EmptyAlertsIcon } from "../../../../assets/icons";
import PastActivity from "./PastActivity";
import AlertsModal from "./AlertsModal";
import {
  createAlert,
  updateAlert,
  deleteAlert,
} from "../../../../services/api.service";

import {
  booleanTemplateAlerts,
  diagnosticOptionsToAlerts,
  diagnosticOptionsToAggregationAlerts,
  monitorsToAlerts,
  alertsParams,
  defaultAlertSilenceTime,
} from "../../../../constants";

import { getRoutePrefix } from "../../../../services/env.service";

export default function Alerts({ className }) {
  const dispatch = useDispatch();
  const selectedVehicle = useSelector(getSelectedVehicle);
  const selectedCustomer = useSelector(getSelectedCustomer);
  const selectedTenant = useSelector(getSelectedTenant);
  const isLoading = useSelector(isAlertsLoading);
  const diagnosticOptions = useSelector(getDiagnosticChartOptions);
  const monitors = useSelector(getSelectedVehicleMonitors);
  const measurementSystem = useSelector(getMeasurementSystem);
  const isMeasurementSystemImperial = measurementSystem === "imperial";

  const [allAlerts, setAllAlerts] = useState({});
  const [isModalOpened, setModalOpened] = useState(false);
  const [silenceTime, setSilenceTime] = useState();

  useEffect(() => {
    if (
      selectedVehicle &&
      selectedCustomer &&
      !selectedCustomer.isVehiclesListLoading
      // Need to reload alerts when customer is changed, because alerts store phone number. After vehicles list is loaded, because alerts are cleared in state after vehicles fetched
    ) {
      dispatch(fetchVehicleAlerts(selectedVehicle));
    }
  }, [selectedVehicle && selectedVehicle.id, selectedCustomer]);

  useEffect(() => {
    if (selectedVehicle) {
      dispatch(
        fetchVehicleAlertsPastActivity({
          id: selectedVehicle.id,
          offset: 0,
        })
      );
    }
  }, [selectedVehicle && selectedVehicle.id]);

  useEffect(() => {
    if (selectedVehicle) {
      const alerts = selectedVehicle.alerts.data.reduce((acc, i) => {
        acc[i.parameter] = i;
        return acc;
      }, {});

      const initialSilenceTime =
        Object.values(alerts).find((x) => x)?.silenceTime ??
        defaultAlertSilenceTime;

      setSilenceTime(initialSilenceTime);

      const possibleAlerts = { ...booleanTemplateAlerts };
      for (const option of diagnosticOptions) {
        if (diagnosticOptionsToAlerts[option.value]) {
          possibleAlerts[diagnosticOptionsToAlerts[option.value]] = false;
        }
        if (diagnosticOptionsToAggregationAlerts[option.value]) {
          possibleAlerts[
            diagnosticOptionsToAggregationAlerts[option.value]
          ] = false;
        }
      }
      for (const monitor of monitors) {
        if (monitor.isAvailable && monitorsToAlerts[monitor.name]) {
          possibleAlerts[monitorsToAlerts[monitor.name]] = false;
        }
      }

      setAllAlerts({
        ...possibleAlerts,
        ...alerts,
      });
    }
  }, [selectedVehicle && selectedVehicle.alerts, diagnosticOptions, monitors]);

  function refetchAlerts() {
    dispatch(fetchVehicleAlerts(selectedVehicle));
    dispatch(
      fetchVehicleAlertsPastActivity({ offset: 0, id: selectedVehicle.id })
    );
  }

  async function handleAlertsChange({ alertsTogglers, silenceTime }) {
    await Promise.all(
      Object.entries(alertsTogglers).map(async ([parameter, status]) => {
        if (!allAlerts[parameter] && status === true) {
          const alertConfig = alertsParams[parameter];

          const triggerRange =
            alertConfig.triggerType === "range"
              ? isMeasurementSystemImperial
                ? alertConfig.triggerRangeImperial.map((value) =>
                    alertConfig.convertImperialToMetric(value)
                  )
                : alertConfig.triggerRangeMetric
              : [];

          const newAlert = {
            triggerType: alertConfig.triggerType,
            triggerRange,
            triggerValue: alertConfig.triggerValue,
            triggerStatusTexts: alertConfig.triggerStatusTexts || [],
            parameter,
            deviceId: selectedVehicle.deviceId,
            customerId: selectedCustomer.id,
            vehicleId: selectedVehicle.id,
            shopId: getRoutePrefix(),
            tenantId: selectedTenant.id,
            subscribers: {
              shop: false,
              customer: false,
            },
            textTemplates: alertConfig.textTemplates,
            silenceTime,
          };
          try {
            await createAlert(newAlert);
          } catch (e) {
            console.log(`cannot create alert for ${parameter}`);
          }
        } else if (allAlerts[parameter] && status === false) {
          try {
            await deleteAlert({
              alertId: allAlerts[parameter]._id,
              deviceId: selectedVehicle.deviceId,
            });
          } catch (e) {
            console.log(`cannot delete alert ${parameter}`);
          }
        } else if (
          allAlerts[parameter] &&
          status === true &&
          allAlerts[parameter].silenceTime !== silenceTime
        ) {
          try {
            const alertUpdate = {
              _id: allAlerts[parameter]._id,
              deviceId: selectedVehicle.deviceId,
              silenceTime,
            };
            await updateAlert(alertUpdate);
          } catch (e) {
            console.log(`cannot update alert ${parameter}`);
          }
        }
      })
    );
    refetchAlerts();
  }

  if (!selectedVehicle) {
    return null;
  }

  if (isLoading || !selectedVehicle.overview.data) {
    return (
      <div
        className={cn([
          classes.SpinnerWrapper,
          className,
          "VehicleInfoPanel__headerLabel position-relative",
        ])}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div className={className}>
      <div className="VehicleInfoPanel__headerLabel w-100 flex mb-4">
        <div className="flex items-center">
          <img src={AlertIcon} alt="" className="mr-3" />
          <span>Alerts</span>
        </div>
        <button
          className={classes.ManageAlertsButton}
          onClick={() => setModalOpened(!isModalOpened)}
        ></button>
      </div>
      {Object.values(allAlerts).some((i) => i) ? (
        Object.values(allAlerts)
          .filter((i) => i)
          .map((alert) => {
            const alertConfig = alertsParams[alert.parameter];
            return (
              <div className={classes.AlertItem__Wrapper} key={alert._id}>
                <AlertItem
                  title={alertConfig.name}
                  description={
                    alert.triggerType === "value"
                      ? alertConfig.description
                      : null
                  }
                  alert={alert}
                  refetchAlerts={refetchAlerts}
                  withSlider={alert.triggerType === "range"}
                  minValue={
                    alert.triggerType === "range"
                      ? alertConfig[
                          isMeasurementSystemImperial
                            ? "triggerRangeImperial"
                            : "triggerRangeMetric"
                        ][0]
                      : null
                  }
                  maxValue={
                    alert.triggerType === "range"
                      ? alertConfig[
                          isMeasurementSystemImperial
                            ? "triggerRangeImperial"
                            : "triggerRangeMetric"
                        ][1]
                      : null
                  }
                  alertConfig={alertConfig}
                />
              </div>
            );
          })
      ) : (
        <div className="flex justify-center">
          <div className={cn([classes.Alerts__NoData, "font-md font-bold"])}>
            <EmptyAlertsIcon className={classes.icon} />
            There is no data to display.
          </div>
        </div>
      )}

      {isModalOpened && (
        <AlertsModal
          isOpen={isModalOpened}
          toggle={() => setModalOpened(!isModalOpened)}
          alerts={allAlerts}
          alertsParams={alertsParams}
          onSubmit={handleAlertsChange}
          initialSilenceTime={silenceTime}
        />
      )}

      <PastActivity />
    </div>
  );
}
