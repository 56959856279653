import { createSelector } from "@reduxjs/toolkit";
import lodash from "lodash";
import { getAdminStatus } from "../../App/ui.slice";
import { WHITELIST } from "../../constants";
import { convertToImperialSystem } from "../../utils";

import {
  getSelectedCustomer,
  getMeasurementSystem,
} from "../customers/customers.selectors";

export const MAX_CHART_VALUES = 10000;

export const getVehiclesById = (state) => state.vehicles.byId;
export const getSelectedVehicleId = (state) => state.vehicles.selectedVehicle;
export const getVehicleState = (state) => state.vehicles;
export const getChartLoading = (state) => state.vehicles.isChartLoading;
export const getWarningsLoading = (state) => state.vehicles.isWarningsLoading;
export const getDeviceAlertStatus = (state) =>
  state.vehicles.isShownDeviceAlert;
export const getSelectedChartOptions = (state) =>
  state.vehicles.selectedChartOptions;
export const getDiagnosticChartOptions = (state) =>
  state.vehicles.diagnosticChartOptions;
export const getDiagnosticChartValues = (state) =>
  state.vehicles.diagnosticChartValues;
export const getDiagnosticWarnings = (state) =>
  state.vehicles.diagnosticWarnings;
export const getSelectedChartDateRange = (state) =>
  state.vehicles.selectedChartDateRange;
export const getSelectedVehicle = (state) =>
  state.vehicles.byId[state.vehicles.selectedVehicle];

export const getVehicleFilter = (state) => state.vehicles.vehicleFilter;

export const getIsClearDTCLoading = (state) => state.vehicles.isClearDTCLoading;

export const getSelectedVehicleMonitors = createSelector(
  getSelectedVehicle,
  (vehicle) => {
    return vehicle ? vehicle.monitors.data : null;
  }
);

export const getActiveDiagnosticWarnings = createSelector(
  getDiagnosticWarnings,
  (warnings) => {
    return Object.entries(warnings)
      .map((warning) => {
        const [key, value] = warning;
        return { id: key, value };
      })
      .filter((warning) => warning.value.data);
  }
);

export const getCoreVehicleData = createSelector(
  getSelectedVehicle,
  (vehicle) => {
    return vehicle ? vehicle.coreParameters.data : null;
  }
);

export const getSelectedVehicleCoreParameters = createSelector(
  getSelectedVehicle,
  (vehicle) => {
    return vehicle ? vehicle.coreParameters.data : null;
  }
);

export const getTirePressure = createSelector(
  getSelectedVehicleCoreParameters,
  (coreParams) => {
    return coreParams ? coreParams.tirePressure : null;
  }
);
export const getEngineOperationalTime = createSelector(
  getSelectedVehicleCoreParameters,
  (coreParams) => {
    return coreParams ? coreParams.engineOperationalTime : null;
  }
);

export const getFaultData = createSelector(getSelectedVehicle, (vehicle) => {
  return vehicle ? vehicle.faults.data : null;
});

export const getDtcFaultData = createSelector(getFaultData, (faultData) => {
  return faultData
    ? {
        faults: faultData.faults.filter((entry) => entry.isDtcCode),
        isClearDTCAvailable: faultData.isClearDTCAvailable,
      }
    : null;
});

export const getLatestFuelMetrics = createSelector(
  getSelectedVehicleCoreParameters,
  (coreParams) => {
    return {
      distanceDriven: coreParams.distanceDriven,
      fuelUsed: coreParams.fuelUsed,
      idleFuelUsed: coreParams.idleFuelUsed,
      mpg: coreParams.mpg,
    };
  }
);

export const getLatestGenericMetrics = createSelector(
  getSelectedVehicleCoreParameters,
  (coreParams) => {
    return {
      distanseWithMilOn: coreParams.distanseWithMilOn,
      timeSinceCodesCleared: coreParams.timeSinceCodesCleared,
      timeSinceMilOn: coreParams.timeSinceMilOn,
      warmsup: coreParams.warmsup,
      disanceSinceCodesCleared: coreParams.disanceSinceCodesCleared,
    };
  }
);

export const getVisualBlockParameters = createSelector(
  getSelectedVehicleCoreParameters,
  ({
    coolantTemperature,
    crankingVoltage,
    fuelLevelPercentage,
    transmissionOilTemperature,
  }) => {
    return {
      coolantTemperature,
      crankingVoltage,
      fuelLevelPercentage,
      transmissionOilTemperature,
    };
  }
);

export const getFuelFilterLifeRemaining = createSelector(
  getSelectedVehicleCoreParameters,
  (coreParameters) => {
    return coreParameters.fuelFilterLifeRemaining;
  }
);

export const getEngineOilLifeRemaining = createSelector(
  getSelectedVehicleCoreParameters,
  (coreParameters) => {
    return coreParameters.engineOilLifeRemaining;
  }
);

export const getIsGeneralVehicleDataLoading = createSelector(
  getSelectedVehicle,
  (vehicle) => (vehicle ? vehicle.overview.isLoading : null)
);

export const getIsMonitorsDataLoading = createSelector(
  getSelectedVehicle,
  (vehicle) => (vehicle ? vehicle.monitors.isLoading : null)
);

export const isFaultDataLoading = createSelector(
  getSelectedVehicle,
  (vehicle) => (vehicle ? vehicle.faults.isLoading : null)
);

export const isAlertsLoading = createSelector(getSelectedVehicle, (vehicle) =>
  vehicle ? vehicle.alerts.isLoading : null
);

export const getIsServicesDataLoading = createSelector(
  getSelectedVehicle,
  (vehicle) => (vehicle ? vehicle.services.isLoading : null)
);

export const getVehicleBodyType = createSelector(
  getSelectedVehicle,
  (vehicle) => {
    const bodyType =
      vehicle.overview &&
      vehicle.overview.data &&
      vehicle.overview.data.vinData &&
      vehicle.overview.data.vinData.bodyType
        ? vehicle.overview.data.vinData.bodyType.toLowerCase()
        : "";

    let bodyTypeClass;

    switch (bodyType) {
      case "full-size":
        bodyTypeClass = "suv";
        break;
      case "chassis":
      case "srw":
        bodyTypeClass = "pickup";
        break;
      default:
        bodyTypeClass = bodyType;
    }

    return bodyTypeClass;
  }
);

export const getFilteredVehicles = createSelector(
  [getSelectedCustomer, getVehiclesById, getVehicleFilter],
  (selectedCustomer, vehiclesById, vehicleFilter) => {
    return ((selectedCustomer && selectedCustomer.vehicles) || [])
      .map((vehicleId) => vehiclesById[vehicleId])
      .filter((vehicle) => {
        if (!vehicle) {
          return false;
        }

        if (
          vehicle.name.toLowerCase().indexOf(vehicleFilter.toLowerCase()) !== -1
        ) {
          return true;
        }

        if (!vehicle.overview.data) {
          return false;
        }

        if (
          vehicle.overview.data.vin
            .toLowerCase()
            .indexOf(vehicleFilter.toLowerCase()) !== -1
        ) {
          return true;
        }

        return false;
      });
  }
);

export const getFormattedChartYaxis = createSelector(
  getSelectedChartOptions,
  (options) =>
    options.map((option) => {
      if (option) {
        return {
          title: {
            text: `${option.label}, ${option.unit
              .replace(/Id$/, "")
              .replace(/^UnitOfMeasure/, "")}`,
          },
        };
      }
    })
);

export const getFilteredChartOptions = createSelector(
  [getDiagnosticChartOptions, getAdminStatus],
  (options, isAdmin) =>
    options.filter(
      ({ label }) => isAdmin || WHITELIST.find((el) => el === label)
    )
);

export const getMultipleChartData = createSelector(
  [getSelectedChartOptions, getDiagnosticChartValues, getMeasurementSystem],
  (diagnosticOtions, diagnosticValues, measurementSystem) => {
    const isMeasurementSystemImperial = measurementSystem === "imperial";
    const dateRange = {};
    return diagnosticOtions.map((option, i) => {
      if (option) {
        const data =
          !lodash.isEmpty(diagnosticValues) &&
          diagnosticValues.hasOwnProperty(option.value)
            ? diagnosticValues[option.value]
            : [];

        const sortedByDate = [...data].sort((a, b) => {
          return new Date(a.dateTime) - new Date(b.dateTime);
        });

        let itemsToShow;

        if (i === 0) {
          // Limit items count for first selected option
          // For next options will be shown all items in accordance to date range of first selected option
          if (sortedByDate.length > MAX_CHART_VALUES) {
            itemsToShow = sortedByDate.slice(
              sortedByDate.length - MAX_CHART_VALUES
            );
            dateRange.start = itemsToShow[0].dateTime;
            dateRange.end = itemsToShow[itemsToShow.length - 1].dateTime;
          } else {
            if (sortedByDate.length) {
              itemsToShow = sortedByDate;
              dateRange.start = sortedByDate[0].dateTime;
              dateRange.end = sortedByDate[sortedByDate.length - 1].dateTime;
            }
          }
        } else {
          itemsToShow = sortedByDate.filter((el) => {
            return (
              el.dateTime >= dateRange.start && el.dateTime <= dateRange.end
            );
          });
        }

        const [values, unit] = isMeasurementSystemImperial
          ? convertToImperialSystem(
              itemsToShow ? itemsToShow : [],
              option && option.unit
            )
          : [itemsToShow ? itemsToShow : [], option ? option.unit : ""];

        // Format values in accordance to chart structure and sort by date
        const formattedData = values.map((value) => ({
          x: value.dateTime,
          y:
            typeof value.data === "boolean"
              ? (value.data && 1) || 0
              : value.data.toFixed(2),
        }));

        return {
          data: formattedData,
          unit,
          name: option.label,
        };
      }
    });
  }
);

export const getVehicleAlertsPastActivity = createSelector(
  getSelectedVehicle,
  (vehicle) => {
    const pastActivity =
      vehicle.alerts && vehicle.alerts.pastActivity
        ? vehicle.alerts.pastActivity
        : [];

    return pastActivity;
  }
);

export const getHasMorePastAcvivity = createSelector(
  getSelectedVehicle,
  (vehicle) => {
    return vehicle.alerts.hasMore;
  }
);

export const getRecommendedServices = createSelector(
  getSelectedVehicle,
  (vehicle) => {
    const recommendedServices =
      vehicle && vehicle.services ? vehicle.services.data : [];

    return recommendedServices;
  }
);

export const getServicesHistory = createSelector(
  getSelectedVehicle,
  (vehicle) => (vehicle && vehicle.servicesHistory) || {}
);

export const getSteerCustomerVehicles = (state) => state.vehicles.steerVehicles;

export const isSteerCustomerVehiclesLoading = (state) =>
  state.vehicles.isSteerVehiclesLoading;

export const isVehicleLoading = (state) => state.vehicles.isLoading;
