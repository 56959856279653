import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { keyBy } from "lodash";

import {
  fetchAllCustomers,
  createCustomer as createCustomerRequest,
  editCustomer as editCustomerRequest,
  deleteCustomer as deleteCustomerRequest,
  fetchSteerCustomers as fetchSteerCustomersRequest,
} from "../../services/api.service";

const fetchCustomersThunk = createAsyncThunk(
  "customers/fetchAll",
  fetchAllCustomers
);

const createCustomerThunk = createAsyncThunk(
  "customers/createCustomer",
  async (data, thunkAPI) => {
    try {
      await createCustomerRequest(data);
      thunkAPI.dispatch(fetchCustomersThunk());
    } catch (e) {
      await thunkAPI.dispatch(setCustomerError(e));
    }
  }
);
const editCustomerThunk = createAsyncThunk(
  "customers/editCustomer",
  async (data, thunkAPI) => {
    await editCustomerRequest(data);
    thunkAPI.dispatch(fetchCustomersThunk());
  }
);

const deleteCustomerThunk = createAsyncThunk(
  "customers/deleteCustomer",
  deleteCustomerRequest
);

const fetchSteerCustomersThunk = createAsyncThunk(
  "customers/SteerAll",
  async (data) => {
    const steerCustomers = await fetchSteerCustomersRequest(data);
    return steerCustomers;
  }
);

const customersSlice = createSlice({
  name: "customers",
  initialState: {
    byId: null,
    byVin: {},
    isLoading: false,
    isRemoveModalShown: false,
    isRemovingCustomer: false,
    hasError: false,
    selectedCustomer: null,
    customerFilter: "",
    steerCustomers: null,
    isSteerLoading: false,
    error: "",
  },
  reducers: {
    selectCustomer(state, action) {
      state.selectedCustomer = action.payload;
    },
    setCustomerFilter(state, action) {
      state.customerFilter = action.payload;
    },
    selectVehicle(state, action) {
      state.selectedVehicle = action.payload;
    },
    setVehicleFilter(state, action) {
      state.vehicleFilter = action.payload;
    },
    setCustomerVehicleListLoading(state, action) {
      const customerId = action.payload;

      state.byId[customerId].hasVehicleListError = false;
      state.byId[customerId].isVehiclesListLoading = true;
    },
    setCustomerVehicleListLoadingError(state, action) {
      const customerId = action.payload;
      state.byId[customerId].hasVehicleListError = true;
      state.byId[customerId].isVehiclesListLoading = false;
    },
    setCustomerVehicleListLoadingSuccess(state, action) {
      const customerId = action.payload;
      state.byId[customerId].hasVehicleListError = false;
      state.byId[customerId].isVehiclesListLoading = false;
    },
    setCustomerError(state, action) {
      state.error = action.payload;
    },
    setRemoveModalShown(state, action) {
      state.isRemoveModalShown = action.payload;
    },
  },
  extraReducers: {
    [fetchCustomersThunk.pending](state) {
      state.byId = null;
      state.isLoading = true;
      state.hasError = false;
    },
    [fetchCustomersThunk.fulfilled](state, action) {
      state.isLoading = false;
      state.hasError = false;
      state.error = "";

      const customersList = [];
      const vins = {};

      action.payload.customers.forEach((customer) => {
        customersList.push(convertToCustomerModel(customer));
        Object.entries(customer.vehicles).forEach(([vehicleId, { vin }]) => {
          if (vin) vins[vin] = { customerId: customer._id, vehicleId };
        });
      });

      state.byId = keyBy(customersList, "id");
      state.byVin = vins;
      state.shopProvider = action.payload.shopProvider;
    },
    [fetchCustomersThunk.rejected](state) {
      state.byId = null;
      state.isLoading = false;
      state.hasError = true;
    },
    [createCustomerThunk.pending](state) {
      state.isLoading = true;
      state.hasError = false;
    },
    [createCustomerThunk.fulfilled](state) {
      state.isLoading = false;
      state.hasError = false;
    },
    [createCustomerThunk.rejected](state) {
      state.byId = null;
      state.isLoading = false;
      state.hasError = true;
    },
    [editCustomerThunk.pending](state) {
      state.isLoading = true;
      state.hasError = false;
    },
    [editCustomerThunk.fulfilled](state, action) {
      state.isLoading = false;
      state.hasError = false;
    },
    [editCustomerThunk.rejected](state) {
      state.isLoading = false;
      state.hasError = true;
    },
    [deleteCustomerThunk.pending](state) {
      state.isRemovingCustomer = true;
    },
    [deleteCustomerThunk.fulfilled](state, action) {
      state.isRemovingCustomer = false;
      state.selectedCustomer = null;
      delete state.byId[action.meta.arg.id];
      state.isRemoveModalShown = false;
    },
    [deleteCustomerThunk.rejected](state) {
      state.isRemovingCustomer = false;
      state.isRemoveModalShown = false;
    },
    [fetchSteerCustomersThunk.pending](state) {
      state.steerCustomers = null;
      state.isSteerLoading = true;
    },
    [fetchSteerCustomersThunk.fulfilled](state, action) {
      state.steerCustomers = action.payload;
      state.isSteerLoading = false;
    },
    [fetchSteerCustomersThunk.rejected](state) {
      state.steerCustomers = null;
      state.isSteerLoading = false;
    },
  },
});

export { fetchCustomersThunk as fetchCustomers };
export { createCustomerThunk as createCustomer };
export { editCustomerThunk as editCustomer };
export { deleteCustomerThunk as deleteCustomer };
export { fetchSteerCustomersThunk as fetchSteerCustomers };

const { actions, reducer } = customersSlice;
export const {
  selectCustomer,
  setCustomerFilter,
  selectVehicle,
  setVehicleFilter,
  setCustomerVehicleListLoading,
  setCustomerVehicleListLoadingError,
  setCustomerVehicleListLoadingSuccess,
  setCustomerError,
  setRemoveModalShown,
} = actions;
export default reducer;

function convertToCustomerModel({
  _id,
  name,
  vehicles,
  isBusinessAccount = false,
  notes,
  phoneNumber,
  additionalPhoneNumbers,
  customerConsented,
  measurementSystem,
  mojioCustomerId,
}) {
  const vehiclesWithConnectedDevices = [];
  const vehiclesWithIgnitionOn = [];
  const vehiclesWithCheckEngineLight = [];
  const vehiclesWithWarnings = [];

  Object.entries(vehicles).forEach(([id, data]) => {
    if (data.connected) vehiclesWithConnectedDevices.push(id);
    if (data.ignition) vehiclesWithIgnitionOn.push(id);
    if (data.checkEngine) vehiclesWithCheckEngineLight.push(id);
    if (Object.keys(data.warnings).length) vehiclesWithWarnings.push(id);
  });

  const customer = {
    id: _id,
    name,
    isBusinessAccount,
    vehicles: Object.keys(vehicles),
    vehiclesWithConnectedDevices,
    vehiclesWithIgnitionOn: vehiclesWithIgnitionOn.length,
    vehiclesWithCheckEngineLight,
    vehiclesWithWarnings,
    vehiclesData: vehicles,
    vehiclesInactive:
      Object.entries(vehicles).length - vehiclesWithConnectedDevices.length,
    isVehiclesListLoading: false,
    hasVehicleListError: false,
    notes,
    phoneNumber,
    additionalPhoneNumbers,
    customerConsented,
    measurementSystem,
  };
  if (mojioCustomerId) customer.email = mojioCustomerId;

  return customer;
}
