import React from "react";
import classes from "./Warnings.module.scss";
import cn from "classnames";
import { WARNINGS, PREDICTION_WARNINGS } from "../../../../constants";

export default function WarningItem({ type, text, isPopupWarning }) {
  const warningConfig = WARNINGS[type] || PREDICTION_WARNINGS[type];
  if (!warningConfig) return null;

  return (
    <div
      className={cn([classes.Warnings__Item, classes.WarningYellow], {
        [classes.WarningRed]: !text && warningConfig.color === "red",
        [classes.PopupWarning]: isPopupWarning,
      })}
    >
      <div className={classes.IconBlock}>
        <img src={warningConfig.icon} className={classes.Icon} alt="" />
      </div>
      <div className={classes.LabelBlock}>
        <span>{`${warningConfig.label}${text ? ". " + text : ""}`}</span>
      </div>
    </div>
  );
}
