/* clamp a value between a min and max value */
export function clamp(value, min, max) {
  return Math.min(Math.max(value, min), max);
}

/* convert a point into a percentage value */
function getPercentageFromPosition(position, clientRect) {
  const length = clientRect.width;
  const sizePerc = position.x / length;

  return sizePerc || 0;
}

/* convert a model value into a percentage value */
function getPercentageFromValue(value, minValue, maxValue) {
  const validValue = clamp(value, minValue, maxValue);
  const valueDiff = maxValue - minValue;
  const valuePerc = (validValue - minValue) / valueDiff;

  return valuePerc || 0;
}

/* convert a value into a point */
function getPositionFromValue(value, minValue, maxValue, clientRect) {
  const length = clientRect.width;
  const valuePerc = getPercentageFromValue(value, minValue, maxValue);
  const positionValue = valuePerc * length;

  return {
    x: positionValue,
    y: 0,
  };
}

/* convert a point into a model value */
export function getValueFromPosition(position, minValue, maxValue, clientRect) {
  const sizePerc = getPercentageFromPosition(position, clientRect);
  const valueDiff = maxValue - minValue;

  return minValue + valueDiff * sizePerc;
}

/* convert a range of values into points */
export function getPositionsFromValues(values, minValue, maxValue, clientRect) {
  return {
    min: getPositionFromValue(values.min, minValue, maxValue, clientRect),
    max: getPositionFromValue(values.max, minValue, maxValue, clientRect),
  };
}

/* convert a value into a step value */
export function getStepValueFromValue(value, valuePerStep) {
  return Math.round(value / valuePerStep) * valuePerStep;
}

/* calculate the absolute difference between two numbers */
export function length(numA, numB) {
  return Math.abs(numA - numB);
}

/* convert model values into percentage values */
export function getPercentagesFromValues(values, minValue, maxValue) {
  return {
    min: getPercentageFromValue(values.min, minValue, maxValue),
    max: getPercentageFromValue(values.max, minValue, maxValue),
  };
}

/* calculate the distance between pointA and pointB */
export function distanceTo(pointA, pointB) {
  const xDiff = (pointB.x - pointA.x) ** 2;
  const yDiff = (pointB.y - pointA.y) ** 2;

  return Math.sqrt(xDiff + yDiff);
}

/* function provides input value with text or just text */
export function formInputValue(value, inscription, inscriptionPosition) {
  if (value === "Min" || value === "Max") {
    return value;
  } else if (inscription && inscriptionPosition === "left") {
    return `${inscription} ${value}`;
  } else if (inscription && inscriptionPosition === "right") {
    return `${value} ${inscription}`;
  }
  return value;
}

export function calculateGradientValueColor(gradientConfig, percentage) {
  for (let i = 0; i < gradientConfig.length - 1; i++) {
    const rangeStart = gradientConfig[i].percentage;
    const rangeStartColor = gradientConfig[i].color;
    const rangeEnd = gradientConfig[i + 1].percentage;
    const rangeEndColor = gradientConfig[i + 1].color;

    if (percentage >= rangeStart && percentage < rangeEnd) {
      return determineColorWithinGradient({
        colorStart: rangeStartColor,
        colorEnd: rangeEndColor,
        ratio: (percentage - rangeStart) / (rangeEnd - rangeStart),
      });
    } else if (percentage === rangeEnd) {
      return rangeEndColor;
    }
  }
}

function determineColorWithinGradient({ colorStart, colorEnd, ratio }) {
  const color1 = colorStart.replace("#", "");
  const color2 = colorEnd.replace("#", "");

  const hex = (color) => {
    const colorString = color.toString(16);
    return colorString.length === 1 ? `0${colorString}` : colorString;
  };

  const r = Math.ceil(
    parseInt(color2.substring(0, 2), 16) * ratio +
      parseInt(color1.substring(0, 2), 16) * (1 - ratio)
  );
  const g = Math.ceil(
    parseInt(color2.substring(2, 4), 16) * ratio +
      parseInt(color1.substring(2, 4), 16) * (1 - ratio)
  );
  const b = Math.ceil(
    parseInt(color2.substring(4, 6), 16) * ratio +
      parseInt(color1.substring(4, 6), 16) * (1 - ratio)
  );

  return "#" + hex(r) + hex(g) + hex(b);
}
