import React, { useEffect, useRef } from "react";
import cn from "classnames";
import PropTypes from "prop-types";

import { customizeColumnStyle } from "../../Table.utils";
import Collapse from "../../../сollapse/Collapse";
import styles from "./Row.module.scss";
import { useScreenSize } from "../../../../utility/hooks";

const Row = ({
  row,
  data,
  CollapseRowComponent,
  ToggleComponent,
  setSize,
  index,
  setRowProps,
  withHidingColumns,
  setCollapsedRowId,
  setCollapsedRowClientHeight,
  collapsedRowId,
  togglerClassName,
}) => {
  const { cells, getRowProps } = row;

  const rowRef = useRef();
  const isExpanded = collapsedRowId === row.id;
  const { isTablet } = useScreenSize();

  useEffect(() => {
    if (setSize) {
      setSize(index, rowRef.current.getBoundingClientRect().height);
    }
  }, [setSize, index, isExpanded]);

  useEffect(() => {
    if (isExpanded) {
      setCollapsedRowClientHeight &&
        setCollapsedRowClientHeight(rowRef.current.clientHeight);
    }
  }, [isExpanded]);

  const propsList = ["style", "key", "onClick", "id"]; // add here other props if you need
  const rowProps = getRowProps(row);
  const filteredRowProps = propsList.reduce((acc, current) => {
    if (rowProps[current]) {
      acc[current] = rowProps[current];
    }
    return acc;
  }, {});
  const classes = cn(styles.row, {
    [styles.isExpanded]: row.isExpanded,
    "px-20": !CollapseRowComponent,
    "pl-10": !CollapseRowComponent && isTablet,
    [styles.rowMargin]: !CollapseRowComponent && row?.index !== data.length - 1,
  });
  const RenderRow = () => (
    <div
      className={classes}
      {...filteredRowProps}
      {...getRowProps(setRowProps({ row, classes }))}
    >
      {cells.map((cell) => {
        const { alignment, className } = cell.column;
        const isAlignLeft = withHidingColumns && cells.length <= 4;
        const noFlexGrow = cell.column.flexGrow === 0;
        const totalLeftPosition = cell.column.totalLeft;
        const isColumnSticky = cell.column.sticky;

        return (
          <div
            {...cell.getCellProps([
              {
                style: customizeColumnStyle(cell.column, {
                  noFlexGrow,
                  isColumnSticky,
                  totalLeftPosition,
                  isAlignLeft,
                }),
              },
            ])}
            className={cn(
              "flex items-center p-10 h-full text-color-secondary",
              className,
              {
                "justify-end": alignment === "right",
              }
            )}
          >
            {cell.render("Cell")}
          </div>
        );
      })}
    </div>
  );

  const handleCollapse = (id) => {
    if (isExpanded) {
      setCollapsedRowId(null);
      return;
    }
    setCollapsedRowId(id);
  };

  return (
    <div ref={rowRef}>
      {CollapseRowComponent && (
        <Collapse
          isOpened={isExpanded}
          content={RenderRow()}
          collapseArrowOpenedClassName={styles.arrowOpened}
          onCollapse={() => handleCollapse(row.id)}
          togglerClassName={cn(styles.toggler, togglerClassName, {
            [styles.togglerExpanded]: isExpanded,
            [styles.rowMargin]: !isExpanded && row?.index !== data.length - 1,
          })}
          contentContainerClassName="w-full"
          ToggleComponent={
            ToggleComponent ? <ToggleComponent isExpanded={isExpanded} /> : null
          }
        >
          <CollapseRowComponent row={row} />
        </Collapse>
      )}
      {!CollapseRowComponent && RenderRow()}
    </div>
  );
};

Row.propTypes = {
  row: PropTypes.object,
  isFetching: PropTypes.bool,
  CollapseRowComponent: PropTypes.element,
  setSize: PropTypes.func,
  setRowProps: PropTypes.func,
  index: PropTypes.number,
};

export default Row;
