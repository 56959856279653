import React from "react";
import cn from "classnames";
import CarServiceOkIcon from "../../../../assets/icons/car-service-ok.svg";
import CarServiceNotOkIcon from "../../../../assets/icons/car-service-not-ok.svg";
import ChatOkIcon from "../../../../assets/icons/chat-ok.svg";
import ChatNotOkIcon from "../../../../assets/icons/chat-not-ok.svg";

import classes from "./Alerts.module.scss";

export default function PastActivityItem({ activity }) {
  const { title, range, value, time, day, icon, ring, chat } = activity;

  return (
    <div
      className={cn(
        classes.Activity__Item,
        "flex justify-between items-center"
      )}
    >
      <div
        className={cn(
          classes.Activity__Title,
          "flex justify-between items-center"
        )}
      >
        <div className={classes.Activity__Date}>
          <div className={classes.Activity__Day}>{day}</div>
          <div className={classes.Activity__Time}>{time}</div>
        </div>
        <div className={classes.Activity__Icon}>
          <img src={icon} alt="" />
        </div>
        <div className={classes.Activity__TypeWrapper}>
          <div className={classes.Activity__Type}>{title}</div>
          {range && (
            <div className={cn([classes.Activity__Range, "mt-2"])}>{range}</div>
          )}
        </div>
      </div>
      <div className={classes.Activity__Value}>
        <span>{value ? value : "-"} </span>
      </div>
      <div className="flex justify-between items-center">
        <img
          src={ring ? CarServiceOkIcon : CarServiceNotOkIcon}
          alt=""
          className={classes.Activity__NotificationIcon}
        />
        <img
          src={chat ? ChatOkIcon : ChatNotOkIcon}
          alt=""
          className={classes.Activity__NotificationIcon}
        />
      </div>
    </div>
  );
}
