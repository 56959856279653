import React, { useRef } from "react";
import cn from "classnames";

import { customizeColumnStyle } from "../../Table.utils";
import styles from "./HeaderGroup.module.scss";
import ColumnSortArrow from "../ColumnSortArrow";

const HeaderGroup = ({
  headerGroup,
  withHidingColumns,
  headRowClassName,
  headCellClassName,
  CollapseRowComponent,
  sortArrowClassName,
}) => {
  const headerRef = useRef();
  const cells = headerGroup.headers.slice([0]);
  const tableActionsLeftPosition = cells.reduce(
    (sum, cell) => (cell.isHeaderHidden ? sum : sum + cell.width),
    0
  );

  return (
    <div
      ref={headerRef}
      {...headerGroup.getHeaderGroupProps()}
      className={cn(styles.headRow, headRowClassName, {
        [styles.collapseMode]: !!CollapseRowComponent,
      })}
    >
      {cells.map((column) => {
        const isAlignLeft = withHidingColumns && cells.length <= 4;
        const isColumnSticky = column.sticky;
        const noFlexGrow = column.flexGrow === 0;
        const totalLeftPosition = column.totalLeft;
        return (
          <div
            {...column.getHeaderProps([
              {
                ...column.getSortByToggleProps(),
                style: customizeColumnStyle(column, {
                  noFlexGrow,
                  isColumnSticky,
                  totalLeftPosition,
                  isAlignLeft,
                }),
              },
            ])}
            className={cn(
              "flex items-center font-sm text-color-tertiary p-10",
              styles.headCellBackground,
              headCellClassName,
              {
                [styles.isColumnSticky]: isColumnSticky,
                [styles.isSorted]: column.isSorted,
                [styles.headCell]: !column.disableSortBy,
                "d-none": column.isHeaderHidden,
              }
            )}
          >
            {column.render("Header")}
            {!column.disableSortBy && (
              <ColumnSortArrow
                sortArrowClassName={sortArrowClassName}
                isSortByDesc={column.isSortedDesc}
              />
            )}
          </div>
        );
      })}
      <div
        style={{
          position: "sticky",
          left: tableActionsLeftPosition,
          display: "flex",
        }}
      ></div>
    </div>
  );
};

export default HeaderGroup;
