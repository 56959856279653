import React from "react";
import cn from "classnames";

import {
  UserAvatarBlueIcon,
  UserAvatarGreyIcon,
} from "../../../../../assets/icons";
import styles from "./UserAvatar.module.scss";

const UserAvatar = ({ width = 40, height = 40, isLogoActive, className }) => {
  return (
    <div className={cn(className)}>
      <div className={cn(styles.profileIcon)} style={{ width, height }}>
        {isLogoActive ? <UserAvatarBlueIcon /> : <UserAvatarGreyIcon />}
      </div>
    </div>
  );
};

export default UserAvatar;
