import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import {
  getFromLocalStorage,
  setToLocalStorage,
  removeFromLocalStorage,
} from "../utils";

import {
  authenticateUser,
  setClientAuthorizationHeader,
  removeClientAuthorizationHeader,
  refreshUser,
} from "../services/api.service";

const loginUserThunk = createAsyncThunk("ui/login", async (data) => {
  const { accessToken, refreshToken } = await authenticateUser(data);
  setClientAuthorizationHeader(accessToken);
  setToLocalStorage(`refreshToken`, refreshToken);
  setToLocalStorage(`accessToken`, accessToken);
  return accessToken;
});

export const logoutUser = createAction("ui/logout", function prepare() {
  removeClientAuthorizationHeader();
  removeFromLocalStorage(`refreshToken`);
  removeFromLocalStorage(`accessToken`);
  removeFromLocalStorage(`selectedTenant`);
  return {};
});

const refreshUserThunk = createAsyncThunk("ui/refresh", async (data) => {
  const { accessToken, refreshToken } = await refreshUser(data);
  setClientAuthorizationHeader(accessToken);
  setToLocalStorage(`refreshToken`, refreshToken);
  setToLocalStorage(`accessToken`, accessToken);
  return {};
});

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    isAdmin: false,
    login: {
      isLoading: false,
      isError: false,
      isLoggedIn: false,
    },
  },
  reducers: {
    setAdmin(state, action) {
      state.isAdmin = action.payload;
    },
    setIsLoggedIn(state, action) {
      state.login.isLoggedIn = action.payload;
    },
  },
  extraReducers: {
    [loginUserThunk.pending](state) {
      state.login.isLoading = true;
      state.login.isError = false;
      state.login.isLoggedIn = false;
    },
    [loginUserThunk.fulfilled](state) {
      state.login.isLoading = false;
      state.login.isError = false;
      state.login.isLoggedIn = true;
    },
    [loginUserThunk.rejected](state) {
      state.login.isLoading = false;
      state.login.isError = true;
      state.login.isLoggedIn = false;
    },
    [logoutUser](state) {
      // user will be redirected to /login
      state.login.isLoggedIn = false;
    },
    [refreshUserThunk.pending](state) {
      state.login.isError = false;
      state.login.isLoggedIn = true;
    },
    [refreshUserThunk.fulfilled](state) {
      state.login.isError = false;
      state.login.isLoggedIn = true;
    },
    [refreshUserThunk.rejected](state) {
      state.login.isError = true;
      state.login.isLoggedIn = false;
    },
  },
});

export { loginUserThunk as loginUser };
export { refreshUserThunk as refreshUser };

const { actions, reducer } = uiSlice;
export const { setAdmin, setIsLoggedIn } = actions;

export const getAdminStatus = (state) => state.ui.isAdmin;
export const getIsLoggedIn = (state) => state.ui.login.isLoggedIn;
export const getIsLoading = (state) => state.ui.login.isLoading;
export const getIsError = (state) => state.ui.login.isError;

export default reducer;
