import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Tooltip from "rc-tooltip";

import "rc-tooltip/assets/bootstrap.css";
import "./Tooltip.module.scss";
import { useScreenSize } from "../../utility/hooks";
import { PROP_TYPES_TEMPLATES } from "../../commonPropTypes";

const CustomTooltip = ({
  children,
  content = "",
  tipContentClassName,
  direction = "top",
  mouseLeaveDelay = 0.1,
  useHover = true,
  isOpen,
  closingTime,
  onVisibleChange,
  align,
  prefixCls,
  destroyTooltipOnHide,
  showContentOnClickMobileTablet = true,
}) => {
  //isOpen prop is provided inside hasTooltipOpened object. If we provide isOpen props directly into tooltip props
  // it will break down showing/hiding tooltip on hover event
  const [visible, setVisible] = useState({});
  const { isMobile, isTablet } = useScreenSize();
  // TODO: This listener is for hiding tooltip on scroll event. Thing about better solution
  const scrollListener = () => {
    if (isOpen) {
      setVisible({ visible: false });
      setTimeout(() => {
        setVisible({});
      }, 10);
    }
  };
  const modal = document.getElementById("modal");
  const { body } = document;
  const elementToScrollListen = modal || body;
  useEffect(() => {
    if (isOpen) {
      setVisible({ visible: isOpen });
    }
    elementToScrollListen.addEventListener("scroll", scrollListener);
    return () => {
      elementToScrollListen.removeEventListener("scroll", scrollListener);
    };
  }, []);

  useEffect(() => {
    if (closingTime && isOpen) {
      setTimeout(() => {
        setVisible({ visible: false });
        setTimeout(() => {
          setVisible({});
        }, 10);
      }, closingTime);
    }
  }, [closingTime, isOpen]);

  const triggers = [];
  if (content && useHover && !isMobile && !isTablet) {
    triggers.push("hover");
  } else if (content && showContentOnClickMobileTablet) {
    triggers.push("click");
  }
  return (
    <Tooltip
      destroyTooltipOnHide={destroyTooltipOnHide}
      prefixCls={prefixCls}
      align={align}
      placement={direction}
      overlayClassName={tipContentClassName}
      trigger={triggers}
      overlay={content}
      mouseLeaveDelay={mouseLeaveDelay}
      onVisibleChange={onVisibleChange}
      {...visible}
    >
      {children}
    </Tooltip>
  );
};

CustomTooltip.propTypes = {
  children: PropTypes.element.isRequired,
  content: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  tipContentClassName: PROP_TYPES_TEMPLATES.CLASS_NAME,
  direction: PropTypes.oneOf([
    "left",
    "right",
    "top",
    "bottom",
    "topLeft",
    "topRight",
    "bottomLeft",
    "bottomRight",
  ]),
  mouseLeaveDelay: PropTypes.number,
  useHover: PropTypes.bool,
  isOpen: PropTypes.bool,
  closingTime: PropTypes.number,
  onVisibleChange: PropTypes.func,
  showContentOnClickMobileTablet: PropTypes.bool,
};

export default CustomTooltip;
