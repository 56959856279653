import React, { useState, useEffect } from "react";
import cn from "classnames";

import InputWrapper from "./InputWrapper";
import classes from "./TextInput.module.scss";

const TextInput = ({
  cls,
  value: valueProp = "",
  onInputChange,
  onFinalChange,
  placeholder,
  disabled,
  isError,
  isRangeError,
  label,
  additionalLabel,
  withButton,
  buttonType,
  addButtonText,
  onInputButtonClick,
}) => {
  const [value, setValue] = useState(valueProp);

  useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  const onChange = (event) => {
    const { value } = event.target;
    setValue(value);
    onInputChange && onInputChange(value);
  };

  return (
    <InputWrapper
      cls={cls}
      label={label}
      isError={isError}
      disabled={disabled}
      additionalLabel={additionalLabel}
      withButton={withButton}
      buttonType={buttonType}
      addButtonText={addButtonText}
      onInputButtonClick={onInputButtonClick}
    >
      <input
        type="text"
        // value={valueProp}
        value={value}
        onChange={onChange}
        onBlur={onFinalChange}
        placeholder={placeholder}
        disabled={disabled}
        className={cn(classes.TextInput, {
          [classes["TextInput--isError"]]: isError || isRangeError,
        })}
      />
    </InputWrapper>
  );
};

export default TextInput;
