import React from "react";
import cn from "classnames";

import styles from "./MobileRow.module.scss";
import Typography from "../../../typography/Typography";
import VehiclesCell from "../../../../scenes/customers/customer-table/vehicles-cell/VehiclesCell";
import ViewMoreBtn from "../../../../scenes/customers/customer-table/view-more-button/ViewMoreBtn";

const MobileRow = ({ className, row, data }) => {
  return (
    <div
      className={cn(styles.mobileRow, className, {
        [styles.rowMargin]: row?.row.index !== data.length - 1,
      })}
    >
      <div className={styles.row}>
        <Typography color="quaternary" fontSize="sm">
          Fleet Size
        </Typography>
        <VehiclesCell row={row} value={row.row?.values?.vehicles} />
      </div>
      <div className={styles.row}>
        <Typography color="quaternary" fontSize="sm">
          # Connected
        </Typography>
        <VehiclesCell
          row={row}
          value={row.row?.values?.vehiclesConnected}
          isConnectedCol
        />
      </div>
      <div className={styles.row}>
        <Typography color="quaternary" fontSize="sm">
          # Driving Now
        </Typography>
        <Typography fontSize="md">
          {row.row?.values?.vehiclesDriving || "-"}
        </Typography>
      </div>
      <div className={styles.row}>
        <Typography color="quaternary" fontSize="sm">
          # Inactive
        </Typography>
        <Typography fontSize="md">
          {row.row?.values?.vehiclesInactive
            ? row.row?.values?.vehiclesInactive
            : "-"}
        </Typography>
      </div>
      <div className={styles.row}>
        <Typography color="quaternary" fontSize="sm">
          # Engine Lights
        </Typography>
        <VehiclesCell row={row} value={row.row?.values?.vehiclesEngineLights} />
      </div>
      <div className={styles.row}>
        <Typography color="quaternary" fontSize="sm">
          # Warnings
        </Typography>
        <VehiclesCell
          row={row}
          value={row.row?.values?.vehiclesWarnings}
          isWarningsCol
        />
      </div>
      <div className={styles.row}>
        <ViewMoreBtn value={row.row?.values?.viewMore} />
      </div>
    </div>
  );
};

export default MobileRow;
