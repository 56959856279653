import React from "react";
import cn from "classnames";

import { SortArrowDownIcon, SortArrowUpIcon } from "../../../../assets/icons";

const ColumnSortArrow = ({ isSortByDesc, sortArrowClassName }) => {
  return (
    <span className={cn("ml-8", sortArrowClassName)}>
      {isSortByDesc && <SortArrowDownIcon />}
      {!isSortByDesc && <SortArrowUpIcon />}
    </span>
  );
};

export default ColumnSortArrow;
