import { configureStore } from "@reduxjs/toolkit";

import customersReducer from "../scenes/customers/customers.slice";
import vehiclesReducer from "../scenes/customer-details/vehicles.slice";
import uiReducer from "./ui.slice";
import shopsReducer from "../scenes/shops/shops.slice";
import usersReducer from "../scenes/adminUsers/users.slice";
import tenantsReducer from "../scenes/tenants/tenants.slice";

const store = configureStore({
  reducer: {
    customers: customersReducer,
    vehicles: vehiclesReducer,
    ui: uiReducer,
    shops: shopsReducer,
    users: usersReducer,
    tenants: tenantsReducer,
  },
});

export default store;
