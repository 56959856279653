import React, { useState } from "react";
import cn from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";

import { getSelectedCustomer } from "../../../customers/customers.selectors";
import { setRemoveModalShown } from "../../../customers/customers.slice";
import { getSelectedShopId } from "../../../shops/shops.selectors";
import { getVehicleFilter } from "../../../customer-details/vehicles.selectors";
import { setVehicleFilter } from "../../../customer-details/vehicles.slice";

import {
  ArrowBackIcon,
  DisconnectCustomerIcon,
} from "../../../../assets/icons";

import classes from "./CustomerBanner.module.scss";
import dropdownClasses from "../../../../styles/dropdown.module.scss";
import { RemoveCustomerModal } from "./RemoveCustomerModal";

export default function CustomerBanner() {
  const dispatch = useDispatch();
  const customer = useSelector(getSelectedCustomer);
  const history = useHistory();
  const shopId = useSelector(getSelectedShopId);
  const [isDropdownShown, setDropdownShown] = useState(false);
  const vehicleFilter = useSelector(getVehicleFilter);

  const showRemoveModal = () => dispatch(setRemoveModalShown(true));

  function onCleanVehicleFilter() {
    dispatch(setVehicleFilter(""));
  }

  if (!customer) {
    return null;
  }

  let AVATAR_CLASS;
  const hasGeneralVehicleWarning =
    !!customer.vehiclesWithCheckEngineLight.length;

  if (customer.isBusinessAccount) {
    AVATAR_CLASS = hasGeneralVehicleWarning
      ? classes.CustomerListItem__redAvatar
      : classes.CustomerListItem__greenAvatar;
  } else {
    AVATAR_CLASS = hasGeneralVehicleWarning
      ? classes.CustomerListItem__avatarRed
      : classes.CustomerListItem__avatar;
  }

  return (
    <div className={cn(classes.CustomerBanner)}>
      <div
        className={classes.CustomerBanner__arrowBack}
        onClick={() => {
          history.push(`/${shopId}`);
          vehicleFilter && onCleanVehicleFilter();
        }}
      >
        <ArrowBackIcon />
      </div>
      <div className={cn([AVATAR_CLASS, classes.CustomerBanner__avatar])}></div>
      <div className="d-flex align-items-center">
        <div className={cn([classes.CustomerBanner__name, "pl-16"])}>
          {customer.name}
        </div>
      </div>
      <div className={classes.disconnectButton} onClick={showRemoveModal}>
        <DisconnectCustomerIcon className={classes.icon} />
        Disconnect Customer
      </div>
      <CustomerDropdown
        isOpen={isDropdownShown}
        toggle={() => setDropdownShown((x) => !x)}
        onDisconnectCustomer={showRemoveModal}
      />
      <RemoveCustomerModal />
    </div>
  );
}

function CustomerDropdown({ isOpen, toggle, onDisconnectCustomer }) {
  return (
    <div className={classes.customerDropdown}>
      <Dropdown isOpen={isOpen} toggle={toggle}>
        <DropdownToggle tag="div" className={dropdownClasses.Dropdown__Toggle}>
          <div className={dropdownClasses.Toogler__Dot} />
          <div className={dropdownClasses.Toogler__Dot} />
          <div className={dropdownClasses.Toogler__Dot} />
        </DropdownToggle>
        <DropdownMenu className={dropdownClasses.Dropdown__Menu}>
          <DropdownItem
            className={dropdownClasses.Dropdown__Item}
            onClick={onDisconnectCustomer}
          >
            <DisconnectCustomerIcon
              className={dropdownClasses.Dropdown__Icon}
            />
            <div>Disconnect Customer</div>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}
