/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect } from "react";
import cn from "classnames";

import { calculateGradientValueColor } from "./utils";
import classes from "./styles.module.scss";

export default function Slider({
  onSliderDrag,
  type,
  percentage,
  onFinalChange,
}) {
  const node = useRef(null);

  function getStyle() {
    const perc = (percentage || 0) * 100;
    const style = {
      left: `${perc}%`,
      // gradient variant of the slider
      backgroundColor: calculateGradientValueColor(
        [
          { color: "#00C6FF", percentage: 0 },
          { color: "#0072FF", percentage: 100 },
        ],
        perc
      ),
    };

    return style;
  }

  useEffect(() => {
    return () => {
      removeDocumentMouseMoveListener();
      removeDocumentMouseUpListener();
      removeDocumentTouchEndListener();
      removeDocumentTouchMoveListener();
    };
  }, []);

  /* remove existing mouseup listener and add new one */
  function addDocumentMouseUpListener() {
    removeDocumentMouseUpListener();
    node.current.ownerDocument.addEventListener("mouseup", onMouseUp);
  }

  /* remove existing movemouse listener and add new one */
  function addDocumentMouseMoveListener() {
    removeDocumentMouseMoveListener();
    node.current.ownerDocument.addEventListener("mousemove", onMouseMove);
  }

  function removeDocumentMouseMoveListener() {
    node.current.ownerDocument.removeEventListener("mousemove", onMouseMove);
  }

  function removeDocumentMouseUpListener() {
    node.current.ownerDocument.removeEventListener("mouseup", onMouseUp);
  }

  function addDocumentTouchMoveListener() {
    removeDocumentTouchMoveListener();
    node.current.ownerDocument.addEventListener("touchmove", onTouchMove);
  }

  function addDocumentTouchEndListener() {
    removeDocumentTouchEndListener();
    node.current.ownerDocument.addEventListener("touchend", onTouchEnd);
  }

  function removeDocumentTouchMoveListener() {
    node.current.ownerDocument.removeEventListener("touchmove", onTouchMove);
  }

  function removeDocumentTouchEndListener() {
    node.current.ownerDocument.removeEventListener("touchend", onTouchEnd);
  }

  function onMouseDown() {
    addDocumentMouseMoveListener();
    addDocumentMouseUpListener();
  }

  function onMouseUp() {
    removeDocumentMouseMoveListener();
    removeDocumentMouseUpListener();
    onFinalChange();
  }

  function onMouseMove(event) {
    onSliderDrag(event, type);
  }

  function onTouchStart() {
    addDocumentTouchEndListener();
    addDocumentTouchMoveListener();
  }

  function onTouchMove(event) {
    onSliderDrag(event, type);
  }

  function onTouchEnd() {
    removeDocumentTouchMoveListener();
    removeDocumentTouchEndListener();
    onFinalChange();
  }

  const style = getStyle();
  return (
    <div
      ref={node}
      style={style}
      className={cn(
        classes.Slider,
        "absolute",
        "border-radius-circle",
        "box-shadow-lightest"
      )}
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
    />
  );
}
