import React from "react";
import cn from "classnames";

import styles from "./Label.module.scss";
import Typography from "../typography/Typography";
import { PROP_TYPES_TEMPLATES } from "../../commonPropTypes";

const Label = (props) => {
  const { label, additionalLabel, className, disabled, labelWrapperClass } =
    props;

  if (!label) return null;

  return (
    <div className={cn("flex items-center mb-8", labelWrapperClass)}>
      {label && (
        <Typography
          className={cn(styles.label, className, {
            [styles.label_disabled]: disabled,
          })}
          color="tertiary"
          fontWeight="semibold"
        >
          {label}
        </Typography>
      )}
      {additionalLabel && (
        <Typography className="ml-5" fontSize="sm" color="quaternary">
          ({additionalLabel})
        </Typography>
      )}
    </div>
  );
};

Label.propTypes = {
  label: PROP_TYPES_TEMPLATES.LABEL,
  additionalLabel: PROP_TYPES_TEMPLATES.LABEL,
};

export default Label;
