import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Spinner } from "reactstrap";
import cn from "classnames";
import { format } from "date-fns";

import {
  getVehiclesById,
  getSelectedVehicleId,
  getVehicleFilter,
} from "../../vehicles.selectors";

import classes from "./CustomerVehiclesPanel.module.scss";
import { fetchVehicleOverview } from "../../vehicles.slice";
import { getRoutePrefix } from "../../../../services/env.service";
import { getSelectedShopId } from "../../../shops/shops.selectors";
import EngineIcon from "../../../../components/engine-icon/EngineIcon";
import EngineRedIcon from "./../../../../assets/icons/engine-red.svg";
import EngineGreenIcon from "./../../../../assets/icons/engine-green.svg";
import DefaultCarAvatar from "./../../../../assets/icons/default-car.png";
import { replaceSpaceToUnderscore } from "../../../../utils";
import { useHistory, useRouteMatch } from "react-router-dom";

export function CustomerVehicleCard({ vehicle }) {
  const ENGINE_ICON = vehicle.overview.data?.generalVehicleWarning
    ? EngineRedIcon
    : EngineGreenIcon;

  const [avatar, setAvatar] = useState({
    isSet: false,
    URL: DefaultCarAvatar,
  });

  if (!avatar.isSet && vehicle.overview.data && vehicle.overview.data.vinData) {
    const { year, make, model } = vehicle.overview.data.vinData;
    if (year && make && model) {
      setAvatar({
        isSet: true,
        URL: `https://images.mechanicadvisor.com/Resources/Upload/Images/Specifications/${year}_${replaceSpaceToUnderscore(
          make
        )}_${replaceSpaceToUnderscore(model)}.png`,
      });
    }
  }

  useEffect(() => {
    if (avatar.isSet) {
      (async () => {
        try {
          const res = await fetch(avatar.URL);
          if (!res.ok) {
            setAvatar((prev) => ({ ...prev, URL: DefaultCarAvatar }));
          }
        } catch {
          setAvatar((prev) => ({ ...prev, URL: DefaultCarAvatar }));
        }
      })();
    }
  }, [avatar.isSet]);

  const ref = useRef(null);
  const history = useHistory();
  const routeMatch = useRouteMatch();
  const customerId = routeMatch.params.id;
  const dispatch = useDispatch();
  useEffect(() => {
    if (vehiclesById[vehicle.id]) {
      dispatch(fetchVehicleOverview(vehicle.id));
    }
  }, [vehicle.id]);

  const selectedVehicleId = useSelector(getSelectedVehicleId);
  const vehiclesFilter = useSelector(getVehicleFilter);

  useEffect(() => {
    if (vehicle.id === selectedVehicleId) {
      ref.current.scrollIntoView({
        behavior: "instant",
        block: "nearest",
        inline: "nearest",
      });
    }
  }, [selectedVehicleId, vehiclesFilter]);

  const vehiclesById = useSelector(getVehiclesById);
  const { data: overviewData } = vehiclesById[vehicle.id]
    ? vehiclesById[vehicle.id].overview
    : { data: null }; // when new vehicle is added it's not in store, but its in customer vehicles list and loader should be shown
  const shopId = useSelector(getSelectedShopId);

  function onVehicleSelect(id) {
    ref.current.scrollIntoView({
      behavior: "instant",
      block: "nearest",
      inline: "nearest",
    });
    setTimeout(() => {
      history.push(`/${shopId}/customer/${customerId}/vehicle/${id}`);
    }, 250);
  }

  let odometerValue = "N/A";
  let lastConnected = "N/A";
  let valueVIN = "N/A";

  if (overviewData) {
    if (Number.isFinite(overviewData.odometer.value)) {
      odometerValue = overviewData.odometer.value.toLocaleString(["en-US"]);
    }
    if (typeof overviewData.lastTimeCommunicated === "string") {
      lastConnected = format(
        new Date(overviewData.lastTimeCommunicated),
        "MMM dd, y"
      );
    }
    if (overviewData.vin) {
      valueVIN = overviewData.vin.slice(-9).replace(/.(?=.{4})/g, "*");
    }
  }

  return (
    <Col
      xs="12"
      md="3"
      className={cn(
        classes[
          selectedVehicleId
            ? "CustomerVehicleCard"
            : "CustomerVehicleCard__tiles"
        ],
        "mt-2",
        "mt-md-0"
      )}
    >
      <div
        ref={ref}
        className={cn(
          classes.CustomerVehicleCard__wrapper,
          {
            [classes["CustomerVehicleCard__wrapper--selected"]]:
              vehicle.id === selectedVehicleId,
            [classes["CustomerVehicleCard__wrapper__tiles"]]:
              !selectedVehicleId,
          },
          "d-flex",
          "align-items-center"
        )}
        onClick={() => onVehicleSelect(vehicle.id)}
      >
        {!vehicle.overview.data ? (
          <Spinner size="sm" color="primary" />
        ) : (
          <>
            <div
              style={{ backgroundImage: `url(${avatar.URL})` }}
              className={cn([
                classes.CustomerVehicleCard__vehicleAvatar,
                "flex-shrink-0",
              ])}
            >
              <EngineIcon icon={ENGINE_ICON} />
            </div>
            <div className="w-100 mr-8 overflow-hidden">
              <div
                className={cn([
                  classes.CustomerVehicleCard__vehicleName,
                  "w-100",
                ])}
              >
                {vehicle.name}
              </div>
              <div className="d-flex w-100">
                <span
                  className={cn([
                    classes.CustomerVehicleCard__vehicleParamName,
                    "w-50",
                  ])}
                >
                  VIN:
                </span>
                <span
                  className={cn([
                    classes.CustomerVehicleCard__vehicleParamValue,
                    "w-50",
                  ])}
                >
                  {valueVIN}
                </span>
              </div>
              <div className="d-flex w-100">
                <span
                  className={cn([
                    classes.CustomerVehicleCard__vehicleParamName,
                    "w-50",
                  ])}
                >
                  Odometer:
                </span>
                <span
                  className={cn([
                    classes.CustomerVehicleCard__vehicleParamValue,
                    "w-50",
                  ])}
                >
                  {odometerValue}
                </span>
              </div>
              <div className="d-flex w-100">
                <span
                  className={cn([
                    classes.CustomerVehicleCard__vehicleParamName,
                    "w-50",
                  ])}
                >
                  Last Connected:
                </span>
                <span
                  className={cn([
                    classes.CustomerVehicleCard__vehicleParamValue,
                    "w-50",
                  ])}
                >
                  {lastConnected}
                </span>
              </div>
            </div>
          </>
        )}
      </div>
    </Col>
  );
}
