import React from "react";
import cn from "classnames";

import styles from "./TableContainer.module.scss";

const getClassNames = (props) => {
  const { className, isInfiniteScroll } = props;

  return cn(className, styles.scrollContainer, {
    [styles["scrollContainer--isOverflowHidden"]]: isInfiniteScroll,
  });
};

const TableContainer = (props) => {
  const { children } = props;

  return <div className={getClassNames(props)}>{children}</div>;
};

export default TableContainer;
