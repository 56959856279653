import React, { useState, Fragment, useEffect } from "react";
import cn from "classnames";
import {
  addMonths,
  endOfDay,
  format,
  startOfWeek,
  endOfWeek,
  subWeeks,
  startOfMonth,
  endOfMonth,
  subMonths,
  startOfYear,
  endOfYear,
  subYears,
  subDays,
} from "date-fns";

import Button from "../ui/button/Button";
import Input from "./Input";
import MonthToggle from "./MonthToggle";
import Month from "./Month";
import TimePicker from "./TimePicker";
import SelectInput from "../ui/input/SelectInput";
import { ACTIONS } from "./constants";
import classes from "./Common.module.scss";
import { getSelectedChartDateRange } from "../../scenes/customer-details/vehicles.selectors";
import { useSelector } from "react-redux";

const DateRangePicker = ({
  cls,
  label,
  startDate,
  endDate,
  dateFormat = "MMM d yyyy",
  onChange,
  withTimePicker,
  placeholder,
}) => {
  const selectedDateRange = useSelector(getSelectedChartDateRange);
  const now = new Date();
  const [focusedDate, setFocusedDate] = useState(startDate || now);
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [activeToggle, setActiveToggle] = useState(ACTIONS.CUSTOM);
  const [dateRange, setDateRange] = useState({
    start: startDate || new Date(selectedDateRange.start),
    end: endDate || new Date(selectedDateRange.end),
    status: false,
  });

  useEffect(() => {
    setDateRange({
      start: new Date(selectedDateRange.start),
      end: new Date(selectedDateRange.end),
    });
  }, [selectedDateRange]);

  const onMonthChange = (date) => {
    setFocusedDate(date);
  };

  const onApplyClick = () => {
    const { start, end } = dateRange;
    setIsPickerOpen(false);
    onChange && onChange({ start, end });
  };

  const onCancelClick = () => {
    setIsPickerOpen(false);
    setDateRange({ start: startDate, end: endDate, status: false });
  };

  const onStartTimeChange = (date) => {
    setDateRange({ ...dateRange, start: date });
  };

  const onEndTimeChange = (date) => {
    setDateRange({ ...dateRange, end: date });
  };

  const onSelectionDate = (date) => {
    setActiveToggle("Custom");
    const { start, status } = dateRange;
    if (!status) {
      setDateRange({
        start: date,
        end: endOfDay(date),
        status: true,
      });
    } else if (start <= date) {
      setDateRange({
        ...dateRange,
        end: endOfDay(date),
        status: false,
      });
    } else {
      setDateRange({
        start: date,
        end: endOfDay(date),
        status: true,
      });
    }
  };

  const onSelectionMove = (date) => {
    const { start, status } = dateRange;
    if (status && start < date) {
      setDateRange({
        ...dateRange,
        end: endOfDay(date),
      });
    }
  };

  const autoFillRange = (action) => {
    const updatedDateRange = { ...dateRange };
    setActiveToggle(action);
    const now = new Date();

    switch (action) {
      case ACTIONS.THIS_WEEK:
        updatedDateRange.start = startOfWeek(now);
        updatedDateRange.end = endOfWeek(now);
        break;
      case ACTIONS.PREVIOUS_WEEK:
        updatedDateRange.start = startOfWeek(subWeeks(now, 1));
        updatedDateRange.end = endOfWeek(subWeeks(now, 1));
        break;
      case ACTIONS.THIS_MONTH:
        updatedDateRange.start = startOfMonth(now);
        updatedDateRange.end = endOfMonth(now);
        break;
      case ACTIONS.PREVIOUS_MONTH:
        updatedDateRange.start = startOfMonth(subMonths(now, 1));
        updatedDateRange.end = endOfMonth(subMonths(now, 1));
        break;
      case ACTIONS.LAST_3_MONTH:
        updatedDateRange.start = startOfMonth(subMonths(now, 3));
        updatedDateRange.end = endOfMonth(subMonths(now, 1));
        break;
      case ACTIONS.THIS_YEAR:
        updatedDateRange.start = startOfYear(now);
        updatedDateRange.end = endOfYear(now);
        break;
      case ACTIONS.PREVIOUS_YEAR:
        updatedDateRange.start = startOfYear(subYears(now, 1));
        updatedDateRange.end = endOfYear(subYears(now, 1));
        break;
      case ACTIONS.CUSTOM:
        updatedDateRange.start = null;
        updatedDateRange.end = null;
        break;
      default:
        return;
    }

    setFocusedDate(updatedDateRange.start || now);
    setDateRange(updatedDateRange);
  };

  const { start, end, status } = dateRange;
  let inputValue = "";
  if (status && start) {
    inputValue = `${format(start, dateFormat)}`;
  } else if (start && end) {
    inputValue = `${format(start, dateFormat)} - ${format(end, dateFormat)}`;
  }

  return (
    <div className={cn(classes.DateRangePicker, cls)}>
      <Input
        label={label}
        value={inputValue}
        placeholder={placeholder}
        isPickerOpen={isPickerOpen}
        onClick={() => setIsPickerOpen(!isPickerOpen)}
      />
      {isPickerOpen && (
        <div
          className={cn(
            classes.Picker,
            {
              [classes.withLabel]: !!label,
            },
            classes.MobilePicker
          )}
        >
          <SelectInput
            cls={classes.ActionSelect}
            label="Select a period"
            value={{ value: ACTIONS.CUSTOM, label: ACTIONS.CUSTOM }}
            options={Object.values(ACTIONS).map((action) => ({
              value: action,
              label: action,
            }))}
            onInputChange={(action) => autoFillRange(action.value)}
          />
          <div className={classes.ActionList}>
            {Object.values(ACTIONS).map((action) => (
              <div
                key={action}
                className={cn(classes.ActionList__item, {
                  [classes["ActionList__item--isActive"]]:
                    action === activeToggle,
                })}
                onClick={() => autoFillRange(action)}
              >
                {action}
              </div>
            ))}
          </div>
          <MonthToggle focusedDate={focusedDate} onChange={onMonthChange} />
          <div
            className={cn(classes.Calendar, {
              [classes["Calendar--eventNone"]]: activeToggle === 10,
            })}
          >
            {Array.from({ length: 2 }).map((_, i) => {
              const month = addMonths(focusedDate, i);
              return (
                <Month
                  key={i}
                  month={month}
                  dateRange={dateRange}
                  onDayClick={onSelectionDate}
                  onSelecteMove={onSelectionMove}
                />
              );
            })}
          </div>
          {withTimePicker && (
            <Fragment>
              <div className={classes.Divider} />
              <div className={classes.TimePicker__wrapper}>
                <TimePicker
                  label="From:"
                  date={dateRange.start}
                  onChange={onStartTimeChange}
                />
                <TimePicker
                  label="To:"
                  date={dateRange.end}
                  onChange={onEndTimeChange}
                />
              </div>
            </Fragment>
          )}
          <div className={classes.Controls}>
            <Button
              type="light"
              cls={classes.Controls__button}
              clicked={onCancelClick}
            >
              Cancel
            </Button>
            <Button
              type="danger"
              cls={classes.Controls__button}
              clicked={onApplyClick}
            >
              Apply
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRangePicker;
